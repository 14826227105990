import { env } from '@seiue/env'
import { stringifyURL } from '@seiue/util'

/**
 * 当前 url 是否是对外共享的页面
 *
 * @returns 是否
 */
export const isPublicShareURL = () =>
  window?.location?.pathname?.startsWith('/share/')

/**
 * 获取对外共享的 url
 *
 * @param params - 参数
 * @param params.pathname - 路径地址，包含 /
 * @param params.schoolId - 学校 id
 * @param params.loginRequired - 是否需要登录
 * @returns url
 */
export const getShareURL = ({
  pathname,
  schoolId,
  loginRequired,
}: {
  pathname: string
  schoolId: number
  loginRequired: boolean
}) => {
  return stringifyURL(`${env('SERVER_PASSPORT')}/go`, {
    t: `/share${pathname}`,
    lr: Number(loginRequired),
    school_id: loginRequired ? schoolId : undefined,
  })
}
