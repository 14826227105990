import { AxiosRequestExtraConfig } from '@seiue/axios'
import { omit } from '@seiue/util'

import { useTermsByTypes } from 'packages/feature-utils/terms'
import { TermTypeEnum } from 'packages/features/terms/types'
import { PluginNameEnum } from 'packages/plugins/types'
import { QueryOptions } from 'packages/sdks-next'
import {
  EnabledSchoolPlugin,
  FieldGroup,
  GetFieldGroupsQuery,
  Term,
  fieldApi$getFieldGroups,
  schoolPluginApi$queryEnabledSchoolPlugins,
} from 'packages/sdks-next/chalk'
import {
  Processor,
  combineProcessorsWithSpecificType,
} from 'packages/utils/plugin-injection'

import { useHasEnabledParentPlugin } from '../plugins'

import { extractAllTermTypeFieldsFromGroups } from './utils'

/**
 * 获取组织架构的字段处理 hook 版
 * 插件启用后停用的情况下，接口仍会返回组织架构的相关字段/组，前端隐藏
 *
 * @returns 字段处理
 */
const useDeptFieldProcessor = (): Processor<FieldGroup[]> => {
  const enabled = useHasEnabledParentPlugin(
    PluginNameEnum.OrganizationStructure,
  )

  return fieldGroups =>
    fieldGroups.filter(
      group => enabled || !group.fieldNames?.includes('department_ids'),
    )
}

type ProcessorGetterParams = {
  /**
   * 启用的插件
   */
  enabledPlugins: EnabledSchoolPlugin[]
}

/**
 * 组织架构插件的字段处理
 * 插件启用后停用的情况下，接口仍会返回组织架构的相关字段/组，前端隐藏
 *
 * @param {ProcessorGetterParams} param0 - 参数对象
 * @returns 字段处理
 */
const getDeptFiledProcessor = ({
  enabledPlugins,
}: ProcessorGetterParams): Processor<FieldGroup[]> => {
  const enabled = !!enabledPlugins.find(
    p => p.pluginName === PluginNameEnum.OrganizationStructure && !p.parentId,
  )

  return fieldGroups =>
    fieldGroups.filter(
      group => enabled || !group.fieldNames?.includes('department_ids'),
    )
}

/**
 * 获取经过处理后字段组 hook
 *
 * @param params - 参数对象
 * @param params.domain - 领域
 * @param params.query - query 参数
 * @param {QueryOptions} options - queryApi 的 option 参数
 * @returns 和 fieldApi.useGetFieldGroups 一样的数据结构
 */
export const useGetFieldGroups = (
  params: {
    domain: string
    query?: GetFieldGroupsQuery
  },
  options?: QueryOptions,
) => {
  const processor = combineProcessorsWithSpecificType<FieldGroup[]>([
    useDeptFieldProcessor(),
    // 其他的处理器（比如通用群组插件禁用的时候，隐藏导师字段）
  ])

  // eslint-disable-next-line no-restricted-properties
  const { data: fieldGroups, ...rest } = fieldApi$getFieldGroups.useApi(
    params,
    options,
  )

  return {
    data: fieldGroups ? processor(fieldGroups) : null,
    ...rest,
  }
}

/**
 * 获取字段组中所有的 terms
 *
 * @param param - 参数
 * @param param.fieldGroups - 字段组
 * @param param.hiddenFieldNames - 定义要隐藏的字段，传原始字段 name
 * @param queryOptions - 查询配置
 * @returns 字段所有的 terms
 */
export const useTermsInFieldGroups = (
  {
    fieldGroups,
    hiddenFieldNames,
  }: {
    fieldGroups?: FieldGroup[]
    hiddenFieldNames?: string[]
  },
  queryOptions?: QueryOptions,
) => {
  // 提取所有有 termType 配置的字段
  const termTypeMap = extractAllTermTypeFieldsFromGroups(fieldGroups || [])
  const visibleTermTypeMap: Record<string, TermTypeEnum> = omit(
    termTypeMap,
    hiddenFieldNames || [],
  )

  const termTypes = Object.values(visibleTermTypeMap)

  // termType 对应的 terms
  const { data: termsMap, ...rest } = useTermsByTypes(
    {
      types: termTypes,
    },
    {
      ...queryOptions,
      disable: !termTypes.length || queryOptions?.disable,
    },
  )

  return {
    // fieldName 对应的 terms
    data: Object.entries(visibleTermTypeMap).reduce(
      (acc, [fieldName, type]) => {
        if (!termsMap?.[type]) return acc

        return {
          ...acc,
          [fieldName]: termsMap[type],
        }
      },
      {} as Record<string, Term[]>,
    ),
    ...rest,
  }
}

/**
 * 获取经过处理后字段组
 *
 * @param domain - 领域
 * @param getFieldGroupsQuery - query 参数
 * @param options - queryApi 的 option 参数
 * @returns 和 fieldApi.getFieldGroups 一致的数据结构
 */
export const getFieldGroups = async (
  domain: string,
  getFieldGroupsQuery?: GetFieldGroupsQuery,
  options?: AxiosRequestExtraConfig,
) => {
  const { data: enabledPlugins } =
    await schoolPluginApi$queryEnabledSchoolPlugins.api()

  const processor = combineProcessorsWithSpecificType<FieldGroup[]>([
    getDeptFiledProcessor({ enabledPlugins }),
    // 其他的处理器（比如通用群组插件禁用的时候，隐藏导师字段）
  ])

  // eslint-disable-next-line no-restricted-properties
  const { data: fieldGroups, ...rest } = await fieldApi$getFieldGroups.api(
    domain,
    getFieldGroupsQuery,
    options,
  )

  return {
    data: processor(fieldGroups),
    ...rest,
  }
}
