import { groupBy, useLazyCopy } from '@seiue/util'
import React, { useCallback } from 'react'

import { isDimension } from 'packages/feature-utils/grades/utils/item'
import { isScoreLocked } from 'packages/feature-utils/grades/utils/score'
import { convertItemsToTree } from 'packages/feature-utils/grades/utils/tree'
import { isSingleEvaluateTargetEntryItem } from 'packages/feature-utils/moral-assessments/views'
import { Reflection } from 'packages/sdks-next/chalk'
import {
  Assessment,
  AssessmentTypeEnum,
  EvaluatedScoreReq,
  Item,
  ItemStatusEnum,
  ItemTypeEnum,
  RelationsEnum,
  Score,
  ScoreTypeEnum,
  ScoringTypeEnum,
  klassScoreApi$getKlassEvaluatedScores,
} from 'packages/sdks-next/vnas'

type ScoreType = Score & Required<{ item: Item }>
/**
 * 把 assessment 转换为 tree
 *
 * @param root0 -- 参数
 * @param root0.assessment -- 评价项
 * @returns tree
 */
export const useCopyAssessmentToTree = ({
  assessment,
}: {
  assessment: Assessment
}) => {
  const copyAssessment = useLazyCopy(
    new Assessment({
      ...assessment,
      // 获取单个评价对象录入的评价项
      items:
        assessment?.items?.filter(
          item => isDimension(item) || isSingleEvaluateTargetEntryItem(item),
        ) || [],
    }),
  )

  const [tree] = React.useMemo(
    () => convertItemsToTree(copyAssessment, copyAssessment.items || []),
    [copyAssessment],
  )

  return tree
}

/**
 * 获取学生的分数
 *
 * @param root0 -- 参数
 * @param root0.assessment -- assessment
 * @param root0.rid -- 学生 id
 * @returns 对 item 组件的加强功能
 */
export const useClassAssessmentEvaluatedScores = ({
  assessment,
  rid,
}: {
  assessment: Assessment
  rid: number
}) => {
  // 保存的分数
  const [savedScores, setSavedScores] = React.useState<EvaluatedScoreReq[]>([])
  // 获取分数

  const {
    data: scores,
    loading,
    reload,
  } = klassScoreApi$getKlassEvaluatedScores.useApi({
    rid,
    id: assessment.id,
  })

  return {
    scores: scores || [],
    savedScores,
    loadingScores: loading,
    setSavedScores: (innerScores: EvaluatedScoreReq[]) => {
      const newScores = getSavedScores({ innerScores, savedScores })
      setSavedScores(newScores)
    },
    reload,
    clearSavedScores: () => setSavedScores([]),
  }
}

/**
 * 获取已经保存的分数
 *
 * @param root0 -- 参数
 * @param root0.innerScores -- 内部的分数
 * @param root0.savedScores -- 保存的分数
 * @returns scores 新分数
 */
export const getSavedScores = ({
  innerScores,
  savedScores,
}: {
  innerScores: EvaluatedScoreReq[]
  savedScores: EvaluatedScoreReq[]
}) => {
  const mergedScores = [...savedScores, ...innerScores]
  const newScores: EvaluatedScoreReq[] = []
  mergedScores.forEach(score => {
    const existedIndex = newScores.findIndex(
      _score => _score.itemId === score.itemId && _score.type === score.type,
    )

    if (existedIndex !== -1) {
      const target = newScores[existedIndex]
      newScores.splice(existedIndex, 1, {
        ...target,
        ...score,
      })
    } else {
      newScores.push(score)
    }
  })

  return newScores
}

/**
 * 合并分数
 *
 * @param root0 -- 参数
 * @param root0.scores -- 分数
 * @param root0.savedScores --保存的分数
 * @param root0.currentReflection -- 人员信息
 * @returns 合并之后的分数的 func
 */
export const useGetItemScore = ({
  scores,
  savedScores,
  currentReflection,
}: {
  scores: Score[]
  savedScores: EvaluatedScoreReq[]
  currentReflection: Reflection
}) => {
  const getItemScore = useCallback(
    (item: Item) => {
      const scoreType = item.multiEvaluators
        ? ScoreTypeEnum.Raw
        : ScoreTypeEnum.ItemScore

      const originalScore = scores.find(
        score =>
          score.itemId === item.id &&
          score.type === scoreType &&
          // 多人评价要取当前用户打的分
          (item.multiEvaluators
            ? score.evaluatorId === currentReflection.id
            : true),
      )

      const savedScore = savedScores.find(
        score => score.itemId === item.id && score.type === scoreType,
      )

      return {
        originalScore,
        savedScore,
        finalScore: savedScore || originalScore,
        scoreType,
      }
    },
    [currentReflection.id, savedScores, scores],
  )

  return { getItemScore }
}

/**
 * 过滤出学生还没有自评的 count
 *
 * @param props -- 参数
 * @param props.assessment -- assessment
 * @param props.rid -- 学生 id
 * @returns count --  剩余没有自评的数量
 */
export const useUnEvaluatedCount = ({
  assessment,
  rid,
}: {
  assessment: Assessment
  rid: number
}) => {
  const { data: scores } = klassScoreApi$getKlassEvaluatedScores.useApi(
    {
      rid,
      id: assessment?.id,
      query: {
        expand: ['item'],
      },
    },
    {
      disable: !assessment || assessment?.items?.length === 0,
    },
  )

  // 如果没有评价项就不显示
  if (!assessment || assessment?.items?.length === 0) return -1
  // 过滤出所有学生自评项
  const studentSelfItem = assessment?.items?.filter(item => {
    return (
      item.relation === RelationsEnum.MarkSelf &&
      item.type === ItemTypeEnum.Item &&
      !isScoreLocked(item) &&
      !(item.status === ItemStatusEnum.Submitted)
    )
  })

  if (scores && scores.length > 0 && studentSelfItem) {
    // 可以修改的分数
    const scoreItem: ScoreType[] = []
    const tagItems: ScoreType[] = []
    scores.forEach(score => {
      const isStudentMarkSelf = score.item.relation === RelationsEnum.MarkSelf

      if (
        isStudentMarkSelf &&
        score.item.scoringType &&
        !isScoreLocked(score.item)
      ) {
        if (
          [
            ScoringTypeEnum.Score,
            ScoringTypeEnum.Level,
            ScoringTypeEnum.Star,
            ScoringTypeEnum.Review,
          ].includes(score.item.scoringType) &&
          !(
            score.item.status === ItemStatusEnum.Published ||
            score.item.status === ItemStatusEnum.Passed
          ) &&
          score.valid === true
        ) {
          scoreItem.push(score)
        }

        if (score.type === ScoreTypeEnum.Raw) {
          tagItems.push(score)
        }
      }
    })

    // 找出所有未评价的不包含加减分和标签
    const s = studentSelfItem.filter(item => {
      // 找出所有没有分数的
      return (
        !scores.find(score => {
          return (
            score.item.id === item.id &&
            !(
              item.scoringType === ScoringTypeEnum.Addition ||
              item.scoringType === ScoringTypeEnum.Tag
            )
          )
        }) && !isScoreLocked(item)
      )
    })

    if (s.length === 0 && scoreItem.length > 0) {
      return 0
    }

    if (s.length === 0) {
      return -1
    }

    return s.length - Object.keys(groupBy(tagItems, 'itemId')).length
  }

  // 如果没有分数,则数量是所有的学生自评数量
  return studentSelfItem?.length ? studentSelfItem.length : -1
}

/**
 * 判断学生可以录入的数量
 *
 * @param props -- 参数
 * @param props.type -- 评价项类型
 * @param props.relation  -- 评价项关系
 * @param props.status -- 评价项状态
 * @returns boolean -- 是否可以录入
 */
export const canBeEvaluateByStudent = ({
  type,
  relation,
  status,
}: {
  type: ItemTypeEnum
  relation: RelationsEnum | null | undefined
  status: ItemStatusEnum | null | undefined
}) => {
  return (
    type === ItemTypeEnum.Item &&
    relation === RelationsEnum.MarkSelf &&
    status === ItemStatusEnum.Pending
  )
}

/**
 * 判断评价项是否发布
 *
 * @param item -- 评价项
 * @param originalScore -- 原始分
 * @returns boolean -- 是否发布
 */
export const isPublishedItem = (item?: Item, originalScore?: Score) => {
  return (
    !!originalScore?.score &&
    !!item?.scoringType &&
    (item.status === ItemStatusEnum.Published ||
      item.status === ItemStatusEnum.Passed) &&
    [
      ScoringTypeEnum.Score,
      ScoringTypeEnum.Level,
      ScoringTypeEnum.Star,
      ScoringTypeEnum.Review,
    ].includes(item.scoringType)
  )
}

/**
 * 判断是否是课程班的学生自评
 *
 * @param relation -- 评价项的关系
 * @param assessmentType -- 类型
 * @returns boolean -- 是否是课程班的学生自评
 */
export const isStudentMarkSelfFromClass = (
  relation: RelationsEnum | undefined | null,
  assessmentType: AssessmentTypeEnum | undefined | null,
) => {
  return (
    relation === RelationsEnum.MarkSelf &&
    assessmentType === AssessmentTypeEnum.Class
  )
}
