import { TeachModeTypeEnum } from './teach-mode-type-enum'

export interface ClassinFieldInterface {
  sid?: string
  secret?: string
  schoolName?: string
  /**
   * 默认为 true
   */
  syncClassName?: boolean
  /**
   * 教学模式：标准教室、智慧教室
   */
  teachMode?: TeachModeTypeEnum
}

export class ClassinField implements ClassinFieldInterface {
  sid?: string
  secret?: string
  schoolName?: string
  /**
   * 默认为 true
   */
  syncClassName?: boolean
  /**
   * 教学模式：标准教室、智慧教室
   */
  teachMode?: TeachModeTypeEnum

  constructor(props: ClassinFieldInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      sid: _sid,
      secret: _secret,
      schoolName: _schoolName,
      syncClassName: _syncClassName,
      teachMode: _teachMode,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.sid = _sid
    this.secret = _secret
    this.schoolName = _schoolName
    this.syncClassName = _syncClassName
    this.teachMode = _teachMode
  }

  static propKeys = [
    'sid',
    'secret',
    'schoolName',
    'syncClassName',
    'teachMode',
  ]
}
