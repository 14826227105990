import { PaperBlockTypeEnum } from './extends/paper-block-type-enum'
import {
  PaperBlockSettings,
  PaperBlockSettingsInterface,
} from './paper-block-settings'
import { SimpleQuestion, SimpleQuestionInterface } from './simple-question'

export interface ExamScoringMarkStudentBlocksInterface {
  /**
   * 本阅卷是否标记了典型卷
   */
  markedTypical?: boolean
  /**
   * 本阅卷是否标记了问题卷
   */
  markedProblem?: boolean
  scoringId?: number
  /**
   * 区块ID
   */
  id: number
  /**
   * 学校ID
   */
  schoolId?: number
  /**
   * 试卷页ID
   */
  pageId: number
  /**
   * 区块类型
   *  - condition 条件块
   *  - confidential 保密区
   *  - candidate_num 涂抹考号
   *  - subjective 主观
   *  - objective 客观
   *  - optional 选做
   */
  type: PaperBlockTypeEnum
  /**
   * 裁剪x坐标
   */
  cropX: number
  /**
   * 裁剪y坐标
   */
  cropY: number
  /**
   * 裁剪宽度
   */
  cropW: number
  /**
   * 裁剪高度
   */
  cropH: number
  /**
   * 特殊设置项
   */
  settings?: PaperBlockSettingsInterface | null
  /**
   * 区块的hash值，对于多块来说hash相同
   */
  hash: string
  /**
   * expand
   *  - 随试卷显示时，在页面显示的分隔区块，如是多切割区，根据块生成顺序，依次按同个 hash 数字 1、2 排序
   */
  multiNo?: number | null
  /**
   * expand
   *  - 随试卷显示时，在页面显示的分隔区块关联题目信息(小题题号、题目 ID)
   *  - 仅返回部分数据，如后续有需要进行调整
   */
  questions?: SimpleQuestionInterface[]
  /**
   * expand
   *  - expand questions 时，condition 类型将返回其对应的 父块 id
   */
  parentBlockId?: number
  /**
   * 关联的布局 ID
   */
  layoutId?: number
}

export class ExamScoringMarkStudentBlocks
  implements ExamScoringMarkStudentBlocksInterface
{
  /**
   * 本阅卷是否标记了典型卷
   */
  markedTypical?: boolean
  /**
   * 本阅卷是否标记了问题卷
   */
  markedProblem?: boolean
  scoringId?: number
  /**
   * 区块ID
   */
  id: number
  /**
   * 学校ID
   */
  schoolId?: number
  /**
   * 试卷页ID
   */
  pageId: number
  /**
   * 区块类型
   *  - condition 条件块
   *  - confidential 保密区
   *  - candidate_num 涂抹考号
   *  - subjective 主观
   *  - objective 客观
   *  - optional 选做
   */
  type: PaperBlockTypeEnum
  /**
   * 裁剪x坐标
   */
  cropX: number
  /**
   * 裁剪y坐标
   */
  cropY: number
  /**
   * 裁剪宽度
   */
  cropW: number
  /**
   * 裁剪高度
   */
  cropH: number
  /**
   * 特殊设置项
   */
  settings?: PaperBlockSettings | null
  /**
   * 区块的hash值，对于多块来说hash相同
   */
  hash: string
  /**
   * expand
   *  - 随试卷显示时，在页面显示的分隔区块，如是多切割区，根据块生成顺序，依次按同个 hash 数字 1、2 排序
   */
  multiNo?: number | null
  /**
   * expand
   *  - 随试卷显示时，在页面显示的分隔区块关联题目信息(小题题号、题目 ID)
   *  - 仅返回部分数据，如后续有需要进行调整
   */
  questions?: SimpleQuestion[]
  /**
   * expand
   *  - expand questions 时，condition 类型将返回其对应的 父块 id
   */
  parentBlockId?: number
  /**
   * 关联的布局 ID
   */
  layoutId?: number

  constructor(props: ExamScoringMarkStudentBlocksInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      markedTypical: _markedTypical,
      markedProblem: _markedProblem,
      scoringId: _scoringId,
      id: _id,
      schoolId: _schoolId,
      pageId: _pageId,
      type: _type,
      cropX: _cropX,
      cropY: _cropY,
      cropW: _cropW,
      cropH: _cropH,
      settings: _settings,
      hash: _hash,
      multiNo: _multiNo,
      questions: _questions,
      parentBlockId: _parentBlockId,
      layoutId: _layoutId,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.markedTypical = _markedTypical
    this.markedProblem = _markedProblem
    this.scoringId = _scoringId
    this.id = _id
    this.schoolId = _schoolId
    this.pageId = _pageId
    this.type = _type
    this.cropX = _cropX
    this.cropY = _cropY
    this.cropW = _cropW
    this.cropH = _cropH
    this.settings = _settings ? new PaperBlockSettings(_settings) : _settings
    this.hash = _hash
    this.multiNo = _multiNo
    this.questions = _questions
      ? _questions.map(i => new SimpleQuestion(i))
      : _questions
    this.parentBlockId = _parentBlockId
    this.layoutId = _layoutId
  }

  static propKeys = [
    'markedTypical',
    'markedProblem',
    'scoringId',
    'id',
    'schoolId',
    'pageId',
    'type',
    'cropX',
    'cropY',
    'cropW',
    'cropH',
    'settings',
    'hash',
    'multiNo',
    'questions',
    'parentBlockId',
    'layoutId',
  ]
}
