import { SettingDirectionEnum } from './setting-direction-enum'
import { SettingCodeMatchFieldEnum } from './setting-code-match-field-enum'

export interface PaperBlockSettingsInterface {
  direction?: SettingDirectionEnum
  examCodeLen?: number
  examCodeMatchField?: SettingCodeMatchFieldEnum
  isBarcode?: boolean
  /**
   * 本块关联题目m选n,eg: 3:1
   */
  conditionQuestionType?: string | null
}

export class PaperBlockSettings implements PaperBlockSettingsInterface {
  direction?: SettingDirectionEnum
  examCodeLen?: number
  examCodeMatchField?: SettingCodeMatchFieldEnum
  isBarcode?: boolean
  /**
   * 本块关联题目m选n,eg: 3:1
   */
  conditionQuestionType?: string | null

  constructor(props: PaperBlockSettingsInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      direction: _direction,
      examCodeLen: _examCodeLen,
      examCodeMatchField: _examCodeMatchField,
      isBarcode: _isBarcode,
      conditionQuestionType: _conditionQuestionType,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.direction = _direction
    this.examCodeLen = _examCodeLen
    this.examCodeMatchField = _examCodeMatchField
    this.isBarcode = _isBarcode
    this.conditionQuestionType = _conditionQuestionType
  }

  static propKeys = [
    'direction',
    'examCodeLen',
    'examCodeMatchField',
    'isBarcode',
    'conditionQuestionType',
  ]
}
