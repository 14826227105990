import { useCurrentReflection } from 'packages/features/sessions/utils/data'
import { RoleEnum, reflectionApi$loadGuardian } from 'packages/sdks-next/chalk'

/**
 * 为当前用户加载孩子 (pupil) 的信息
 *
 * @param param0 - 参数
 * @param param0.disable - 是否禁用
 * @returns [pupil, loading]
 */
export const useLoadCurrentPupil = ({
  disable,
}: { disable?: boolean } = {}) => {
  const currentReflection = useCurrentReflection()

  const { data: guardian, loading } = reflectionApi$loadGuardian.useApi(
    {
      id: currentReflection.id,
      query: {
        tryExpand: ['pupil'] as const,
      },
    },
    { disable: currentReflection.role !== RoleEnum.Guardian || disable },
  )

  return [guardian?.pupil ?? null, loading] as const
}

/**
 * 从当前登录用户中获取操作人的 rid
 *
 * 家长登录：返回其孩子 rid
 * 其他身份登录：返回其自身 rid
 *
 * @returns rid
 */
export const useActionRid = () => {
  const currentReflection = useCurrentReflection()
  const isGuardian = currentReflection.role === RoleEnum.Guardian

  const [pupil, loadingPupil] = useLoadCurrentPupil({
    disable: !isGuardian,
  })

  const rid = isGuardian ? pupil?.id : currentReflection.id

  return {
    rid,
    loading: loadingPupil,
    isGuardian,
  }
}
