import {
  CategoryAxisType,
  ConditionStyle,
  DataFormat,
  ThemeNameEnum,
  ValueAxisType,
} from 'packages/components/ReportCenter/Reporting/types'

export * from './series'
export * from './enum'
export * from './axis'
export * from './condition-style'
export * from './data-format'

/**
 * 图表组件的自定义配置项（字段不完全对应 echarts）
 */
export type WidgetChartOption<Series extends Record<string, any> = any> = {
  /**
   * x 轴配置
   */
  xAxis?: ValueAxisType | CategoryAxisType
  /**
   * y 轴配置
   */
  yAxis?: ValueAxisType | CategoryAxisType
  /**
   * 系列配置
   */
  series?: Series
  /**
   * 主题色名称
   * ThemeName 是一组预定义的主题色，适合显示多个数据
   * string 是自定义的主题色，适合显示单个数据 (指标卡图表)
   */
  theme?: ThemeNameEnum | string
  /**
   * 条件样式（目前仅支持表格）
   */
  conditionStyles?: ConditionStyle[]
  /**
   * 数据格式（目前仅支持表格）
   */
  dataFormats?: DataFormat[]
  /**
   * 自定义的数据单位配置
   */
  customDataUnits?: string[]
  /**
   * 冻结前列
   */
  freezeCols?: number
}
