/* eslint-disable */
// 废弃代码, 不再修正 jsdoc lint 问题

import { noop, useLocalStorageState } from '@seiue/util'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { SessionState } from 'packages/features/sessions/utils/types'
import {
  personalDictApi$setPersonalDict,
  personalDictApi$getPersonalDict,
} from 'packages/sdks-next/chalk'
import humps from 'humps'

/**
 * 这里统一管理 dictName 下的 keys
 * 推荐对每个 key 下的 value 定义一个 type/interface
 *
 * @deprecated use PersonalConfigKey / PersonalTableConfigKey instead
 */
export enum DictPaths {
  // 当前用户曾经登录过 3.0
  ChalkLoggedIn = 'chalk_logged_in',

  // 当前 Chalk 所使用的语言
  ChalkLocale = 'chalk_locale',

  // 选择学生列表
  ChalkStudentPickerTableConfig = 'chalk_student_picker_table_config',

  // 选择宿舍学生列表
  ChalkDormStudentPickerTableConfig = 'chalk_dorm_student_picker_table_config',

  // 选择教师列表
  ChalkTeacherPickerTableConfig = 'chalk_teacher_picker_table_config',

  // 选择家长列表
  ChalkGuardianPickerTableConfig = 'chalk_guardian_picker_table_config',

  // 选择校外成员列表
  ChalkStaffPickerTableConfig = 'chalk_staff_picker_table_config',

  // 导师约谈管理
  ChalkMentorsTalksTableConfig = 'chalk_mentors_talks_table_config',

  // 导师任务管理
  ChalkMentorsTasksTableConfig = 'chalk_mentors_tasks_table_config',

  // 导师人员管理
  ChalkMentorsTableConfig = 'chalk_mentors_table_config',

  // 空间列表
  ChalkPlacesTableConfig = 'chalk_places_table_config',

  // 宿舍列表
  ChalkDormsTableConfig = 'chalk_dorms_table_config',

  // 宿舍评价列表
  ChalkDormAssessmentsNormalTableConfig = 'chalk_dorm_assessments_normal_table_config',

  // 归档宿舍评价列表
  ChalkDormAssessmentsArchivedTableConfig = 'chalk_dorm_assessments_archived_table_config',

  // 宿管宿舍评价列表
  ChalkDormAssessmentsManagerTableConfig = 'chalk_dorm_assessments_manager_table_config',

  // 教师账号管理
  ChalkUsersTeachersNormalTableConfig = 'chalk_users_teachers_normal_table_config',

  // 归档教师账号列表
  ChalkUsersTeachersDisabledTableConfig = 'chalk_users_teachers_disabled_table_config',

  // 家长账号管理
  ChalkUsersGuardiansNormalTableConfig = 'chalk_users_guardians_normal_table_config',

  // 归档家长账号列表
  ChalkUsersGuardiansDisabledTableConfig = 'chalk_users_guardians_disabled_table_config',

  // 学生账号管理
  ChalkUsersStudentsNormalTableConfig = 'chalk_users_students_normal_table_config',

  // 归档学生账号列表
  ChalkUsersStudentsDisabledTableConfig = 'chalk_users_students_disabled_table_config',

  // 学生账号管理操作日志
  ChalkUsersStudentsLogsTableConfig = 'chalk_users_students_logs_table_config',

  // 教师账号管理操作日志
  ChalkUsersTeachersLogsTableConfig = 'chalk_users_teachers_logs_table_config',

  // 家长账号管理操作日志
  ChalkUsersGuardiansLogsTableConfig = 'chalk_users_guardians_logs_table_config',

  // 校外人员账号管理
  ChalkUsersStaffsNormalTableConfig = 'chalk_users_staffs_normal_table_config',

  // 归档校外人员账号管理
  ChalkUsersStaffsDisabledTableConfig = 'chalk_users_staffs_disabled_table_config',

  // 归档校外人员账号管理操作日志
  ChalkUsersStaffsLogsTableConfig = 'chalk_users_staffs_logs_table_config',

  // 已选课程列表
  ChalkCoursesNormalTableConfig = 'chalk_courses_normal_table_config',

  // 选择课程列表
  ChalkCoursesPickerTableConfig = 'chalk_courses_normal_table_config',

  // 行政班列表
  ChalkAdminClassesNormalTableConfig = 'chalk_admin_classes_normal_table_config',

  // 行政班选择器列表
  ChalkAdminClassesPickerTableConfig = 'chalk_admin_classes_picker_table_config',

  // 课程禁用列表
  ChalkCoursesDisabledTableConfig = 'chalk_courses_disabled_table_config',

  ChalkCoursesLogsTableConfig = 'chalk_courses_logs_table_config',

  // 课程班正常列表
  ChalkClassesNormalTableConfig = 'chalk_classes_normal_table_config',

  // 课程班选择器列表
  ChalkClassesPickerTableConfig = 'chalk_classes_picker_table_config',

  // 通用群组选择器列表
  ChalkCustomGroupPickerTableConfig = 'chalk_custom_groups_picker_table_config',

  // 课程班操作日志
  ChalkClassesLogsTableConfig = 'chalk_classes_logs_table_config',

  // 课程任务统计
  ChalkClassTaskKlassStatistics = 'chalk_class_task_klass_statistics',
  ChalkClassTaskStudentStatistics = 'chalk_class_task_student_statistics',
  ChalkClassTaskTeacherStatistics = 'chalk_class_task_teacher_statistics',
  ChalkClassTaskKlassTasks = 'chalk_class_task_klass_tasks',
  ChalkClassTaskTeacherTasks = 'chalk_class_task_teacher_tasks',

  // 课程班考勤统计
  ChalkAttendanceClassListStatsConfig = 'chalk_attendance_class_list_stats_config',

  // 宿舍考勤统计
  ChalkAttendanceDormListStatsConfig = 'chalk_attendance_dorm_list_stats_config',

  // 学生考勤统计
  ChalkAttendanceStudentListStatsConfig = 'chalk_attendance_student_list_stats_config',

  // 课程班的学生考勤统计
  ChalkAttendanceClassStudentListStatsConfig = 'chalk_attendance_class_student_list_stats_config',

  // 宿舍的学生考勤统计
  ChalkAttendanceDormStudentListStatsConfig = 'chalk_attendance_dorm_student_list_stats_config',
  // 考勤管理考勤记录
  ChalkAttendanceClassesRecordConfig = 'chalk_attendance_classes_record_config',
  // 总成绩列表
  ChalkAssessmentTableConfig = 'chalk_assessment_table_config',

  // 待审核评价项列表
  ChalkReviewNeededItemTableConfig = 'chalk_review_needed_item_table_config',

  // 成绩维度编辑列表
  ChalkAssessmentDimensionTableConfig = 'chalk_assessment_dimension_table_config',

  // 成绩子维度编辑列表
  ChalkAssessmentSubDimensionTableConfig = 'chalk_assessment_sub_dimension_table_config',

  // 成绩评价项编辑列表
  ChalkAssessmentItemTableConfig = 'chalk_assessment_item_table_config',

  // 档案管理目标管理编辑列表
  ChalkProfileGoalItemTableConfig = 'chalk_profile_goal_management_table_config',

  // 档案管群组课程班
  ChalkProfileStudentGroupClassTableConfig = 'chalk_profile_student_group_class_table_config',

  // 档案管群组行政班
  ChalkProfileStudentGroupAdminClassTableConfig = 'chalk_profile_student_group_admin_class_table_config',

  // 档案管群组宿舍
  ChalkProfileStudentGroupDormTableConfig = 'chalk_profile_student_group_dorm_table_config',

  // 反馈管理列表
  ChalkFeedbacksTableConfig = 'chalk_feedbacks_table_config',

  // 通知管理表格
  ChalkManagerNoticeListTableConfig = 'chalk_manager_notice_list_table_config',

  // 学生成绩列表
  ChalkStudentsAssessmentTableConfig = 'chalk_students_assessment_table_config',

  // 请假后台数据 - 请假人列表
  ChalkAbsencesAbsencedTableConfig = 'chalk_absences_absenced_table_config',
  // 深圳中学成绩表格 - 成绩单列表
  SzGradeReport = 'sz_grade_report',
  // 深圳湾成绩报告 - 成绩单列表
  CgrGradeReport = 'cgr_grade_report',
  // 考试管理 - 批量关联评价项
  ChalkExamsBatchRelateItemsConfig = 'chalk_exams_batch_relate_items_config',

  // 考试管理 - 信息修改日志
  ChalkExamsLogsTableConfig = 'chalk_exams_logs_table_config',

  // 考试管理 - 选择课程班
  ChalkExamsClassesTableConfig = 'chalk_exams_classes_table_config',

  // 评教详情 - 评教详情列表
  ChalkEvaluationDetailsTableConfig = 'chalk_evaluation_details_table_config',

  // 评教评学 - 选择课程班列表
  ChalkEvaluationsClassesTableConfig = 'chalk_evaluations_classes_table_config',

  // 评教评学 - 选择行政班列表
  ChalkEvaluationsAdminClassesTableConfig = 'chalk_evaluations_admin_classes_table_config',

  // 行政班前台 - 课程班列表
  ChalkAdminClassRelatedFrontendClassesConfig = 'chalk_admin_class_related_frontend_classes_table_config',

  // 行政班后台 - 课程班列表
  ChalkAdminClassRelatedBackendClassesConfig = 'chalk_admin_class_related_backend_classes_table_config',

  // 课程申报 - 课程发布
  ChalkAdminClassDeclarationPrePublishClassesConfig = 'chalk_admin_class_declaration_pre_publish_classes_config',

  // 应用中心常用应用
  CommonUseApplicationsConfig = 'common_use_applications_config',

  // 评价录入/汇总 - 排序规则
  ChalkAssessmentSortBy = 'chalk_assessment_sort_by',

  // 课表查询展示配置
  SzzxScheduleVisibleConfig = 'szzx_schedule_visible_config',

  // AI 教师管理列表
  ChalkAiTeacherListConfig = 'chalk_ai_teacher_list_config',
}

export type DictState = {
  [key: string]: any
}

const defaultState: DictState = {}

const reducers = {
  set(state: DictState, newState: DictState) {
    return newState
  },

  get(state: DictState, path: DictPaths) {
    return state[path]
  },

  clearCache() {
    return defaultState
  },
}

const effects = (dispatch: any) => ({
  async update(
    { path, value }: { path: DictPaths; value: any },
    { session }: { session: SessionState },
  ) {
    const id = session.currentReflection?.id

    if (!id) {
      return value
    }

    const newValue: DictState = (
      await personalDictApi$setPersonalDict.api(id, {
        [path]: value,
      })
    )?.data

    dispatch.dicts.set(newValue)
    return newValue[path]
  },

  async find(
    { path }: { path: DictPaths },
    { session }: { session: SessionState },
  ) {
    const id = session.currentReflection?.id

    if (!id) return null

    const { data: newState }: { data: DictState } =
      await personalDictApi$getPersonalDict.api(id)

    dispatch.dicts.set(newState)

    // HACK axios-config 会默认把响应体内的 data 转为驼峰式
    const camelizePath = humps.camelize(path)

    return newState[camelizePath]
  },
})

/**
 * 使用字典，仅供表格使用
 * @deprecated use feature-utils/personal-configs#usePersonalConfig instead
 * @param path
 * @param defaultColumnKeys
 */
export const useTableDict = (
  path?: DictPaths | string,
  defaultColumnKeys?: string[],
) => {
  const dispatch = useDispatch<any>()
  const dictState = useSelector((state: any) => state.dicts)

  useEffect(() => {
    // 未传 path 时不发请求
    if (!path) return

    dispatch.dicts.find({ path })
  }, [dispatch.dicts, path])

  const setValue = useCallback(
    val => {
      dispatch.dicts.update({ path, value: val })
    },
    [path, dispatch.dicts],
  )

  // 未传 path 时返回无效值
  if (!path) return [null, noop] as const
  const value = dictState?.[humps.camelize(path)]

  return [
    value || {
      columnKeys: defaultColumnKeys,
    },
    setValue,
  ] as const
}

/**
 * 使用线上字典
 * @deprecated use feature-utils/personal-configs#usePersonalConfig instead
 */
export const useDictOnline = (path?: DictPaths | string) => {
  const dispatch = useDispatch<any>()
  const dictState = useSelector((state: any) => state.dicts)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // 未传 path 时不发请求
    if (!path) return

    setLoading(true)
    dispatch.dicts.find({ path }).finally(() => {
      setLoading(false)
    })
  }, [dispatch.dicts, path])

  return [
    dictState?.[humps.camelize(path || '')],
    useCallback(
      val => (path ? dispatch.dicts.update({ path, value: val }) : noop),
      [path, dispatch.dicts],
    ),
    loading,
  ] as const
}

/**
 * 使用本地存储的字典
 * @deprecated use feature-utils/personal-configs#usePersonalConfig instead
 */
export const useDictLocal = (path?: DictPaths | string) => {
  const [dicts, setDicts] = useLocalStorageState<{ [key: string]: string }>(
    'seiue_dicts',
    {},
  )

  const value = useMemo(() => {
    if (!path) return undefined

    const v = dicts?.[path]

    if (!v) return undefined

    return JSON.parse(v)
  }, [dicts, path])

  const setValue = useCallback(
    (_v: any) => {
      if (!path) return

      setDicts(_dicts => ({
        ..._dicts,
        [path]: JSON.stringify(_v),
      }))
    },
    [setDicts, path],
  )

  return [value, setValue] as const
}

/**
 * 使用字典，任意场景使用
 * @deprecated use feature-utils/personal-configs#usePersonalConfig instead
 */
export const useDict = (path?: DictPaths | string, local?: boolean) => {
  const resultOnline = useDictOnline(path)
  const resultLocal = useDictLocal(path)

  if (local) {
    return [...resultLocal, false] as const
  }

  return resultOnline
}

export const dicts = { state: defaultState, reducers, effects }
