import { createEnumHelper, max } from '@seiue/util'

import { isPublishedItem } from 'packages/feature-utils/classes'
import { TagStatusEnum } from 'packages/feature-utils/grades'
import {
  quantitative,
  isDimension,
} from 'packages/feature-utils/grades/utils/item'
import { SavedScoresType } from 'packages/feature-utils/moral-assessments'
import { $t } from 'packages/locale'
import {
  ComputeTypeEnum,
  DisplayLevelItemEnum,
  DisplayTypeEnum,
  ItemStatusEnum,
  ItemTypeEnum,
  RelationsEnum,
  ScoringTypeEnum,
  Item,
  ScoreStatusEnum,
} from 'packages/sdks-next/vnas'

export {
  quantitative,
  isDimension,
  isSpecialItem,
  isScoringTypeQuantitative,
  isItemWithinScore,
  formatItemLockAllowedTime,
} from 'packages/feature-utils/grades/utils/item'

export { TagStatusEnum }

/**
 * 获取评价项类型标签
 *
 * @param type - 评价项类型
 * @returns 类型
 */
export const getItemTypeLabel = (type: ItemTypeEnum): string => {
  switch (type) {
    case ItemTypeEnum.Dimension:
      return $t('维度')
    case ItemTypeEnum.SubDimension:
      return $t('子维度')
    case ItemTypeEnum.Item:
      return $t('评价项')
    case ItemTypeEnum.SpecialItem:
      return $t('特殊评价项')
    case ItemTypeEnum.AttendanceItem:
      return $t('考勤评价项')
    default:
      return ''
  }
}

/**
 * 获取维度计算类型标签
 *
 * @param type - 维度计算类型
 * @param options - 可选参数
 * @param options.avgHighScoreCount - 最高几项取平均分
 * @returns 类型标签
 */
export const getComputeTypeLabel = (
  type?: ComputeTypeEnum | null,
  options?: {
    avgHighScoreCount?: number | null
  },
) => {
  if (options?.avgHighScoreCount && type === ComputeTypeEnum.Avg) {
    return $t('平均（取分数最高的 {num} 项计算平均分）', {
      num: options?.avgHighScoreCount,
    })
  }

  switch (type) {
    case ComputeTypeEnum.Avg:
      return $t('平均')
    case ComputeTypeEnum.Sum:
      return $t('求和')
    case ComputeTypeEnum.WeightedAvg:
      return $t('加权平均')
    default:
      return ''
  }
}

/**
 * 获取计算类型选项
 *
 * @returns 选项
 */
export const getComputeTypeOptions = () => [
  {
    value: ComputeTypeEnum.Sum,
    label: getComputeTypeLabel(ComputeTypeEnum.Sum),
  },
  {
    value: ComputeTypeEnum.Avg,
    label: getComputeTypeLabel(ComputeTypeEnum.Avg),
  },
  {
    value: ComputeTypeEnum.WeightedAvg,
    label: getComputeTypeLabel(ComputeTypeEnum.WeightedAvg),
  },
]

/**
 * 获取评价项类型选项
 *
 * @param type - 打分方式
 * @returns 选项
 */
export const getScoringTypeLabel = (type: ScoringTypeEnum) => {
  switch (type) {
    case ScoringTypeEnum.Addition:
      return $t('加减分')
    case ScoringTypeEnum.Level:
      return $t('等级')
    case ScoringTypeEnum.Review:
      return $t('评语')
    case ScoringTypeEnum.Score:
      return $t('分数')
    case ScoringTypeEnum.Star:
      return $t('星星')
    case ScoringTypeEnum.Tag:
      return $t('标签')
    default:
      return ''
  }
}

export const scoringTypeToLabel = createEnumHelper(() => ({
  [ScoringTypeEnum.Addition]: $t('加减分'),
  [ScoringTypeEnum.Level]: $t('等级'),
  [ScoringTypeEnum.Review]: $t('评语'),
  [ScoringTypeEnum.Score]: $t('分数'),
  [ScoringTypeEnum.Star]: $t('星星'),
  [ScoringTypeEnum.Tag]: $t('标签'),
}))

const qualitative = [ScoringTypeEnum.Tag, ScoringTypeEnum.Review]

/**
 * 获取成绩形式标签
 *
 * @param params - 参数
 * @param params.type - quantitative 定量 qualitative 定性
 * @param params.relation - 评价人
 * @returns 标签选项
 */
export const getScoringTypeOptions = (params?: {
  type?: 'quantitative' | 'qualitative'
  relation?: RelationsEnum
}) => {
  const { type, relation } = params || {}

  let optionValues: ScoringTypeEnum[] = []
  if (!type) {
    optionValues = [...quantitative, ...qualitative]
  } else if (type === 'quantitative') {
    optionValues = quantitative
  } else if (type === 'qualitative') {
    optionValues = qualitative
  }

  // 根据评价人类型过滤分数形式选项
  const filteredOptionValues = optionValues.filter(v => {
    // 如果是小组互评，不可选择加减分、等级
    if (relation === RelationsEnum.StudentMarkInTeam) {
      return ![ScoringTypeEnum.Addition, ScoringTypeEnum.Level].includes(v)
    }

    return true
  })

  return filteredOptionValues.map(v => ({
    value: v,
    label: getScoringTypeLabel(v),
  }))
}

/**
 * 判断某个评价项类型是否需要发布
 *
 * @param type - 打分方式
 * @returns 是否
 */
export const isNeedPublished = (type: ScoringTypeEnum) =>
  [
    ScoringTypeEnum.Score,
    ScoringTypeEnum.Level,
    ScoringTypeEnum.Star,
    ScoringTypeEnum.Review,
  ].includes(type)

/**
 * 判断某个评价项是否可能拥有多个子分数
 *
 * @param item - 评价项
 * @returns 是否
 */
export const isItemHasRawScores = (item: Item) =>
  item.type === ItemTypeEnum.SpecialItem ||
  item.scoringType === ScoringTypeEnum.Addition ||
  item.scoringType === ScoringTypeEnum.Tag

/**
 * 获取评价项标签状态
 *
 * @param status - 评价项状态
 * @returns 标签状态
 */
export const getItemTagsStatus = (status: ItemStatusEnum) =>
  ({
    [ItemStatusEnum.Published]: 'success' as const,
    [ItemStatusEnum.Rejected]: 'danger' as const,
    [ItemStatusEnum.Submitted]: 'warning' as const,
    [ItemStatusEnum.Pending]: 'default' as const,
    [ItemStatusEnum.SomePublished]: 'default' as const,
    [ItemStatusEnum.Passed]: 'ongoing' as const,
  }[status])

/**
 * 获取评价项状态文本
 *
 * @param status - 评价项状态
 * @returns 状态文本
 */
export const getItemStatusLabel = (status?: ItemStatusEnum) => {
  if (!status) return $t('未知状态')
  return {
    [ItemStatusEnum.Pending]: $t('未提交'),
    [ItemStatusEnum.Submitted]: $t('待审核'),
    [ItemStatusEnum.Rejected]: $t('已驳回'),
    [ItemStatusEnum.Published]: $t('已发布'),
    [ItemStatusEnum.SomePublished]: $t('部分发布'),
    [ItemStatusEnum.Passed]: $t('已通过'),
  }[status]
}

/**
 * 获取评价项状态列表
 *
 * @returns 状态列表
 */
export const getItemStatusOptions = () =>
  [
    ItemStatusEnum.Pending,
    ItemStatusEnum.Submitted,
    ItemStatusEnum.Rejected,
    ItemStatusEnum.Published,
    ItemStatusEnum.Passed,
  ].map(v => ({
    value: v,
    label: getItemStatusLabel(v),
  }))

/**
 * 获取分数显示设置标签名
 *
 * @param type - 显示方式
 * @returns 显示方式文本
 */
export const getDisplayTypeLabel = (type: DisplayTypeEnum) =>
  ({
    [DisplayTypeEnum.Level]: $t('等级'),
    [DisplayTypeEnum.Score]: $t('分数'),
    [DisplayTypeEnum.Star]: $t('星星'),
  }[type])

/**
 * 获取分数显示设置标签列表
 *
 * @returns 显示方式列表
 */
export const getDisplayTypeOptions = () => {
  const values = [
    DisplayTypeEnum.Score,
    DisplayTypeEnum.Star,
    DisplayTypeEnum.Level,
  ]

  return values.map(v => ({
    value: v,
    label: getDisplayTypeLabel(v),
  }))
}

/**
 * 获取等级显示设置标签名
 *
 * @param item - 等级显示设置
 * @returns 等级显示设置的标签名
 */
export const getDisplayLevelItemsLabel = (item: DisplayLevelItemEnum) =>
  ({
    [DisplayLevelItemEnum.Name]: $t('等级'),
    [DisplayLevelItemEnum.Score]: $t('分数'),
    [DisplayLevelItemEnum.Description]: $t('等级描述'),
  }[item])

/**
 * 获取等级显示设置标签列表
 *
 * @param values - 等级显示设置
 * @returns 标签列表
 */
export const getDisplayLevelItemsOptions = (
  values?: DisplayLevelItemEnum[],
) => {
  const defaultValues = [
    DisplayLevelItemEnum.Name,
    DisplayLevelItemEnum.Score,
    DisplayLevelItemEnum.Description,
  ]

  return (values || defaultValues).map(value => {
    const isNameValue = value === DisplayLevelItemEnum.Name
    const label = getDisplayLevelItemsLabel(value)
    return {
      value,
      label: isNameValue ? $t('{label}（必选）', { label }) : label,
    }
  })
}

/**
 * 获取标签 label
 *
 * @param status - 标签状态
 * @returns 标签文本
 */
export const getTagStatusLabel = (status: TagStatusEnum) =>
  ({
    [TagStatusEnum.Default]: $t('默认'),
    [TagStatusEnum.Positive]: $t('鼓励'),
    [TagStatusEnum.Negative]: $t('批评'),
  }[status])

/**
 * 获取标签列表
 *
 * @returns 标签列表
 */
export const getTagStatusOptions = () => {
  const values = [
    TagStatusEnum.Default,
    TagStatusEnum.Positive,
    TagStatusEnum.Negative,
  ]

  return values.map(value => ({
    value,
    label: getTagStatusLabel(value),
  }))
}

/**
 * 是否是平均维度
 *
 * @param item - 评价项
 * @returns 是否
 */
export const isAvgDimension = (item: Pick<Item, 'type' | 'computeType'>) =>
  isDimension(item) && item.computeType === ComputeTypeEnum.Avg

export const moralRelationToLabel = createEnumHelper(() => ({
  [RelationsEnum.Custom]: $t('教师评价'),
  [RelationsEnum.MarkSelf]: $t('自评'),
  [RelationsEnum.StudentMarkInTeam]: $t('小组互评'),
  [RelationsEnum.GuardianMarkStudent]: $t('家长评价'),
}))

export const classRelationToLabel = createEnumHelper(() => ({
  [RelationsEnum.TeacherMarkStudent]: $t('教师评价'),
  [RelationsEnum.MarkSelf]: $t('自评'),
}))

/**
 * 是否存在未发布的评价项
 *
 * @param items - 评价项列表
 * @returns 是否
 */
export const hasNotPublishedItem = (items?: Item[]) =>
  items?.some(
    item =>
      item.type === ItemTypeEnum.Item &&
      item.status !== ItemStatusEnum.Published,
  ) || false

/**
 * 是否存在未通过的评价项
 *
 * @param items - 评价项列表
 * @returns 是否
 */
export const hasNotPassedItem = (items?: Item[]) =>
  items?.some(
    item =>
      item.type === ItemTypeEnum.Item && item.status !== ItemStatusEnum.Passed,
  ) || false

/**
 * 当前成绩形式是否支持配置多人评价
 *
 * @param scoringType - 成绩形式
 * @returns boolean
 */
export const isSupportMultiEvaluators = (scoringType: ScoringTypeEnum) => {
  return [
    ScoringTypeEnum.Score,
    ScoringTypeEnum.Star,
    ScoringTypeEnum.Review,
  ].includes(scoringType)
}

/**
 * 判断是否为标签类评价项（个性化评价/考勤/加减分/标签签）
 *
 * @param item - 指定评价项
 * @returns 是否
 */
export const isTagItem = (item: Item) => {
  return (
    item.type === ItemTypeEnum.SpecialItem ||
    item.type === ItemTypeEnum.AttendanceItem ||
    item.scoringType === ScoringTypeEnum.Addition ||
    item.scoringType === ScoringTypeEnum.Tag
  )
}

/**
 * 获取评分状态
 *
 * @param root0 --参数
 * @param root0.item -- 评价项
 * @param root0.getItemScore -- 分数
 * @returns publish 是否发布，noNeedReview 是否暂不评价
 */
export const getItemStatus = ({
  item,
  getItemScore,
}: {
  item: Item
  getItemScore: any
}) => {
  const { originalScore } = getItemScore(item)
  return {
    isSubmitted: item.status === ItemStatusEnum.Submitted,
    isPublish: isPublishedItem(item, originalScore),
    noNeedReview: originalScore?.valid !== false,
  }
}

/**
 * 获取宿舍评价的评价项发布状态
 * 由于宿舍评价存在部分发布的情况，所以用当前分数的发布情况，来显示计算评价项的发布情况
 *
 * @param scores - 成绩列表数据
 * @returns 宿舍评价的评价项发布状态
 */
export const getDormItemPublishStatus = (scores: SavedScoresType) => {
  let status = ItemStatusEnum.Pending
  let publishedCount = 0
  let unpublishCount = 0

  if (scores?.length) {
    scores.forEach(score => {
      if (score?.status === ScoreStatusEnum.Published) {
        publishedCount += 1
      } else {
        unpublishCount += 1
      }
    })

    if (publishedCount !== 0) {
      if (unpublishCount) {
        status = ItemStatusEnum.SomePublished
      } else {
        status = ItemStatusEnum.Published
      }
    }
  }

  return [status, publishedCount, unpublishCount] as const
}

/**
 * 根据给定的评价项列表计算最大排序值
 *
 * @param items - 评价项列表
 * @returns 最大排序值
 */
export const computeMaxSortByItems = (items: Item[]) => {
  return (
    (max(
      items
        .filter(
          // 保证个性化评价在最下面
          item => item.type !== ItemTypeEnum.SpecialItem,
        )
        .map(item => item.sort || 0),
    ) ?? 0) + 1
  )
}
