import { $ct } from 'packages/locale'

import { EnabledPlugin } from './types'

/**
 * 获取插件实例的显示名称.
 *
 * @param plugin - 插件
 * @returns 显示名称
 */
export const getPluginLabel = (plugin: EnabledPlugin) => {
  return $ct(plugin.label, 'plugin')
}
