import {
  BaseSeriesLabelType,
  BaseSeriesType,
  CommonSeriesContentType,
  SeriesContentTypeEnum,
  WidgetChartOption,
  WithoutChartType,
} from 'packages/components/ReportCenter/Reporting/types'
import { BarChart, StackedBarChart, WidgetType } from 'packages/sdks-next/nuwa'

/**
 * 柱状图/条形图的文本标签位置
 */
export enum BarSeriesLabelPosition {
  Top = 'top',
  InsideTop = 'insideTop',
  Inside = 'inside',
  InsideBottom = 'insideBottom',
  InsideLeft = 'insideLeft',
  InsideRight = 'insideRight',
  Right = 'right',
}

type BarSeriesLabelType = BaseSeriesLabelType<
  BarSeriesLabelPosition,
  SeriesContentTypeEnum
>
export type BarSeriesType = BaseSeriesType<BarSeriesLabelType>
type BarChartOption = WidgetChartOption<BarSeriesType>

export type BarChartExtra = WithoutChartType<
  BarChart,
  WidgetType.BarChart,
  BarChartOption
>

/** 堆叠柱状图 **/
type StackBarSeriesLabelPosition = Exclude<
  BarSeriesLabelPosition,
  BarSeriesLabelPosition.Top
>

type StackedBarSeriesLabelType = BaseSeriesLabelType<
  StackBarSeriesLabelPosition,
  CommonSeriesContentType
>
type StackedBarChartSeriesType = BaseSeriesType<StackedBarSeriesLabelType>
type StackedBarChartOption = WidgetChartOption<StackedBarChartSeriesType>

export type StackedBarChartExtra = WithoutChartType<
  StackedBarChart,
  WidgetType.StackedBarChart,
  StackedBarChartOption
>
