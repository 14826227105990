/**
 * @file types
 */

import type { LoadableComponent } from '@loadable/component'
import { Dictionary } from '@seiue/util'
import { LazyExoticComponent, FunctionComponent } from 'react'

import { ModuleEnum, PluginEnum } from 'packages/feature-utils/plugins'
import { RoleEnum, PermissionNameEnum } from 'packages/sdks-next/chalk'
import type { MenuType } from 'packages/web-layout/menu-types'

type IconSource = any

export type ModalRouteNode = {
  name: string
  getTitle: () => string
  component: LazyExoticComponent<FunctionComponent<any>>
}

type GroupConfig<T> = T | readonly [T, string]

export type RouteMenuGroupConfig = () =>
  | GroupConfig<MenuType.Apps>
  | GroupConfig<MenuType.Admin>
  | GroupConfig<MenuType.Default>
  | GroupConfig<MenuType.AdminApps>

export type RouteMenuConfig = {
  /**
   * 指定 MenuType 以及可选的菜单组名称
   */
  group: RouteMenuGroupConfig
  /**
   * 菜单组名称旁边的 icon
   */
  icon?: IconSource
}

export type RouteConfig = {
  // 考虑向后兼容, 暂时设为 optional
  name?: string

  // relative path here
  path: string
  getTitle: () => string
  component:
    | LazyExoticComponent<FunctionComponent<any>>
    | LoadableComponent<any>

  /**
   * 路由的菜单定义
   * 应使用最新的菜单定义
   *
   * @deprecated
   */
  menu?: RouteMenuConfig

  /**
   * 按后端返回的权限筛选，满足其中一个则为有权限；
   * 和 `requireManageablePlugins` 满足一个即可
   */
  requirePermissions?: PermissionNameEnum[]

  /**
   * 按插件管理员权限筛选，满足其中一个则为有权限；适用于某些用户为 XX 管理员，但未单独赋予 XX.read/write 的情况；
   * 和 `requirePermissions` 满足一个即可
   */
  requireManageablePlugins?: (ModuleEnum | PluginEnum)[]

  // 按角色筛选，优先级：`requireRoles` > `requirePermissions`
  requireRoles?: RoleEnum[]

  /**
   * 用 school id 指定开放的学校
   *
   * WARNING: 在有插件商店管理插件开关的情况下，不应当用到这个字段，
   * 只用于特殊时期和特殊情况下的 hack 需求
   */
  requireSchools?: number[]

  /**
   * 指定隐藏的学校
   *
   * WARNING: 在有插件商店管理插件开关的情况下，不应当用到这个字段，
   * 只用于特殊时期和特殊情况下的 hack 需求
   */
  hideSchools?: number[]

  // 自定义字段
  customMeta?: { [key: string]: any }

  subRoutes?: RouteConfig[]

  /*
   * 路由所属菜单组定义，会用于菜单的懒加载
   * 侧边栏的菜单会依据当前路由配置的最后一个 module 匹配展示
   */
  module?: string[]

  /*
   * 注册该路由需要的模块名称
   * 设置后，只有学校开启了该模块，路由才会被注册
   */
  requireModules?: (ModuleEnum | PluginEnum)[]
  /**
   * 是否显示侧边栏菜单, 默认 true
   */
  showSidebarMenu?: boolean
  /**
   * 分享页面配置
   */
  shareConfig?: {
    /**
     * 是否匿名
     * 即隐藏顶部栏的用户信息
     */
    anonymous?: boolean
  }
}

export type RouteNode = Omit<RouteConfig, 'subRoutes'> & {
  exact?: boolean
  parentPath?: string
  subRoutes?: RouteNode[]
}

export interface Query {
  [key: string]: any
}

export enum ModalMode {
  Push = 'push',
  Replace = 'replace',
}

export const MODAL_QUERY_NAMESPACE = 'modalQuery'

export type RouteParams = {
  [key: string]:
    | string
    | string[]
    | number
    | number[]
    | boolean
    | null
    | undefined
}

export type RouteParamsList = { [key: string]: RouteParams | null }

export type RouteModalParams = {
  [key: string]:
    | string
    | string[]
    | number
    | number[]
    | boolean
    | undefined
    | Dictionary<any>
}

export type RouteModalParamsList = { [key: string]: RouteModalParams | null }
