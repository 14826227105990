import { getPersistor } from '@rematch/persist'
import { isAxiosError } from '@seiue/axios'
import {
  AlertMetaError,
  AlertThenLogoutMetaError,
  filterAndReportError,
} from '@seiue/error-handler'
import { moment, Formatter } from '@seiue/moment'
import { setSentryContext } from '@seiue/sentry'
import { find, isReactNative, parseURLQuery } from '@seiue/util'

import {
  getSchoolDomainFromURL,
  loadSchool,
} from 'packages/feature-utils/schools'
import { decodeRidFromAccessToken } from 'packages/feature-utils/session'
import { BindTypeEnum } from 'packages/features/accounts/utils'
import { isPublicShareURL } from 'packages/features/shares/utils'
import { $t } from 'packages/locale'
import { Expand } from 'packages/sdks-next'
import {
  Reflection,
  BindPhone,
  RoleEnum,
  OAuthToken,
  School,
  User,
  oAuthApi$info,
  roleApi$ownedPermissions,
  roleApi$joinedRoles,
  userApi$bindPhoneToUser as userApiChalk$bindPhoneToUser,
  userApi$unbindField as userApiChalk$unbindField,
} from 'packages/sdks-next/chalk'
import { sendDataToRnWebview } from 'packages/utils/rn-webview'
import { v3RoleToV2 } from 'packages/utils/user'
import { getWechatSdk } from 'packages/wechat'

import { passport } from '../../../passport'
import {
  authorize,
  requestToken,
  isSSOLogin,
  isTokenDying,
  AuthorizeParams,
  SessionState,
  removeTokenAuthParamsInUrl,
  getSSOProviderFromUrl,
  getSchoolIdFromUrl,
} from '../utils'

/* eslint-disable seiue/missing-formatted-message */
/* FIXME: session 创建时 locale 未初始化 */

const loadSchoolByDomainFromURL = async () => {
  const domain = getSchoolDomainFromURL()

  if (!domain) return null
  try {
    const { data: school } = await loadSchool(domain)
    return school
  } catch (e) {
    if (isAxiosError(e, 404)) {
      throw new AlertMetaError({
        title: '学校不存在',
        content: `不存在域名为 ${domain}.seiue.com 的学校，请重新输入网址，或点击按钮前往选择学校页面。`,
        report: false,
        onConfirm: () => passport.logout(),
      })
    }

    // 其他情况静默失败, 就算这里未成功加载学校, 后续逻辑依然能执行
    return null
  }
}

/* eslint-disable max-lines-per-function */

/**
 * session store 的 effects
 *
 * @param dispatch - redux dispatch
 * @returns session store effects
 */
export const effects = (dispatch: any) => ({
  async init(
    params:
      | (
          | AuthorizeParams
          | {
              type: 'token'
              oAuthToken?: OAuthToken
            }
        )
      | void,
  ): Promise<{
    currentUser: Expand<User, ['reflections']>
    currentReflection: Reflection
  }> {
    try {
      dispatch.session.setState('pending')

      const result = await dispatch.session.create(params)

      dispatch.session.setState('created')

      return result
    } catch (e) {
      dispatch.session.setState('none')

      throw e
    }
  },

  async create(
    params: AuthorizeParams | void,
    { session }: { session: SessionState },
  ) {
    // Try load school by domain in url
    const school = await loadSchoolByDomainFromURL()
    if (school) dispatch.session.setCurrentSchool(school)

    const ssoProvider = getSSOProviderFromUrl()

    if (ssoProvider) {
      dispatch.session.setSsoProvider(ssoProvider)
    }

    // Auth for access token if auth params provided (otherwise auth via passport)
    const oAuthToken = params ? await authorize(params) : null

    if (params) {
      /**
       * 腾讯登录没有办法指定 rid（url 中不含 rid，也不 setup passport），
       * 所以必须清除，否则旧缓存会一直生效
       */
      if (params.type === 'tencent') {
        dispatch.session.resetState()
      }

      dispatch.session.setAuthType(params.type)
    }

    const rid = await dispatch.session.getTargetReflectionId({
      /**
       * 以下情况都不需要 setup passport：
       * 1. native（走 refresh-token 机制）
       * 2. native 中的 webview，token 由 url 传入，
       *    正常用户有 refresh-token，Apollo 代登录没有 refresh-token，
       *    所以不能完全 = 有 refresh token 的情况
       * 3. 新 token 有 refreshToken（走 refresh-token 机制）
       * 4. 缓存的 token 有 refresh-token（走 refresh-token 机制）
       * 5. 腾讯登录（走 refresh-token 机制，且腾讯校园的微信小程序也不支持第三方域名 iframe）
       * 6. 令牌登录（走 ticket 机制）
       */
      setupPassport:
        !isReactNative &&
        !window?.navigator?.userAgent?.includes?.('c3app') &&
        !oAuthToken?.refreshToken &&
        !session.oAuthToken?.refreshToken &&
        (!params || !['tencent', 'token', 'ticket'].includes(params.type)),
      authParams: params,
      oAuthToken,
    })

    // 此时才 set token 以免跟旧党一起被 validateCache 清除掉
    if (oAuthToken) dispatch.session.setOAuthToken(oAuthToken)

    // 存储一下 Ticket 信息
    if (params && params.type === 'ticket') {
      dispatch.session.setAuthTicket(params.ticket)
    }

    const user: SessionState['currentUser'] =
      await dispatch.session.getCurrentUser()

    const result = await dispatch.session.setUser({
      user,
      rid,
      school,
    })

    if (params?.type === 'token') {
      // 在登录成功后，移除掉 url 中的 token 信息，防止用户通过复制链接的方式，将 token 错误的分享出去
      removeTokenAuthParamsInUrl()
    }

    // 立即将持久化数据写入存储
    await getPersistor().flush()

    return result
  },

  // 将用户信息根据一定的逻辑存入 store
  async setUser({
    user,
    rid,
    school,
  }: {
    user: SessionState['currentUser']
    rid: number
    school?: School | null
  }) {
    const enabledReflections = user.reflections.filter(
      r => !r.disabled && (!school || r.schoolId === school.id),
    )

    /*
     * 如果不存在有效身份时，即刻登出
     * 当一个用户底下的身份被全部删除/禁用时，但之前登录过，保留了 Token，就会出现这种情况
     */
    if (!enabledReflections.length) {
      throw new AlertThenLogoutMetaError({
        title: school
          ? `你登录的账号在${school.name}没有有效的身份`
          : `你登录的账号没有有效的身份`,
        content: $t('请联系教务老师，或使用其他账号登录。'),
        report: false,
      })
    }

    /*
     * 如果未能取到指定的 Reflection，则取出默认身份的 reflection
     * 拿不到默认身份的 reflection，就取第一个
     */
    const reflection =
      find(enabledReflections, r => r.id === rid) ??
      find(enabledReflections, { isDefault: 1 }) ??
      enabledReflections[0]

    // 为了加快首屏渲染速度，此处不等待 setCurrentReflection 内部的异步副作用初始化执行完毕
    dispatch.session.setCurrentReflection(reflection)

    // 记录用户最后一次登录时间
    dispatch.session.setLastLoginAt(moment().format(Formatter.DateFullTime))

    /**
     * 注册基于 user 而非 reflection 的服务，
     * 如果是基于 reflection 应放到 runSide...Reflection 中
     */
    await this.registerThirdParties()

    // 返回 currentUser 和 currentReflection 以方便外部设计业务逻辑
    return {
      currentUser: user,
      currentReflection: reflection,
    }
  },

  /**
   * App 注册第三方服务
   */
  async registerThirdParties() {
    if (isReactNative) {
      dispatch.app.registerThirdParties()
    }
  },

  /**
   * App 取消注册第三方服务
   */
  async unregisterThirdParties() {
    if (isReactNative) {
      await dispatch.push.unregister()
    }
  },

  async getCurrentUser() {
    const user = (
      await oAuthApi$info.api(
        {
          expand: ['reflections'] as const,
          tryExpand: [['reflections', 'archivedType']] as const,
        },
        {
          omitReflectionHeaders: true,
        },
      )
    ).data

    dispatch.session.setCurrentUser(user)
    return user
  },

  async getTargetReflectionId(
    {
      setupPassport,
      authParams,
      oAuthToken,
    }: {
      setupPassport: boolean
      authParams: AuthorizeParams | void
      oAuthToken?: OAuthToken
    },
    { session: { currentReflection, currentUser } }: { session: SessionState },
  ) {
    let externalRid = 0
    if (isReactNative) {
      const nextOAuthToken =
        authParams && authParams?.type === 'token'
          ? authParams.oAuthToken.accessToken
          : oAuthToken?.accessToken

      if (nextOAuthToken) {
        const tokenRid = decodeRidFromAccessToken(nextOAuthToken)

        externalRid = tokenRid ?? externalRid
      }
    } else if (
      authParams &&
      authParams.type === 'ticket' &&
      authParams.ticket?.reflectionId
    ) {
      externalRid = authParams.ticket.reflectionId
    } else {
      // 读取 url 中指定的 rid
      externalRid = Number(parseURLQuery(window.location.search)['rid'])
    }

    // 读取 passport 中的 uid 和 rid
    let passportUid = 0
    let passportRid = 0
    if (setupPassport) {
      const { uid, rid } = await passport.setup()
      passportRid = rid
      passportUid = uid
    }

    /**
     * 读取缓存的 rid，
     * 如果 passport 中的 current user 与缓存不符，直接清除缓存，并置缓存 rid 为 0
     */
    let cachedRid = currentReflection?.id ?? 0
    if (passportUid && passportUid !== currentUser?.id) {
      dispatch.session.resetState()
      cachedRid = 0
    }

    /*
     * 外部指定的 rid：指定的 rid > passport rid
     * FIXME: 这里无法使用 ||=，因为 React Native Android 不支持
     */
    externalRid = externalRid || passportRid

    // 优先以外部指定的 rid 为目标 rid
    if (externalRid) {
      /**
       * 如果外部指定了 rid，且当前缓存中不存在该 id 的 reflection，则清除缓存
       */
      if (!find(currentUser?.reflections, { id: externalRid })) {
        dispatch.session.resetState()
      }

      return externalRid
    }

    /**
     * 如果外部未指定 rid，则尝试返回缓存的 rid
     * （缓存有可能已经因为 passport 返回的 uid 和缓存不一致而被清空）
     */
    return cachedRid
  },

  async setCurrentReflection(reflection: Reflection | null) {
    dispatch.session.setCurrentReflectionReducer(reflection)

    if (reflection) {
      await dispatch.session.runSideEffectsOfSetCurrentReflection(reflection)
    }
  },

  async destroy(
    {
      setPassportRedirect,
      isElectron,
    }: {
      /**
       * 如果是登出到 passport, 则设置当前地址为 redirect_uri,
       * 使用户登录后能重新回到他原本想访问的页面
       */
      setPassportRedirect?: boolean
      /**
       * 是否为 Electron 应用
       */
      isElectron?: boolean
    } | void = {},
    { session }: { session: SessionState },
  ) {
    dispatch.session.setIsApolloLogin(false)
    dispatch.session.setSkipResetPassword(false)

    // 在 react native webview 中，点击退登会触发 app 退登
    if ((window as any).ReactNativeWebView) {
      ;(window as any).ReactNativeWebView.postMessage('logout')
    }

    // if in web and passport set up or in public-share page, log out to passport
    if (!isReactNative && (passport.isSetUp() || isPublicShareURL())) {
      // 在此方法中，我们优先考虑 URL 所指定的学校 ID
      const schoolId =
        getSchoolIdFromUrl() ||
        session.currentSchool?.id ||
        session.currentReflection?.schoolId

      const { ssoProvider } = session

      dispatch.session.setState('none')
      /*
       * 成功登出后，清空 token。在部分情况（比如修改密码后）下，token 会处于一种可以被校验通过，但使用异常的情况。
       * 在这些场景下，前端会主动登出账号，同时清理掉有问题的 token，登录后，重新获取。
       */
      dispatch.session.clearOAuthToken()

      // 等待更新缓存的登录信息后，再执行下一步
      await getPersistor().flush()

      passport.logout(schoolId, !!setPassportRedirect, ssoProvider)
    } else if (isReactNative) {
      /**
       * native 下登出后依然留在 app 中，所以重置 session 即可。
       * 除 native 外目前不是 passport 模式就是腾讯校园模式：
       * 1. passport 模式下不能清除，会导致跳转前当前页面就重渲染并报错。
       * 2. 腾讯校园模式在产品逻辑上就不能登出。
       */
      await this.unregisterThirdParties()

      // 当用户退出登录时，使用 purge 立即清除存储的用户相关的全局数据
      // https://github.com/rt2zz/redux-persist/blob/master/docs/api.md#type-persistor
      await getPersistor().purge()

      dispatch.session.resetState({
        state: 'none',
      })
    } else if (isSSOLogin(session.authType) && !passport.isSetUp()) {
      // 如果是单点登录，且没有使用 passport，那么这种情况我们不直接登出

      const wx = await getWechatSdk()
      if (wx) {
        try {
          wx.relogin()

          return
        } catch (e) {
          filterAndReportError(e, { ExceptionType: 'wechatReloginFail' })
        }
      }

      throw new AlertMetaError({
        title: $t('检测到你的登录信息已失效'),
        // FIXME，随着单点登录的情况变多，这里可能需要变更文案
        content: $t('请重新登录小程序，以免影响你继续使用'),
        report: false,
      })
    } else if (isElectron) {
      await getPersistor().purge()

      dispatch.session.resetState({
        state: 'none',
      })
    }
  },

  async refreshOAuthTokenIfNeeded(
    payload: void,
    { session }: { session: SessionState },
  ) {
    if (
      !session.oAuthToken ||
      !session.oAuthTokenFetchedAt ||
      isTokenDying(session.oAuthToken.expiresIn, session.oAuthTokenFetchedAt)
    ) {
      /*
       * 如果是 App，且 session 中没有 refreshToken，那么不再刷新。
       * 因为 App 必然依赖 refreshToken
       */
      if (isReactNative && !session.oAuthToken?.refreshToken) return

      try {
        /**
         * 避免未登录状态下的 token 刷新
         * 原因：跳转到 /logout 前会触发 refreshOAuthTokenIfNeeded，
         * 这个并发操作可能导致后端 session 未被正确清空(dev环境触发次数较多)
         */
        if (session.state === 'none') return
        const { data: token } = await requestToken(
          session.oAuthToken?.refreshToken,
        )

        dispatch.session.setOAuthToken(token)

        // Send new tokens back if we're in React Native webview
        sendDataToRnWebview('oAuthToken', token)
      } catch (e) {
        /*
         * 针对 refreshToken 时所产生的请求错误，根据 OAuth 的规范，token 过期，client 信息错误的错误码都是 400
         * 因此，在这个接口出现 400 时，令用户重新登录
         */
        if (isAxiosError(e, 400)) {
          throw new AlertThenLogoutMetaError({
            title: $t('你的登录信息已过期'),
            content: $t('请重新登录你的账号。'),
            report: false,
          })
        }

        throw e
      }
    }
  },

  async runSideEffectsOfSetCurrentReflection(
    currentReflection: Reflection,
    { session }: { session: SessionState },
  ) {
    setSentryContext(currentReflection)

    // userId 仅在家长账号未认证的时候是 null 时为空，而未认证的家长无法登录
    const { schoolId, id, role, userId } = currentReflection
    const isTeacher = [RoleEnum.Teacher, RoleEnum.Shadow].includes(role)
    passport.setSessionCache(userId as number, schoolId, id, v3RoleToV2(role))

    // Load permissions & role
    const [
      { data: permissions },
      { data: managerRoles },
      { data: managedRoles },
      { data: school },
    ] = await Promise.all([
      isTeacher
        ? roleApi$ownedPermissions.api()
        : Promise.resolve({ data: [] }),
      isTeacher
        ? roleApi$joinedRoles.api({
            paginated: 0,
            asManager: true,
            expand: ['assignments'],
          })
        : Promise.resolve({ data: [] }),
      isTeacher
        ? roleApi$joinedRoles.api({
            paginated: 0,
            asManager: false,
            expand: ['assignments'],
          })
        : Promise.resolve({ data: [] }),
      session.currentSchool?.id === schoolId
        ? Promise.resolve({ data: session.currentSchool })
        : loadSchool(schoolId),
    ])

    dispatch.session.setCurrentPermissions(permissions)
    dispatch.session.mergeCurrentRoles({
      roles: managerRoles,
      isManager: true,
      reset: true,
    })

    dispatch.session.mergeCurrentRoles({
      roles: managedRoles,
      isManager: false,
      reset: true,
    })

    dispatch.session.setCurrentSchool(school)

    // 初始化 role&permission 时，清空当前 Scopes，以达到完整的权限刷新效果。
    dispatch.session.clearCurrentScopes()
  },

  async bindPhone(payload: BindPhone, { session }: { session: SessionState }) {
    await userApiChalk$bindPhoneToUser.api(session.currentUser.id, payload)
    return this.getCurrentUser()
  },

  // 解绑当前用户的手机号或邮箱
  async unbind(
    {
      field,
      password,
    }: {
      field: BindTypeEnum.Phone | BindTypeEnum.EMail
      password: string
    },
    { session }: { session: SessionState },
  ) {
    const user = session.currentUser

    await userApiChalk$unbindField.api(user.id, field, { password })

    if (field === BindTypeEnum.Phone) {
      await dispatch.session.getCurrentUser()
    } else {
      dispatch.session.setCurrentUser({
        ...user,
        [field]: undefined,
      })
    }
  },
})
