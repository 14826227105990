import { Formatter, Moment, moment } from '@seiue/moment'

import { EarlyTypeEnum } from '../early-type-enum'
import { Venue as Base } from '../venue'

export type { VenueInterface } from '../venue'

/**
 * 场地
 */
export class Venue extends Base {
  /**
   * 需提前预约时间（小时）
   *
   * @returns 需提前预约时间（小时）
   */
  get earlyLimitHours() {
    const { earlyLimit, earlyType } = this
    if (!earlyLimit || !earlyType) return 0

    switch (earlyType) {
      case EarlyTypeEnum.Minutes:
        return earlyLimit / 60
      case EarlyTypeEnum.Hours:
        return earlyLimit
      case EarlyTypeEnum.Days:
        return earlyLimit * 24
      default:
        return 0
    }
  }

  /**
   * 判断预约时间是否超出最大允许预约天数
   *
   * @param startAt - 预约开始时间
   * @returns 是否
   */
  isExceedMaxOrderDays(startAt: Moment) {
    if (!this.enableMaxOrderDays) return false

    const notOpenStartAt = moment()
      .startOf('day')
      .add(this.maxOrderDays || 0, 'day')

    return !!(
      this.maxOrderDays &&
      moment(startAt.format(Formatter.DateFullTimeStart)).isAfter(
        notOpenStartAt,
      )
    )
  }
}
