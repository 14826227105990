import { moment } from '@seiue/moment'
import { isNumber, isNaN, floor } from '@seiue/util'

import { Item, ItemInterface } from 'packages/sdks-next/vnas'

/**
 * 评价项是否已锁定分数
 *
 * @param item - 评价项
 * @returns 是否
 */
export const isScoreLocked = (item: Item | ItemInterface) => {
  if (!item.lockScore) return false
  const { startAt, endAt } = item.lockRules || {}
  return startAt && endAt ? !moment().isBetween(startAt, endAt) : true
}

/**
 * Convert score to number
 *
 * @param param0 - 参数
 * @param param0.value - 参数
 * @param param0.precision - 精度
 * @param param0.defaultValue - 默认值
 * @returns 数字
 */
export const toNumberScore = ({
  value,
  precision,
  defaultValue,
}: {
  value: any
  precision?: number
  defaultValue?: any
}) => {
  const parsed = Number(value)
  if (isNaN(parsed)) {
    return defaultValue
  }

  return isNumber(precision) ? floor(parsed, precision) : parsed
}
