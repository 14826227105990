import { Formatter, moment, Moment, MomentInput } from '@seiue/moment'
import { assign, reduce, useOnUpdate } from '@seiue/util'
import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'

import { TermTypeEnum } from 'packages/features/terms/types'
import { $ct } from 'packages/locale'
import {
  QuerySemestersQuery,
  Term,
  semesterApi$querySemesters,
  semesterApi$loadSemester,
  semesterApi$getCurrent,
  semesterApi$createSemester,
  semesterApi$deleteSemester,
  Semester,
  termApi$queryTerm,
} from 'packages/sdks-next/chalk'
import {
  timetableApi$queryTimetables,
  semesterApi$updateSemesterAndTimetable as scmsSemesterApi$updateSemesterAndTimetable,
} from 'packages/sdks-next/scms'
import { MutationOptions, QueryOptions } from 'packages/sdks-next/types'

import { useTermOptions, useTermOptionsForTable, useTerms } from '../terms/data'

import {
  currentSemesterAtom,
  selectedSemesterAtom,
  semestersAtom,
} from './atoms'
import {
  dateToSemesterWeek,
  findSemesterByDate,
  isDateInSemester,
  semesterWeekToDates,
  useHackSemesterData,
} from './utils'

type SemesterCategoryNameById = Record<number, string>

const reduceCategoryNameById = (categories: Term[]) =>
  reduce(
    categories,
    (result, item) => assign(result, { [item.id]: item.name }),
    {} as SemesterCategoryNameById,
  )

/**
 * 全部学期 hook.
 *
 * @param query - 查询参数
 * @param queryOption - 查询选项
 * @returns [semesters, reload]
 */
export const useSemesters = (
  query?: QuerySemestersQuery,
  queryOption?: QueryOptions,
) => {
  const [semesters, set] = useAtom(semestersAtom)
  const { disable } = queryOption ?? {}

  const { data: cateNameById } = useTerms(
    { type: TermTypeEnum.SystemSemesterCategory },
    { select: reduceCategoryNameById },
  )

  const { data, reload, loading } = semesterApi$querySemesters.useApi(
    {
      paginated: 0,
      sort: '-start_at',
      expand: ['isCurrent'] as const,
      ...query,
    },
    {
      staleTime: 5 * 60,
      disable: !cateNameById || disable,
      select: res => {
        if (!cateNameById) return res
        return res.map(smst => {
          const cateName = cateNameById[smst.categoryId]
          const name = smst.name.replace(
            cateName,
            $ct(cateName, 'semester_category'),
          )

          return assign(smst, { name })
        })
      },
    },
  )

  const _semesters = useHackSemesterData(data)

  useEffect(() => {
    if (_semesters) set(_semesters)
  }, [_semesters, set])

  return [semesters, reload, loading] as const
}

/**
 * 当前学期 hook.
 *
 * @returns 当前学期
 */
export const useCurrentSemester = () => {
  return useAtom(currentSemesterAtom)[0]
}

/**
 * 全局选中的学期 hook.
 *
 * @returns [selectedSemester, selectedSemester id setter]
 */
export const useSelectedSemester = () => {
  return useAtom(selectedSemesterAtom)
}

/**
 * 全局选中的学期 i d hook.
 *
 * @returns selectedSemesterId
 */
export const useSelectedSemesterId = () => {
  const [semester] = useAtom(selectedSemesterAtom)

  return semester?.id
}

/**
 * 按 id 查询学期 hook.
 *
 * @param id - 学期 id
 * @returns 查到的学期
 */
export const useSemesterById = (id?: number) => {
  const [semesters] = useSemesters()
  return semesters.find(s => s.id === id)
}

/**
 * 按日期查询学期 hook.
 *
 * @param date - 指定日期, 默认现在
 * @returns 查到的学期
 */
export const useSemesterByDate = (date?: MomentInput) => {
  const [semesters] = useSemesters()
  return findSemesterByDate(semesters, date)
}

const reload = [
  semesterApi$querySemesters.api,
  semesterApi$loadSemester.api,
  semesterApi$getCurrent.api,
  timetableApi$queryTimetables.api,
] as const

/**
 * 返回创建后会重新加载相关数据的 createSemester
 *
 * @param mutationOptions - @seiue/axios 支持的请求参数
 * @returns createSe mester function
 */
export const useCreateSemester = (mutationOptions?: MutationOptions) =>
  semesterApi$createSemester.useApi({
    reload,
    ...mutationOptions,
  })

/**
 * 返回修改后会重新加载相关数据的 updateSemester
 *
 * @param mutationOptions - @seiue/axios 支持的请求参数
 * @returns updateSemester function
 */
export const useUpdateSemester = (mutationOptions?: MutationOptions) =>
  scmsSemesterApi$updateSemesterAndTimetable.useApi({
    reload,
    ...mutationOptions,
  })

/**
 * 返回删除后会重新加载相关数据的 deleteSemester
 *
 * @param mutationOptions - @seiue/axios 支持的请求参数
 * @returns deleteSe mester function
 */
export const useDeleteSemester = (mutationOptions?: MutationOptions) =>
  semesterApi$deleteSemester.useApi({
    reload,
    ...mutationOptions,
  })

/**
 * 根据学期设置，返回届别与届别对应的年级
 *
 * @param semester - 学期
 * @returns 年级和届别
 */
export const useGraduateAndGradeWithSemester = (semester?: Semester) => {
  const { data: graduateTerms } = termApi$queryTerm.useApi({
    type: TermTypeEnum.SystemGraduatesIn,
  })

  const { data: structureTerms } = termApi$queryTerm.useApi({
    type: TermTypeEnum.SystemSchoolStructure,
  })

  return React.useMemo(() => {
    const grades = Object.entries(semester?.gradeMaps || {})
    const result = (graduateTerms || []).map(term => {
      // 找一下学期里是不是设置了这个届别
      const matchedGrade = grades.find(
        ([, graduateId]) => Number(graduateId) === term.id,
      )

      // 再到年级里找一下是否还存在这个年级设置
      const gradeTerm = structureTerms?.find(
        _term => _term.id === Number(matchedGrade?.[0]),
      )

      // 找一下年级对应的学部
      const facultyTerm = structureTerms?.find(
        _term => _term.id === Number(gradeTerm?.parentId),
      )

      return {
        grade: gradeTerm,
        faculty: facultyTerm,
        graduate: term,
      }
    })

    return result
  }, [semester, graduateTerms, structureTerms])
}

/**
 * 获取届别选项，若届别在当前学期有年级对应关系，则显示「届别+年级」，否则只显示届别
 *
 * @param options - 选项
 * @param options.excludeArchived - 是否排除已归档的届别
 * @returns 届别选项
 */
export const useGraduatesInOptions = (options?: {
  excludeArchived?: boolean
}) => {
  const { excludeArchived } = options ?? {}
  const semester = useCurrentSemester()
  const pairs = useGraduateAndGradeWithSemester(semester)
  return React.useMemo(() => {
    const filteredPairs = excludeArchived
      ? pairs.filter(pair => !pair.graduate.archivedAt)
      : pairs

    return filteredPairs.map(pair => ({
      text: `${pair.graduate.name}${
        pair.grade ? `(${$ct(pair.grade.name, 'grade')})` : ''
      }`,
      value: pair.graduate.id,
      disabled: !!pair.graduate.archivedAt,
    }))
  }, [excludeArchived, pairs])
}

/**
 * 获取学期类型选项
 *
 * @returns 学期类型选项
 */
export const useSemesterCategoryOptions = () => {
  const { data: semesterCategoryEnum, ...result } = useTermOptions({
    type: TermTypeEnum.SystemSemesterCategory,
  })

  return {
    data: semesterCategoryEnum?.map(option => {
      return {
        ...option,
        label: $ct(option.label, 'semester_category'),
      }
    }),
    ...result,
  }
}

/**
 * 获取学期类型选项 For Table
 *
 * @returns 学期类型选项
 */
export const useSemesterCategoryTableOptions = () => {
  const { data: semesterCategoryEnum, ...result } = useTermOptionsForTable(
    TermTypeEnum.SystemSemesterCategory,
  )

  return {
    data: semesterCategoryEnum?.map(option => {
      return {
        ...option,
        text: $ct(option.text, 'semester_category'),
      }
    }),
    ...result,
  }
}

const caculateCurrentWeek = (semester?: Semester, defaultDate?: string) => {
  if (!semester?.startAt) return undefined

  const dateMoment = defaultDate ? moment(defaultDate) : moment()
  if (isDateInSemester(dateMoment, semester)) {
    const week = dateToSemesterWeek(
      dateMoment.format(Formatter.DateFullTime),
      semester.startAt,
    )

    return week
  }

  return 1
}

const caculateDate = (semester: Semester, currentWeek: number) => {
  const todayMoment = moment()
  if (currentWeek === 0) {
    // 如果今天在选中学期内，默认今天，如果不在，默认选中学期的第一天
    return isDateInSemester(todayMoment, semester)
      ? todayMoment
      : moment(semester.startAt)
  }

  const { startAt, endAt } = semesterWeekToDates(currentWeek, semester.startAt)

  if (todayMoment.isSameOrBefore(endAt) && todayMoment.isSameOrAfter(startAt)) {
    return todayMoment
  }

  return moment(startAt)
}

/**
 * 根据选中学期，获取一个可控的日期状态
 *
 * @param param0 - 参数，如果不传入参数指定日期，那么会使用选中学期进行计算
 * @param param0.defaultWeek - 默认周，如果传入，则使用当前学期进行计算
 * @param param0.defaultDate - 默认日期，如果传入，则使用日期所对应的学期进行计算
 * @returns [date 默认今天, currentWeek 默认当前周, setCurrentWeek, setDate]
 */
export const useDateStatesBySelectedSemester = (param0?: {
  defaultWeek?: number
  defaultDate?: string
}) => {
  const { defaultWeek, defaultDate } = param0 ?? {}

  const [selectedSemester] = useSelectedSemester()
  const currentSemester = useCurrentSemester()
  const defaultDateSemester = useSemesterByDate(defaultDate)

  let semester = selectedSemester

  if (defaultWeek) {
    semester = currentSemester
  } else if (defaultDate) {
    semester = defaultDateSemester
  }

  const [currentWeek, setCurrentWeek] = React.useState(() => {
    if (defaultWeek) return defaultWeek

    return caculateCurrentWeek(semester, defaultDate) || 0
  })

  const [date, setDate] = useState<Moment>(() => {
    if (defaultDate) {
      return moment(defaultDate)
    }

    if (!semester?.startAt) return moment()

    return caculateDate(semester, currentWeek)
  })

  useOnUpdate(() => {
    if (!semester?.startAt) return

    setCurrentWeek(caculateCurrentWeek(semester) || 1)
    setDate(caculateDate(semester, currentWeek))
  }, [semester])

  return {
    date,
    currentWeek,
    setCurrentWeek,
    setDate,
  }
}

/**
 * 判断选中的学期是否是历史学期（即当前学期之前）
 *
 * @returns 是否为历史学期
 */
export const useIsHistorySelectedSemester = () => {
  const currentSemester = useCurrentSemester()
  const [selectedSemester] = useSelectedSemester()

  return moment(selectedSemester?.startAt).isBefore(currentSemester?.startAt)
}
