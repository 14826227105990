import { QuestionTypeEnum } from './question-type-enum'

export interface SimpleQuestionInterface {
  id: number
  /**
   * 大题
   */
  headline?: string
  /**
   * 小题
   */
  questionSubId?: string
  /**
   * 分数
   */
  score?: number
  /**
   * 题型
   */
  questionType: QuestionTypeEnum
  teacherIds?: number[]
  /**
   * 题目m选n,eg: 3:1
   */
  conditionQuestionType?: string | null
  /**
   * 选做题题组序号
   */
  questionGroupNo?: string
  /**
   * 题组
   */
  questionGroup?: string
  /**
   * 给分单位
   */
  subunit?: number
  /**
   * 评价标准
   */
  evaluationCriteria?: string | null
}

export class SimpleQuestion implements SimpleQuestionInterface {
  id: number
  /**
   * 大题
   */
  headline?: string
  /**
   * 小题
   */
  questionSubId?: string
  /**
   * 分数
   */
  score?: number
  /**
   * 题型
   */
  questionType: QuestionTypeEnum
  teacherIds?: number[]
  /**
   * 题目m选n,eg: 3:1
   */
  conditionQuestionType?: string | null
  /**
   * 选做题题组序号
   */
  questionGroupNo?: string
  /**
   * 题组
   */
  questionGroup?: string
  /**
   * 给分单位
   */
  subunit?: number
  /**
   * 评价标准
   */
  evaluationCriteria?: string | null

  constructor(props: SimpleQuestionInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      id: _id,
      headline: _headline,
      questionSubId: _questionSubId,
      score: _score,
      questionType: _questionType,
      teacherIds: _teacherIds,
      conditionQuestionType: _conditionQuestionType,
      questionGroupNo: _questionGroupNo,
      questionGroup: _questionGroup,
      subunit: _subunit,
      evaluationCriteria: _evaluationCriteria,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.id = _id
    this.headline = _headline
    this.questionSubId = _questionSubId
    this.score = _score
    this.questionType = _questionType
    this.teacherIds = _teacherIds
    this.conditionQuestionType = _conditionQuestionType
    this.questionGroupNo = _questionGroupNo
    this.questionGroup = _questionGroup
    this.subunit = _subunit
    this.evaluationCriteria = _evaluationCriteria
  }

  static propKeys = [
    'id',
    'headline',
    'questionSubId',
    'score',
    'questionType',
    'teacherIds',
    'conditionQuestionType',
    'questionGroupNo',
    'questionGroup',
    'subunit',
    'evaluationCriteria',
  ]
}
