import {
  compact,
  memoize,
  isObject,
  mapValues,
  isArray,
  isNumber,
  isBoolean,
  parseURLQuery,
  isNaN,
} from '@seiue/util'
import { matchPath } from 'react-router-dom'

import { RouteNode, MODAL_QUERY_NAMESPACE, RouteParams } from './types'

/**
 * breaks /a/b/c into /, /a, /a/b, /a/b/c
 *
 * @param path - path
 * @returns ancestors
 */
export const getPathAncestors = (path: string) => {
  const segments = compact(path.split('/'))
  const ancestors: string[] = ['/']
  segments.forEach((seg, idx) => {
    if (idx === 0) ancestors.push(`/${seg}`)
    else ancestors.push(`${ancestors[idx]}/${seg}`)
  })

  return ancestors
}

/**
 * fing match route
 */
export const findMatchRoute = memoize((path: string, routes: RouteNode[]) => {
  const matchRoute = (route: RouteNode): RouteNode | null => {
    const matched = matchPath(path, {
      path: route.path,
      exact: true,
      strict: false,
    })

    if (matched) return route

    if (route.subRoutes) {
      for (const sr of route.subRoutes) {
        // Don't return sr. Return the route returned from matchRoute(sr).
        const result = matchRoute(sr)
        if (result) return result
      }
    }

    return null
  }

  for (const route of routes) {
    const result = matchRoute(route)
    if (result) return result
  }

  return null
})

/**
 * clear modal query from query
 *
 * @param query - query
 * @returns next query without modalQuery
 */
export const clearModalQuery = (query: string | object) => {
  const nextQuery: { [key: string]: any; modal?: string } = isObject(query)
    ? { ...query }
    : parseURLQuery(query)

  delete nextQuery.modal
  delete nextQuery[MODAL_QUERY_NAMESPACE]

  return nextQuery
}

/**
 * 允许 route params 使用 string 外的各种类型, 在被 route 使用前转为 string
 *
 * @param params - route params
 * @returns new route params
 */
export const formatParams = (params: RouteParams) =>
  mapValues(params, val => {
    if (isArray(val)) return val.join(',')
    if (isNumber(val) || isBoolean(val)) return `${val}`

    return val
  })

/**
 * 检查一个变量是否是数字或数字字符串，只校验十进制
 *
 * @param n - 检查对象
 * @returns result
 */
const isNumeric = (n: number | string) => {
  if (typeof n === 'number') {
    return !isNaN(n)
  }

  if (typeof n === 'string') {
    const resN = n.trim()
    // 允许小数点、数字、可能的负号或正号（位于开头）、以及科学计数法中的 'e' 或 'E'
    const regex = /^[-+]?\d+(\.\d+)?$/
    return regex.test(resN)
  }

  return false
}

/**
 * 允许 route params 使用 string 外的各种类型, 在开发者使用前由 string 转为原类型
 *
 * @param params - route params
 * @returns parsed params
 */
export const parseParams = (params: { [key: string]: string }): RouteParams =>
  mapValues(params, val => {
    /**
     * 已知问题：传递的参数在浏览器刷新后会被做一次 encode，
     * 每次刷新页面都有一次浏览器的 encode ，还不确定问题原因
     */
    if (val === 'true') return true
    if (val === 'false') return false
    if (val === 'null') return null

    // 这里有一个隐患是，如果定义的是类似 idIn(形如'id1,id2,id3') 的参数
    // 但是数量只有一条时，idIn: '123'，会被转为 123，
    // 此时在调用处 123.split(',).map(Number) 就会报错
    if (isNumeric(val)) return +val

    // val 可能为 undefined
    if (val?.includes && val.includes(',')) {
      const array = val.split(',')
      if (array.every(isNumeric)) return array.map(Number)
      return array
    }

    return val
  })

/**
 * 对 routes 进行排序，当两个 route.path 之间是包含关系时，让更具体的 route 在先，更泛的在后
 *
 * @param _routes - 原 routes
 * @returns 排序后 routes
 */
export const sortRoutes = (_routes: any[]) => {
  const pattern = new RegExp(':[^/]+', 'g')
  _routes.sort((current, prev) => {
    const modifiedCurrentPath = current.path.replace(pattern, ':')
    const modifiedPrevPath = prev.path.replace(pattern, ':')

    if (modifiedCurrentPath.startsWith(modifiedPrevPath)) return -1
    if (modifiedPrevPath.startsWith(modifiedCurrentPath)) return 1

    // 毫无关系的 route 之间保持原序
    return 0
  })

  return _routes
}

/**
 * 路由过滤函数，接受自定义的路由过滤方法
 *
 * @param routes - 初始路由
 * @param filterMethod - filter 函数，接收的参数为当前 subroute
 * @returns 过滤后的路由
 */
export const filter = (
  routes: RouteNode[],
  filterMethod: (args: RouteNode) => RouteNode | undefined,
) => {
  const iterator = (_route?: RouteNode): RouteNode | undefined => {
    if (!_route) return undefined
    let filtered: any = {}
    // 先处理子路由
    if (_route.subRoutes) {
      filtered = {
        ..._route,
        subRoutes: compact(_route.subRoutes.map(iterator)),
      }
    } else {
      // 没有子路由返回过滤结果
      return filterMethod(_route)
    }

    // 此时处理子路由都已过滤完毕的父路由
    filtered = filterMethod(filtered)
    return filtered
  }

  return compact(routes.map(iterator))
}
