import { confirm, danger, ModalFuncProps, ConfirmArguments } from './methods'

/**
 * 生成一个根据 confirmModal 操作结果 resolve 布尔值的 promise
 *
 * @deprecated Use Modal.confirmAsync from @seiue/ui instead.
 * @param props - {@link ModalFuncProps}
 * @returns 布尔值或 ConfirmArguments
 */
export const promisifyConfirm = (
  props: Omit<ModalFuncProps, 'onOk'> & {
    onOk?: (args?: ConfirmArguments) => void
    isDanger?: boolean
  },
) =>
  new Promise<boolean | ConfirmArguments>(resolve => {
    const _args = {
      ...props,
      onOk: (args?: ConfirmArguments) => {
        if (props.onOk) props.onOk(args)
        resolve(args || true)
      },
      onCancel: () => {
        if (props.onCancel) props.onCancel()
        resolve(false)
      },
    }

    if (props.isDanger) {
      danger(_args)
    } else {
      confirm(_args)
    }
  })

/**
 * 注册全局选择器，返回一系列工具函数
 * 具体使用案例请查看 apps/chalk/src/components/Roles/Pickers/ReflectionPicker/method.tsx
 *
 * @param key - 唯一 key
 * @returns 工具函数
 */
export function registerGlobalPicker<T extends Function>(
  key: string,
): {
  getPicker: () => T | undefined
  getDestory: () => () => void | undefined
  setPicker: (pickerFn: T) => void
  setDestory: (destoryFn: () => void) => void
} {
  const innerWindow = window as unknown as any
  const globalKeyPrefix = `seiue_${key}`

  return {
    getPicker: () => innerWindow[`${globalKeyPrefix}_picker`],
    getDestory: () => innerWindow[`${globalKeyPrefix}_destory`],
    setPicker: pickerFn => {
      innerWindow[`${globalKeyPrefix}_picker`] = pickerFn
    },
    setDestory: destoryFn => {
      innerWindow[`${globalKeyPrefix}_destory`] = destoryFn
    },
  }
}
