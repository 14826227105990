/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Venue, VenueInterface } from './extends/venue'
import type { QueryAdminVenuesQuery } from './query-admin-venues-query'

const buildConfig = createConfigBuilder('scms')

export const venueApi$queryAdminVenues = {
  config(
    queryAdminVenuesQuery?: QueryAdminVenuesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/venue/admin-venues',
      {},
      queryAdminVenuesQuery,
    )
  },

  /**
   * @summary Class QueryAdminVenuesApi
   * 3.1 获取管理员场地列表
   * @param [queryAdminVenuesQuery]
   * @param [venueQuery.currentEmpty] 当前空闲true； 默认false
   * @param [venueQuery.approvalStatusIn] 查看指定订单状态的场地
   *  'initiated',  待审核
   * 'canceled',   已取消
   * 'approved' ,  审核通过
   * 'rejected'; 已拒绝
   * @param [venueQuery.expand] expand:
   * place 空间
   * admins 管理员
   * places  空间
   * scopes  开放对象
   * place_ids 空间Ids
   * @param [venueQuery.paginated] 是否分页，默认 1
   * @param [venueQuery.page] 显示的数据页，默认 1
   * @param [venueQuery.perPage] 每页显示数量，默认 20
   * @param [venueQuery.fields]
   * @param [venueQuery.id]
   * @param [venueQuery.idIn]
   * @param [venueQuery.name]
   * @param [venueQuery.nameLike]
   * @param [venueQuery.typeId]
   * @param [venueQuery.typeIdIn]
   * @param [venueQuery.capacity]
   * @param [venueQuery.capacityEgt]
   * @param [venueQuery.capacityElt]
   * @param [venueQuery.equipments]
   * @param [venueQuery.equipmentsLike]
   * @param [venueQuery.needApproval]
   * @param [venueQuery.openStatus]
   * @param [venueQuery.approvalingOrderEnable]
   * @param [venueQuery.placeIdIn]
   * @param [venueQuery.admins.account]
   * @param [venueQuery.admins.usin]
   * @param [venueQuery.admins.idcard]
   * @param [venueQuery.admins.outerId]
   * @param [venueQuery.admins.idIn]
   * @param [venueQuery.admins.nameIn]
   * @param [venueQuery.admins.nameLike]
   * @param [venueQuery.admins.enameIn]
   * @param [venueQuery.admins.enameLike]
   * @param [venueQuery.admins.identity] name, usin, ename, pinyin 的模糊搜索
   * @param [venueQuery.admins.accountLike]
   * @param [venueQuery.admins.accountIn]
   * @param [venueQuery.admins.usinLike]
   * @param [venueQuery.admins.usinIn]
   * @param [venueQuery.admins.genderIn]
   * @param [venueQuery.admins.statusIn]
   * @param [venueQuery.admins.archivedTypeIdIn]
   * @param [venueQuery.admins.phoneIn]
   * @param [venueQuery.admins.phoneLike]
   * @param [venueQuery.admins.emailIn]
   * @param [venueQuery.admins.emailLike]
   * @param [venueQuery.admins.idcardIn]
   * @param [venueQuery.admins.idcardLike]
   * @param [venueQuery.admins.enteredOnElt]
   * @param [venueQuery.admins.enteredOnEgt]
   * @param [venueQuery.admins.workStartOnElt]
   * @param [venueQuery.admins.workStartOnEgt]
   * @param [venueQuery.admins.enteredYearsElt]
   * @param [venueQuery.admins.enteredYearsEgt]
   * @param [venueQuery.admins.workYearsElt]
   * @param [venueQuery.admins.workYearsEgt]
   * @param [venueQuery.admins.adminClassesContains]
   * @param [venueQuery.admins.adminClassesOverlaps]
   * @param [venueQuery.admins.adminClassFullnamesOverlaps]
   * @param [venueQuery.admins.adminClassesLike]
   * @param [venueQuery.admins.dorm]
   * @param [venueQuery.admins.dormLike]
   * @param [venueQuery.admins.dormIn]
   * @param [venueQuery.admins.dormAdminsContains]
   * @param [venueQuery.admins.dormAdminsOverlaps]
   * @param [venueQuery.admins.dormAdminsLike]
   * @param [venueQuery.admins.role]
   * @param [venueQuery.admins.roleIn]
   * @param [venueQuery.adminIdsIn]
   * @param [options]
   * @return AxiosResponsePromise<Venue[]>
   */

  api<const TQuery extends QueryAdminVenuesQuery>(
    queryAdminVenuesQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Venue, TQuery['expand']>[]> {
    return axios(
      venueApi$queryAdminVenues.config(queryAdminVenuesQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Venue(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryAdminVenuesQuery,
    TSelected = Expand<Venue, TQuery['expand']>[],
  >(
    query?: TQuery,
    queryOptions?: QueryOptionsWithSelect<
      Expand<Venue, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Venue, TQuery['expand']>[], TSelected>(
      venueApi$queryAdminVenues.api,
      'Venue.queryAdminVenues',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
venueApi$queryAdminVenues.api._name_ = `Venue.queryAdminVenues`
