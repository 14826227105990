import { ExamScoringStatusEnum } from './exam-scoring-status-enum'
import {
  ExamScoringSettings,
  ExamScoringSettingsInterface,
} from './exam-scoring-settings'
import { ExamScoringPaperGenerationMethodEnum } from './exam-scoring-paper-generation-method-enum'
import {
  ExamScoringPaperGenerationStatuses,
  ExamScoringPaperGenerationStatusesInterface,
} from './exam-scoring-paper-generation-statuses'
import { Attachment, AttachmentInterface } from './attachment'
import { ExamStatusEnum } from './exam-status-enum'
import {
  ExamScoringMarkStudentBlocks,
  ExamScoringMarkStudentBlocksInterface,
} from './exam-scoring-mark-student-blocks'

export interface ExamScoringInterface {
  examId: number
  examSubjectId: number
  subjectName?: string
  fullScore?: number
  studentCounts?: number
  status?: ExamScoringStatusEnum | null
  /**
   * 阅卷进度
   */
  completionRate?: number | null
  /**
   * 格式化-考试时间
   */
  examTime?: string
  /**
   * 格式化-完成进度
   */
  completionRateStr?: string
  /**
   * 客观题答案是否已全部设置
   */
  answersSet?: boolean
  /**
   * 本阅卷是否有批阅数据
   *  - 客观题、选做题、主观题 只要有一个小题有批阅记录，则批阅数据
   */
  isReviewed?: boolean
  /**
   * 本阅卷关联的异常学生
   *  - 学生答卷 缺页 miss_pages
   *  - 学生答卷 无页 no_pages
   */
  abnormalStudentCount?: number
  settings?: ExamScoringSettingsInterface | null
  /**
   * 出卷方式
   */
  paperGenerationMethod?: ExamScoringPaperGenerationMethodEnum | null
  /**
   * 出卷状态信息
   */
  paperGenerationStatuses?: ExamScoringPaperGenerationStatusesInterface | null
  gradeGroupIds?: number[]
  /**
   * 本次考试试卷附件
   */
  testPaperAttachments?: AttachmentInterface[]
  /**
   * 本次阅卷是否已发布
   */
  isPublished?: boolean
  /**
   * 本次阅卷正常的考生ID集合
   */
  normalStudentIds?: number[]
  /**
   * 是否上报过问题卷
   */
  isReportProblemPage?: boolean
  examStatus?: ExamStatusEnum | null
  statusGeneralLabel?: string
  markedStudentBlocks?: ExamScoringMarkStudentBlocksInterface | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
}

export class ExamScoring implements ExamScoringInterface {
  examId: number
  examSubjectId: number
  subjectName?: string
  fullScore?: number
  studentCounts?: number
  status?: ExamScoringStatusEnum | null
  /**
   * 阅卷进度
   */
  completionRate?: number | null
  /**
   * 格式化-考试时间
   */
  examTime?: string
  /**
   * 格式化-完成进度
   */
  completionRateStr?: string
  /**
   * 客观题答案是否已全部设置
   */
  answersSet?: boolean
  /**
   * 本阅卷是否有批阅数据
   *  - 客观题、选做题、主观题 只要有一个小题有批阅记录，则批阅数据
   */
  isReviewed?: boolean
  /**
   * 本阅卷关联的异常学生
   *  - 学生答卷 缺页 miss_pages
   *  - 学生答卷 无页 no_pages
   */
  abnormalStudentCount?: number
  settings?: ExamScoringSettings | null
  /**
   * 出卷方式
   */
  paperGenerationMethod?: ExamScoringPaperGenerationMethodEnum | null
  /**
   * 出卷状态信息
   */
  paperGenerationStatuses?: ExamScoringPaperGenerationStatuses | null
  gradeGroupIds?: number[]
  /**
   * 本次考试试卷附件
   */
  testPaperAttachments?: Attachment[]
  /**
   * 本次阅卷是否已发布
   */
  isPublished?: boolean
  /**
   * 本次阅卷正常的考生ID集合
   */
  normalStudentIds?: number[]
  /**
   * 是否上报过问题卷
   */
  isReportProblemPage?: boolean
  examStatus?: ExamStatusEnum | null
  statusGeneralLabel?: string
  markedStudentBlocks?: ExamScoringMarkStudentBlocks | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number

  constructor(props: ExamScoringInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      examId: _examId,
      examSubjectId: _examSubjectId,
      subjectName: _subjectName,
      fullScore: _fullScore,
      studentCounts: _studentCounts,
      status: _status,
      completionRate: _completionRate,
      examTime: _examTime,
      completionRateStr: _completionRateStr,
      answersSet: _answersSet,
      isReviewed: _isReviewed,
      abnormalStudentCount: _abnormalStudentCount,
      settings: _settings,
      paperGenerationMethod: _paperGenerationMethod,
      paperGenerationStatuses: _paperGenerationStatuses,
      gradeGroupIds: _gradeGroupIds,
      testPaperAttachments: _testPaperAttachments,
      isPublished: _isPublished,
      normalStudentIds: _normalStudentIds,
      isReportProblemPage: _isReportProblemPage,
      examStatus: _examStatus,
      statusGeneralLabel: _statusGeneralLabel,
      markedStudentBlocks: _markedStudentBlocks,
      id: _id,
      schoolId: _schoolId,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.examId = _examId
    this.examSubjectId = _examSubjectId
    this.subjectName = _subjectName
    this.fullScore = _fullScore
    this.studentCounts = _studentCounts
    this.status = _status
    this.completionRate = _completionRate
    this.examTime = _examTime
    this.completionRateStr = _completionRateStr
    this.answersSet = _answersSet
    this.isReviewed = _isReviewed
    this.abnormalStudentCount = _abnormalStudentCount
    this.settings = _settings ? new ExamScoringSettings(_settings) : _settings
    this.paperGenerationMethod = _paperGenerationMethod
    this.paperGenerationStatuses = _paperGenerationStatuses
      ? new ExamScoringPaperGenerationStatuses(_paperGenerationStatuses)
      : _paperGenerationStatuses
    this.gradeGroupIds = _gradeGroupIds
    this.testPaperAttachments = _testPaperAttachments
      ? _testPaperAttachments.map(i => new Attachment(i))
      : _testPaperAttachments
    this.isPublished = _isPublished
    this.normalStudentIds = _normalStudentIds
    this.isReportProblemPage = _isReportProblemPage
    this.examStatus = _examStatus
    this.statusGeneralLabel = _statusGeneralLabel
    this.markedStudentBlocks = _markedStudentBlocks
      ? new ExamScoringMarkStudentBlocks(_markedStudentBlocks)
      : _markedStudentBlocks
    this.id = _id
    this.schoolId = _schoolId
  }

  static propKeys = [
    'examId',
    'examSubjectId',
    'subjectName',
    'fullScore',
    'studentCounts',
    'status',
    'completionRate',
    'examTime',
    'completionRateStr',
    'answersSet',
    'isReviewed',
    'abnormalStudentCount',
    'settings',
    'paperGenerationMethod',
    'paperGenerationStatuses',
    'gradeGroupIds',
    'testPaperAttachments',
    'isPublished',
    'normalStudentIds',
    'isReportProblemPage',
    'examStatus',
    'statusGeneralLabel',
    'markedStudentBlocks',
    'id',
    'schoolId',
  ]
}
