import { floor } from '@seiue/util'

import { toNumberScore } from 'packages/feature-utils/grades/utils/score'
import { Item, Score, ScoringTypeEnum, ScoreReq } from 'packages/sdks-next/vnas'

import { TreeNode } from './item-tree-node'

export { toNumberScore } from 'packages/feature-utils/grades/utils/score'

/**
 * 比较两个分数是否相等
 *
 * @param firstScore - 第一个分数
 * @param lastScore - 第二个分数
 * @returns 是否相等
 */
export const isScoreEqual = (firstScore = '', lastScore = ''): boolean =>
  Number(firstScore) === Number(lastScore)

/**
 * 将分数转换为字符串
 *
 * @param param0 - 参数
 * @param param0.value - 分数
 * @param param0.precision - 精度
 * @param param0.defaultValue - 默认值
 * @returns 字符串
 */
export const toStringScore = ({
  value,
  precision = 0,
  defaultValue,
}: {
  value: any
  precision?: number
  defaultValue?: any
}) => `${toNumberScore({ value, precision, defaultValue })}`

/**
 * 计算评价项得分率（评价项占分才有效）
 *
 * @param param0 - 参数
 * @param param0.item - 评价项
 * @param param0.score - 分数
 * @returns 得分率
 */
export const computeItemScoreRate = ({
  item,
  score,
}: {
  item: Item
  score?: Score
}) => {
  if (!score?.score) return 0

  const divisor = score.score
  const dividend = item.within ? item.fullScore : 0

  return floor(
    (toNumberScore({ value: divisor }) / toNumberScore({ value: dividend })) *
      100,
    2,
  )
}

/**
 * 计算维度得分率
 *
 * @param param0 - 参数
 * @param param0.score - 分数
 * @param param0.scores - 分数列表
 * @param param0.nodes - 节点列表
 * @returns 得分率
 */
export const computeDimensionScoreRate = ({
  score,
  scores,
  nodes,
}: {
  score?: Score
  scores?: Score[]
  nodes: TreeNode[]
}) => {
  if (!score?.score) return 0

  const withinScore = (function reduceItems(nodeList): number {
    return nodeList.reduce(
      (prevScore, node) =>
        prevScore +
        (node.children
          ? reduceItems(node.children)
          : (scores?.find(
              _score => !!_score.score && _score.itemId === node.item.id,
            ) &&
              node.score) ||
            0),
      0,
    )
  })(nodes)

  return floor((toNumberScore({ value: score.score }) / withinScore) * 100, 2)
}

/**
 * 判断一个分数是不是空的
 *
 * @param scoringType - 评分类型
 * @param param1 - 参数
 * @param param1.score - 分数
 * @param param1.valid - 有效
 * @param param1.review - 审核
 * @param param1.tag - 标签
 * @returns 是否为空
 */
export const isEmptyScore = (
  scoringType: ScoringTypeEnum,
  {
    score,
    valid,
    review,
    tag,
  }: Pick<ScoreReq, 'score' | 'valid' | 'review' | 'tag' | 'invalidReason'>,
) => {
  if (!valid) return false

  switch (scoringType) {
    case ScoringTypeEnum.Score:
    case ScoringTypeEnum.Star:
      return !score
    case ScoringTypeEnum.Level:
      return !tag
    case ScoringTypeEnum.Review:
      return !review
    default:
      return false
  }
}
