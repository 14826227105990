/**
 * @file 审批流 view utils
 */
import {
  faArrowAltLeft,
  faBan,
  faCheck,
  faEllipsisH,
  faShare,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons'
import { compact, createEnumHelper } from '@seiue/util'

import {
  getCascadeSelectorFieldValue,
  getOptionFieldSafeValue,
} from 'packages/components/LegoForm/Builder/utils/views'
import { isLegoFormBuiltInField } from 'packages/components/LegoForm/utils/base'
import { useI18nName } from 'packages/feature-utils/reflections'
import { useCurrentReflection } from 'packages/features/sessions'
import {
  FlowFieldValue,
  FlowStatusEnum,
  FlowWithWorkflow,
} from 'packages/features/workflows/types'
import { $t } from 'packages/locale'
import { Attachment, PluginScopeParam } from 'packages/sdks-next/chalk'
import {
  FallbackModeEnum,
  Field,
  FieldTypeEnum,
  FlowNode,
  FlowNodeStatusEnum,
  FlowStage,
  NodeManagerTypeEnum,
  NodeTypeEnum,
  PluginScopeParamInterface,
  SchoolPluginScopesInterface,
} from 'packages/sdks-next/form'

export const flowNodeStatusToIcon = createEnumHelper(() => ({
  [FlowNodeStatusEnum.Approved]: faCheck,
  [FlowNodeStatusEnum.Pending]: faEllipsisH,
  [FlowNodeStatusEnum.Denied]: faTimes,
  [FlowNodeStatusEnum.Returned]: faArrowAltLeft,
  [FlowNodeStatusEnum.Withdrawn]: faArrowAltLeft,
  [FlowNodeStatusEnum.Forwarded]: faShare,
  [FlowNodeStatusEnum.Invalid]: faBan,

  // waiting stopped 是特殊状态，前端会对其特殊判断
  [FlowNodeStatusEnum.Waiting]: faBan,
  [FlowNodeStatusEnum.Stopped]: faBan,
}))

export const flowNodeStatusToLabel = createEnumHelper(() => ({
  [FlowNodeStatusEnum.Approved]: $t('已通过'),
  [FlowNodeStatusEnum.Pending]: $t('审批中'),
  [FlowNodeStatusEnum.Denied]: $t('已拒绝'),
  [FlowNodeStatusEnum.Returned]: $t('退回给'),
  [FlowNodeStatusEnum.Withdrawn]: $t('已撤回'),
  [FlowNodeStatusEnum.Forwarded]: $t('转交给'),
  [FlowNodeStatusEnum.Invalid]: $t('失效'),

  // waiting stopped 是特殊状态，前端会对其特殊判断
  [FlowNodeStatusEnum.Waiting]: '',
  [FlowNodeStatusEnum.Stopped]: '',
}))

/**
 * 获取审批节点的状态文本
 *
 * @param node - 审批节点
 * @returns 状态文本
 */
export const getFlowNodeStatusLabel = (node: FlowNode) => {
  if (node.nodeType === NodeTypeEnum.Approval) {
    if (node.status === FlowNodeStatusEnum.Pending) return $t('审批中')
    if (node.status === FlowNodeStatusEnum.Approved) return $t('已通过')
    if (node.status === FlowNodeStatusEnum.Denied) return $t('已驳回')
    if (node.status === FlowNodeStatusEnum.Withdrawn) return $t('撤销通过')
    if (node.status === FlowNodeStatusEnum.Invalid) return $t('已通过（失效）')
  }

  if (node.nodeType === NodeTypeEnum.Handle) {
    if (node.status === FlowNodeStatusEnum.Pending) return $t('办理中')
    if (node.status === FlowNodeStatusEnum.Approved) return $t('已办理')
    if (node.status === FlowNodeStatusEnum.Denied) return $t('已拒绝')
    if (node.status === FlowNodeStatusEnum.Withdrawn) return $t('撤销办理')
    if (node.status === FlowNodeStatusEnum.Invalid) return $t('已办理（失效）')
  }

  return flowNodeStatusToLabel.get(node.status)
}

export const flowStatusToTagStyle = createEnumHelper(() => ({
  [FlowStatusEnum.Pending]: 'warning' as const,
  [FlowStatusEnum.Withdrawn]: 'default' as const,
  [FlowStatusEnum.Rejected]: 'danger' as const,
  [FlowStatusEnum.Refused]: 'danger' as const,
  [FlowStatusEnum.Approved]: 'success' as const,
  [FlowStatusEnum.Verifying]: 'warning' as const,
  [FlowStatusEnum.Submitted]: 'warning' as const,
  [FlowStatusEnum.Verified]: 'success' as const,
}))

export const flowStatusToLabel = createEnumHelper(() => ({
  [FlowStatusEnum.Pending]: $t('审批中'),
  [FlowStatusEnum.Withdrawn]: $t('已撤回'),
  [FlowStatusEnum.Rejected]: $t('已驳回'),
  [FlowStatusEnum.Refused]: $t('已拒绝'),
  [FlowStatusEnum.Approved]: $t('已通过'),
  [FlowStatusEnum.Verifying]: $t('待销假'),
  [FlowStatusEnum.Submitted]: $t('销假待审核'),
  [FlowStatusEnum.Verified]: $t('已销假'),
}))

/**
 * 格式化使用范围
 *
 * @param scopes - 插件的使用范围
 * @returns 使用范围的文本形式
 */
export const formatScopes = (scopes?: SchoolPluginScopesInterface) => {
  if (!scopes) return '-'

  const formatScope = (
    scope: PluginScopeParamInterface,
    ext: string,
  ): string => {
    if (PluginScopeParam.isAllScope(scope)) {
      return $t('全部') + ext
    }

    if (scope.relateStudent) {
      return $t('包含已选学生的{ext}', { ext })
    }

    if (scope.idIn === '') {
      return ''
    }

    return $t('部分') + ext
  }

  const txt = [
    formatScope(scopes.student, $t('学生')),
    formatScope(scopes.guardian, $t('家长')),
    formatScope(scopes.teacher, $t('教师')),
    formatScope(scopes.staff, $t('校外人员')),
  ].filter(t => !!t)

  return txt.length ? txt.join(', ') : '-'
}

/**
 * 将分钟格式化为可读的文本
 *
 * @param minutes - 分钟
 * @returns x 天 y 小时 z 分钟
 */
export const formatMinutes = (minutes?: number | null) => {
  if (!minutes) return '-'

  const days = Math.floor(minutes / (24 * 60))
  const hours = Math.floor((minutes - days * 24 * 60) / 60)
  const mins = Math.floor(minutes - days * 24 * 60 - hours * 60)

  const daysString = days ? $t('{num} 天', { num: days }) : null
  const hoursString = hours ? $t('{num} 小时', { num: hours }) : null
  const minsString = mins ? $t('{num} 分钟', { num: mins }) : null

  return compact([daysString, hoursString, minsString]).join(' ')
}

/**
 * 格式化无人审批时/无人办理/无人抄送时的文案
 *
 * @param nodeType - 节点类型
 * @param mode - 未找到审批人时的模式
 * @returns 格式化后的文案
 */
export const formatFallbackMode = (
  nodeType: NodeTypeEnum,
  mode?: FallbackModeEnum,
) => {
  if (mode === FallbackModeEnum.ToAdmin) {
    switch (nodeType) {
      case NodeTypeEnum.Approval:
        return $t('管理员审批')
      case NodeTypeEnum.Handle:
        return $t('管理员办理')
      case NodeTypeEnum.Cc:
        return $t('抄送管理员')
      default:
        return ''
    }
  }

  if (mode === FallbackModeEnum.AutoReject) {
    switch (nodeType) {
      case NodeTypeEnum.Approval:
        return $t('自动驳回')
      default:
        return ''
    }
  }

  if (mode === FallbackModeEnum.AutoRefuse) {
    switch (nodeType) {
      case NodeTypeEnum.Handle:
        return $t('自动拒绝')
      default:
        return ''
    }
  }

  switch (nodeType) {
    case NodeTypeEnum.Approval:
      return $t('自动通过')
    case NodeTypeEnum.Handle:
      return $t('自动跳过')
    case NodeTypeEnum.Cc:
      return $t('自动忽略')
    default:
      return ''
  }
}

export const approverToLabel = createEnumHelper(() => ({
  [NodeManagerTypeEnum.Guardians]: $t('家长'),
  [NodeManagerTypeEnum.GroupAdmin]: $t('群组管理员'),
  [NodeManagerTypeEnum.Role]: $t('角色'),
  [NodeManagerTypeEnum.Custom]: $t('发起人自选'),
  [NodeManagerTypeEnum.SpecificReflections]: $t('指定人员'),
  [NodeManagerTypeEnum.CustomByField]: $t('表单内人员'),
  [NodeManagerTypeEnum.Department]: $t('部门'),
  [NodeManagerTypeEnum.DepartmentDirectors]: $t('部门负责人'),
  [NodeManagerTypeEnum.PluginAdmin]: $t('插件管理员'),
  [NodeManagerTypeEnum.AdjustmentRelatedTeachers]: $t('相关教师'),
  [NodeManagerTypeEnum.ParentPluginAdmin]: $t('父插件管理员'),
  [NodeManagerTypeEnum.ChatTeachers]: $t('相关教师'),
  [NodeManagerTypeEnum.EventVenueAdmin]: $t('场地管理员'),
  [NodeManagerTypeEnum.EventAdmin]: $t('活动管理员'),
  [NodeManagerTypeEnum.Initiator]: $t('发起人本人'),
}))

export const flowNodeTypeToLabel = createEnumHelper(() => ({
  [NodeTypeEnum.Conditional]: $t('条件'),
  [NodeTypeEnum.Entry]: $t('发起人'),
  [NodeTypeEnum.Approval]: $t('审批人'),
  [NodeTypeEnum.Cc]: $t('抄送人'),
  [NodeTypeEnum.Handle]: $t('办理人'),
  [NodeTypeEnum.Exit]: '',
}))

export const flowNodeTypeToActionLabel = createEnumHelper(() => ({
  [NodeTypeEnum.Conditional]: '',
  [NodeTypeEnum.Entry]: $t('发起'),
  [NodeTypeEnum.Approval]: $t('审批'),
  [NodeTypeEnum.Cc]: $t('抄送'),
  [NodeTypeEnum.Handle]: $t('办理'),
  [NodeTypeEnum.Exit]: '',
}))

/**
 * 获取纯文本格式的表单字段值
 *
 * @param args - args
 * @param args.field - 字段
 * @param args.fieldValues - 表单值
 * @param args.formatBizFieldToTextValue - 将业务字段值格式为纯文本格式
 * @returns 纯文本的字段值
 */
export const getFlowFieldTextValue = ({
  field,
  fieldValues,
  formatBizFieldToTextValue,
}: {
  field: Field
  fieldValues: FlowFieldValue[]
  formatBizFieldToTextValue?: (
    flowField: FlowFieldValue,
    field: Field,
    fieldValues: FlowFieldValue[],
  ) => string
}): string => {
  const fieldValue = fieldValues.find(fv => fv.fieldName === field.name)

  // 业务/自定义字段
  if (!isLegoFormBuiltInField(field.name)) {
    return fieldValue
      ? formatBizFieldToTextValue?.(fieldValue, field, fieldValues) || ''
      : ''
  }

  switch (field.type) {
    case FieldTypeEnum.Klass:
      return fieldValue?.className || ''
    case FieldTypeEnum.Input:
    case FieldTypeEnum.Textarea:
    case FieldTypeEnum.Money:
    case FieldTypeEnum.Number:
    case FieldTypeEnum.DateTime:
      return fieldValue?.value || ''
    case FieldTypeEnum.CascadeSelector:
      return getCascadeSelectorFieldValue({
        field,
        fieldValue,
      })
    case FieldTypeEnum.Checkbox: {
      return (
        getOptionFieldSafeValue({
          field,
          fieldValue,
          connector: '、',
        }) || ''
      )
    }

    case FieldTypeEnum.Radio:
    case FieldTypeEnum.Select:
      return (
        getOptionFieldSafeValue({
          field,
          fieldValue,
        }) || ''
      )

    case FieldTypeEnum.Attachment: {
      const attachments: Attachment[] = fieldValue?.value
      return Array.isArray(attachments)
        ? attachments.map(attachment => attachment.name).join('、')
        : ''
    }

    case FieldTypeEnum.DateRange: {
      const range = fieldValue?.value
      return !range?.start || !range?.end
        ? ''
        : $t('{start} 至 {end}', {
            start: range.start,
            end: range.end,
          })
    }

    case FieldTypeEnum.Address:
      return compact([
        fieldValue?.value?.province,
        fieldValue?.value?.city,
        fieldValue?.value?.district,
        fieldValue?.value?.address,
      ]).join('/')

    default:
      return ''
  }
}

/**
 * 返回一个获取审批单的标题的函数
 *
 * @param args - args
 * @param args.workflowName - 审批单名称
 * @returns 获取审批单标题的函数
 */
export const useGetFlowTitle = ({
  workflowName,
}: { workflowName?: string } = {}) => {
  const currentReflection = useCurrentReflection()

  const $name = useI18nName()

  return (flow: FlowWithWorkflow) => {
    return flow.initiatorId === currentReflection.id
      ? $t('我发起的{name}', {
          name: workflowName || flow.workflow?.schoolPlugin?.label,
        })
      : $t('{initiator}发起的{name}', {
          initiator: $name(flow.initiator),
          name: workflowName || flow.workflow?.schoolPlugin?.label,
        })
  }
}

/**
 * 是否需要签字
 *
 * @param param - 参数
 * @param param.node - 节点
 * @param param.stage - 节点所在的阶段
 * @returns 是否需要签字
 */
export const isNeedSigned = ({
  node,
  stage,
}: {
  stage: FlowStage
  node: FlowNode
}) => {
  return (
    !!node.signNeeded &&
    [NodeTypeEnum.Handle, NodeTypeEnum.Approval].includes(stage.nodeType)
  )
}
