/**
 * @file 成绩结构树的相关 utils
 */
import { faLock } from '@fortawesome/pro-solid-svg-icons'
import {
  Dictionary,
  cloneDeep,
  compact,
  flatten,
  floor,
  omit,
  round,
  sumBy,
  uniqBy,
} from '@seiue/util'
import { useMemo } from 'react'

import { SeIconProp } from 'packages/components/Icon'
import {
  Tree,
  TreeNode,
  convertItemsToTree,
} from 'packages/feature-utils/grades/utils/tree'
import { Query } from 'packages/hooks'
import { $t } from 'packages/locale'
import { Task } from 'packages/sdks-next/chalk'
import {
  Assessment,
  AssessmentStageStatusEnum,
  AssessmentTypeEnum,
  ComputeTypeEnum,
  Item,
  ItemInterface,
  ItemReq,
  ItemStatusEnum,
  ItemTypeEnum,
  ScoringTypeEnum,
  RelationsEnum,
  SettingScoreTypeEnum,
  AchievedScore,
} from 'packages/sdks-next/vnas'

import { isAvgDimension, isItemWithinScore, isNeedPublished } from './item'
import { isLoosePublished } from './score-actions'
import { toNumberScore } from './scores'

export { convertItemsToTree } from 'packages/feature-utils/grades/utils/tree'
export type {
  Tree,
  TreeNode,
  TreeNodeMap,
} from 'packages/feature-utils/grades/utils/tree'

/**
 * 是不是根节点
 *
 * @param tree - 树的信息
 * @returns 是不是根节点
 */
export const isTree = (tree: Tree | TreeNode): tree is Tree =>
  !!(tree as Tree).root

/**
 * 是不是子节点
 *
 * @param treeNode - 节点
 * @returns 是不是子节点
 */
export const isTreeNode = (treeNode: Tree | TreeNode): treeNode is TreeNode =>
  !!(treeNode as TreeNode).item

/**
 * 是不是在平均维度下的子节点
 *
 * @param treeNode - 节点
 * @returns 是不是在平均维度下的子节点
 */
export const isTreeNodeInsideAvgNode = (treeNode: Tree | TreeNode) =>
  isTreeNode(treeNode) &&
  isTreeNode(treeNode.parent) &&
  isAvgDimension(treeNode.parent.item)

/**
 * 判断一个节点的父节点是否为加权平均
 *
 * @param treeNode - 指定节点
 * @returns 是否
 */
export const isWeightedAvgParentNode = (treeNode: TreeNode) => {
  return (
    isTreeNode(treeNode.parent) &&
    treeNode.parent.item.computeType === ComputeTypeEnum.WeightedAvg
  )
}

/**
 * 是否显示指定节点的占分
 *
 * @param treeNode - 指定节点
 * @returns 是否
 */
export const shouldShowItemNodeWithinScore = (treeNode: TreeNode) => {
  return (
    !isTreeNodeInsideAvgNode(treeNode) && !isWeightedAvgParentNode(treeNode)
  )
}

/**
 * 获取节点深度
 *
 * @param treeNode - 节点
 * @returns number
 */
export const getNodeDeep = (treeNode: TreeNode) => {
  let { parent } = treeNode
  let deep = 1

  while (!isTree(parent)) {
    parent = parent.parent
    deep += 1
  }

  return deep
}

/**
 * 递归检查评价节点下是否有评价项
 *
 * @param itemNode - 节点
 * @returns 是否有评价项
 */
export const checkNodeHasItems = (itemNode: TreeNode): boolean => {
  if (itemNode.item.type === ItemTypeEnum.Item && !!itemNode.item.scoringType)
    return true
  if (!itemNode.children?.length) return false
  return itemNode.children.some(child => checkNodeHasItems(child))
}

/**
 * 根据某个节点获取其根节点
 *
 * @param node - 节点信息
 * @returns 根节点
 */
export const getRootNode = (node: TreeNode | Tree): Tree => {
  if (isTree(node)) return node
  return getRootNode(node.parent)
}

/**
 * 两个节点是否可以合并
 *
 * @param nodeA - 节点 A
 * @param nodeB - 节点 B
 * @returns 是否可以合并
 */
const isTwoNodesMergeable = (nodeA: TreeNode, nodeB: TreeNode) => {
  const { item: itemA } = nodeA
  const { item: itemB } = nodeB

  if (itemA.type !== itemB.type) return false

  if (
    [
      ItemTypeEnum.Item,
      ItemTypeEnum.SpecialItem,
      ItemTypeEnum.AttendanceItem,
    ].find(type => {
      return type === itemA.type
    })
  )
    return false

  if (itemA.name !== itemB.name) return false
  if (itemA.computeType !== itemB.computeType) return false
  if (itemA.withinScore !== itemB.withinScore) return false
  if (itemA.within !== itemB.within) return false
  if (getNodeDeep(nodeA) !== getNodeDeep(nodeB)) return false

  return true
}

/**
 * 以一个节点为目标，生成下一个节点的名称
 *
 * @param targetNode - 目标节点
 * @returns 下一个节点的名称
 */
export const generateNextNodeName = (targetNode: TreeNode) => {
  const indexRegExp = /[（(]([1-9]+)[)）]$/

  const baseName = targetNode.item.name.replace(indexRegExp, '')

  const nameIndexMatchResult = compact(
    [targetNode, ...targetNode.siblings]
      .filter(_node => _node.item.name.startsWith(baseName))
      .map(_node => {
        return _node.item.name.match(/[（(]([1-9]+)[)）]$/)?.[1]
      }),
  )
    .sort()
    ?.pop()

  let nextNodeName = ''

  if (nameIndexMatchResult) {
    nextNodeName = `${baseName}（${Number(nameIndexMatchResult) + 1}）`
  } else {
    nextNodeName = `${baseName}（1）`
  }

  return nextNodeName
}

/**
 * 合并两棵树，targetTree 合并进 originTree
 *
 * @param _originTree - 原树
 * @param _targetTree - 目标树
 * @returns 合并后的树
 */
export const mergeTree = (_originTree: Tree, _targetTree: Tree) => {
  const originTree = cloneDeep(_originTree)
  const targetTree = cloneDeep(_targetTree)

  const mergeNodeIds: number[] = []

  /* eslint-disable no-param-reassign */
  const mergeNode = (_origin: Tree | TreeNode, target: Tree | TreeNode) => {
    const originChildren = _origin.children || []

    target.children?.forEach(node => {
      const targetMergeableNode = _origin.children?.find(targetNode =>
        isTwoNodesMergeable(targetNode, node),
      )

      if (targetMergeableNode) {
        // 处理合并
        mergeNode(targetMergeableNode, node)
        mergeNodeIds.push(node.id)
      } else {
        node.siblings = uniqBy([...originChildren, ...node.siblings], 'id')

        if (node.siblings.find(_node => _node.item.name === node.item.name)) {
          node.item.name = generateNextNodeName(node)
        }

        node.parent = _origin

        node.index = originChildren.length

        originChildren.push(node)
      }
    })

    _origin.children = originChildren

    return _origin
  }
  /* eslint-enable no-param-reassign */

  return {
    tree: mergeNode(originTree, targetTree) as Tree,
    mergeNodeIds,
  }
}

/**
 * 为批量创建填充 path
 * 因为批量创建时，各个 item 是无 id 的，这也意味着无法通过 pid 来标注父子关系，所以后端支持了一个 path 参数
 *
 * 如果传递了 originAssessment，那么会在原有的结构上，进行合并或者复制
 * 合并：将相同名称/计算方式/占分的维度/子维度合并在一起
 * 复制：复制无法合并的维度/子维度和评价项，命名为“维度/子维度/评价项名称-序号”
 *
 * @param assessment - 评价数据
 * @param items - 评价 items
 * @param originAssessment - 原本的评价项，如果传入，那么会在原有的结构上，进行合并或者复制
 * @returns 填充 path 后的数据
 */
export const fulfillBatchCreatePath = (
  assessment: Assessment,
  items: Item[],
  originAssessment?: Assessment,
) => {
  let [tree] = convertItemsToTree(assessment, items)

  if (originAssessment && originAssessment.items) {
    const [originTree] = convertItemsToTree(
      originAssessment,
      originAssessment.items,
    )

    const nextOriginTree = cloneDeep(originTree)

    ;({ tree } = mergeTree(nextOriginTree, tree))
  }

  const pathMap: { [key: number]: string } = {}
  const nextItems: ItemReq[] = []

  const fulfillPath = (node: TreeNode, index: number) => {
    const isNewItem = items.some(item => item.id === node.id)

    const nextItem: ItemReq = {
      ...(omit(node.item, ['pid', 'id']) as any),
      pid: undefined,
      pathname: undefined,
      path: '',
      sort: index,
    }

    if (isTree(node.parent)) {
      nextItem.path = `0-${nextItem.sort}`
    } else {
      nextItem.path = `${pathMap[node.parent.id]}-${nextItem.sort}`
    }

    // 仅记录新增的 item
    if (isNewItem) {
      nextItems.push(nextItem)
    }

    pathMap[node.id] = nextItem.path

    node.children?.forEach((subNode, _index) => {
      fulfillPath(subNode, _index)
    })
  }

  tree.children.forEach((node, index) => {
    fulfillPath(node, index)
  })

  return nextItems
}

/**
 * 复制评价项前的必要处理
 *
 * @param assessment - 评价数据
 * @param items - 评价 items
 * @param originAssessment - 原本的评价项，如果传入，那么会在原有的结构上，进行合并或者复制
 * @returns 处理后的数据
 */
export const executeItemsBeforeCopy = (
  assessment: Assessment,
  items: Item[],
  originAssessment?: Assessment,
) =>
  fulfillBatchCreatePath(assessment, items, originAssessment).map(
    ({ taskRelations, ...item }) => {
      const relations = (taskRelations || []).map(({ taskId }) => ({
        copiedTaskId: taskId,
      }))

      return {
        ...item,
        taskRelations: relations,
      }
    },
  )

/**
 * 获取节点的路径
 *
 * @param node - 节点
 * @returns 节点路径
 */
export const getNodePath = (node: TreeNode) => {
  // 路径的第一个元素是根节点的 index，所以先填充 0
  const path: number[] = [0]

  const innerGetNodePath = (innerNode: TreeNode) => {
    if (innerNode.parent && !isTree(innerNode.parent)) {
      innerGetNodePath(innerNode.parent)
    }

    path.push(innerNode.index)

    return path
  }

  innerGetNodePath(node)

  return path
}

/**
 * 检查目标评价项是否可以添加
 *
 * @param assessment - 评价
 * @param item - 评价项
 * @param task - 任务
 * @returns 是否可以添加任务
 */
export const checkAssesmentItemDisabled = (
  assessment: Assessment,
  item: Item,
  task?: Task,
) => {
  if (!item.scoringType) return $t('未能识别的评价项类型')

  // 如果关联了学案，则不能关联其他项
  if (item.handoutOutlineRelations?.length) return $t('该评价项已关联学案')

  if (item.type === ItemTypeEnum.AttendanceItem) return $t('无法关联考勤评价项')

  if (item.lockSetting) {
    return $t('该评价项已被设置锁定，无法关联，请选择其他评价项或联系教务。')
  }

  if (item.reviewNeeded) {
    return $t('该评价项的发布需管理员审核，不能关联')
  }

  if (isNeedPublished(item.scoringType) && item.taskRelations) {
    if (task?.customFields?.isAllJoined && item.taskRelations.length) {
      return $t('该评价项已有任务，不能直接添加包含全部学生的任务')
    }

    if (assessment.klass && assessment.klass.studentNums) {
      const studentCount = sumBy(
        item.taskRelations,
        relation => (relation.task as Task).assignments?.length || 0,
      )

      if (studentCount >= assessment.klass.studentNums)
        return $t('该评价项的所有学生都已指定任务')
    } else if (
      item.taskRelations.find(
        relation => (relation.task as Task)?.customFields?.isAllJoined,
      )
    ) {
      return $t('该评价项的所有学生都已指定任务')
    }
  }

  if (
    (isNeedPublished(item.scoringType) &&
      (item.status === ItemStatusEnum.Submitted ||
        item.status === ItemStatusEnum.Passed ||
        item.status === ItemStatusEnum.Published)) ||
    (item.relation === RelationsEnum.MarkSelf &&
      assessment.type === AssessmentTypeEnum.Class)
  ) {
    return $t(
      '打分，打星，等级，评语标签在待审核、已通过、已发布时，或者学生自评评价项无法被任务引用',
    )
  }

  if (item.stage?.id && assessment.assessmentStages?.length) {
    const itemStageStatus = assessment.assessmentStages.find(
      assStage => assStage.stageId === item.stage?.id,
    )

    if (
      itemStageStatus &&
      [
        AssessmentStageStatusEnum.Submitted,
        AssessmentStageStatusEnum.Passed,
        AssessmentStageStatusEnum.Published,
      ].includes(itemStageStatus.status)
    ) {
      return $t(
        '{stageName}成绩待审核、已通过、已发布，如需编辑，请先撤回成绩',
        {
          stageName: item.stage.name,
        },
      )
    }
  }

  return ''
}

/**
 * 获取目标评价项的 icon
 *
 * @param item - 评价项
 * @returns icon 或 undefined
 */
export const getAssessmentItemIcon = (item: Item): SeIconProp | undefined => {
  if (item.lockSetting) {
    return faLock
  }

  return undefined
}

/**
 * 获取当前 assessment 的评价树及其字典
 *
 * @param assessment - 评价数据
 * @returns 评价树及其字典
 */
export const useCurrentTree = (assessment: Assessment) =>
  useMemo(
    () => convertItemsToTree(assessment, assessment.items || []),
    [assessment],
  )

/**
 * 获取当前子评价项
 *
 * @param assessment - 评价数据
 * @param query - 查询条件
 * @returns 当前子评价项
 */
export const useCurrentItemNode = (
  assessment: Assessment,
  query: { itemId?: string | undefined } & Query,
) => {
  const treeNodeMap = useCurrentTree(assessment)[1]
  return useMemo(
    () => (query.itemId ? treeNodeMap[query.itemId] : null),
    [query, treeNodeMap],
  )
}

/**
 * 评价项是不是待审核/已发布
 *
 * @param item - 评价项
 * @returns 是不是待审核/已发布
 */
export const isItemLoosePublished = (item: ItemInterface | Item) =>
  !!item.scoringType &&
  isNeedPublished(item.scoringType) &&
  isLoosePublished(item)

/**
 * 节点是否有需发布且已发布的项
 *
 * @param param - 参数
 * @param param.item - 评价项
 * @param param.children - 评价项子元素
 * @returns 是否有需发布且已发布的项
 */
export const isNodeHasPublishedChild = ({
  item,
  children,
}: TreeNode): boolean => {
  if (children) {
    return !!children.find(child => isNodeHasPublishedChild(child))
  }

  return isItemLoosePublished(item)
}

/**
 * 计算维度的满分
 *
 * @param treeNode - 节点
 * @returns 包含分数的节点信息
 */
export const caculateTreeNodeScore = (
  treeNode: TreeNode | null | undefined,
) => {
  if (!treeNode) return
  if (!treeNode.children || treeNode.score === null || !treeNode) return

  const {
    score: parentScore,
    item: { computeType },
    children,
  } = treeNode

  const withinChildren = children.filter(child => isItemWithinScore(child.item))
  /* eslint-disable no-param-reassign */
  if (computeType === ComputeTypeEnum.Sum) {
    withinChildren.forEach(node => {
      node.score = Number(node.item.withinScore) || 0
    })
  } else if (computeType === ComputeTypeEnum.Avg) {
    withinChildren.forEach(node => {
      // 平均下不要分数
      node.score = null
    })
  } else if (computeType === ComputeTypeEnum.WeightedAvg) {
    const allWeight = sumBy(withinChildren, 'item.weight')

    withinChildren.forEach(node => {
      if (node.item.weight && allWeight) {
        node.score = (parentScore * node.item.weight) / allWeight
      } else {
        node.score = 0
      }
    })
  }

  withinChildren.forEach(node => {
    node.score = node.score && floor(node.score, 2)
    caculateTreeNodeScore(node)
  })

  /* eslint-disable consistent-return */
  return withinChildren
}

/**
 * 过滤已发布的评价节点
 *
 * @param params - 参数
 * @param params.node - 待过滤的树节点
 * @param params.scores - 已发布的分数
 * @returns 过滤后的树节点，只包含已发布的评价项及其子项
 */
export const filterPublishedNode = ({
  node,
  scores,
}: {
  node: TreeNode
  scores: AchievedScore[] | null
}): TreeNode | null => {
  const { item: childItem } = node

  // 如果是需要发布的评价项并且已经发布
  if (
    childItem.type === ItemTypeEnum.Item &&
    childItem.scoringType &&
    isNeedPublished(childItem.scoringType) &&
    childItem.isScorePublished(
      scores?.find(score => score.itemId === childItem.id),
    )
  ) {
    return node
  }

  // 考勤和个性化评价直接就是发布状态
  if (
    [ItemTypeEnum.AttendanceItem, ItemTypeEnum.SpecialItem].includes(
      childItem.type,
    )
  ) {
    return node
  }

  // 加减分和标签直接就是发布状态
  if (
    childItem.type === ItemTypeEnum.Item &&
    childItem.scoringType &&
    [ScoringTypeEnum.Addition, ScoringTypeEnum.Tag].includes(
      childItem.scoringType,
    )
  ) {
    return node
  }

  // 如果有子节点，则递归过滤子节点
  if (node.children?.length) {
    const filtered = compact(
      node.children.map(sunNode =>
        filterPublishedNode({ node: sunNode, scores }),
      ),
    )

    // 如果过滤后没有子节点，返回 null，否则返回包含过滤后子节点的新节点
    return !filtered.length ? null : { ...node, children: filtered }
  }

  // 如果没有子节点，返回 null
  return null
}

/**
 * 过滤学段成绩的评价节点
 *
 * @param stageIds - 学段 ids
 * @param node - 需要过滤掉评价节点
 * @returns 过滤后的评价节点，只包含与指定阶段相关的节点及其子节点
 */
export const filterStagesNode = (
  stageIds: number[],
  node: TreeNode,
): TreeNode | null => {
  const { item: childItem } = node

  if (
    (childItem.stageId && stageIds.includes(childItem.stageId)) ||
    (childItem.stage?.id && stageIds.includes(childItem.stage.id))
  ) {
    return node
  }

  // 如果有子节点，则递归过滤子节点
  if (node.children?.length) {
    const filtered = compact(
      node.children.map(sunNode => filterStagesNode(stageIds, sunNode)),
    )

    // 如果过滤后没有子节点，返回 null，否则返回包含过滤后子节点的新节点
    return !filtered.length ? null : { ...node, children: filtered }
  }

  // 如果没有子节点，返回 null
  return null
}

/**
 * 获取维度已发布总分（已发布的评价项占总分之和）
 *
 * @param args - args
 * @param args.item - 评价项
 * @param args.scoreType - 得分类型，原始分或百分制得分
 * @param args.treeNodeMap - 树结构
 * @param args.precision - 精度
 * @returns 维度已发布总分
 */
export const getDimensionPublishedScore = ({
  item,
  treeNodeMap,
  precision,
  scoreType,
}: {
  item: Item
  treeNodeMap?: Dictionary<TreeNode>
  precision?: number
  scoreType?: SettingScoreTypeEnum
}): number | null => {
  // 百分制得分直接返回 100
  if (scoreType === SettingScoreTypeEnum.PercentageScore) {
    return 100
  }

  const currentItemNode = treeNodeMap?.[item.id]

  // 如果是加权平均/平均维度，已发布总分就是其占分或父维度占分
  if (item.isWeightedAvgDimension || item.isAvgDimension) {
    if (item.withinScore) {
      return +item.withinScore
    }

    return (
      (currentItemNode?.parent && isTreeNode(currentItemNode.parent)
        ? currentItemNode.parent.score
        : currentItemNode?.score) ?? 0
    )
  }

  // 维度/子维度的已发布总分（已发布的评价项占总分之和）
  const initialScore =
    item.computeType === ComputeTypeEnum.Avg &&
    currentItemNode?.score &&
    hasPublishedItemNode(currentItemNode)
      ? +currentItemNode.score
      : 0

  const isItemPublished = (itemNode: TreeNode) => {
    const isPublished =
      itemNode.item.status === ItemStatusEnum.Published ||
      itemNode.item.isTaskRelated

    if (itemNode.item.type === ItemTypeEnum.Item && isPublished) {
      return true
    }

    if (
      itemNode.item.type === ItemTypeEnum.AttendanceItem &&
      itemNode.item.withinScore &&
      isPublished
    ) {
      return true
    }

    return false
  }

  const score =
    currentItemNode?.children?.reduce((prevScore, currentNode) => {
      if (isItemPublished(currentNode)) {
        return prevScore + (currentNode.score || 0)
      }

      return (
        prevScore +
        (getDimensionPublishedScore({
          item: currentNode.item,
          scoreType,
          treeNodeMap,
        }) ?? 0)
      )
    }, initialScore) || 0

  return toNumberScore({ value: score, precision })
}

/**
 * 判断节点下是否有评价项已发布
 *
 * @param itemNode - 节点
 * @returns 是否
 */
const hasPublishedItemNode = (itemNode: TreeNode): boolean => {
  return !!itemNode.children?.some(childNode => {
    const { children, item } = childNode
    if (children?.length) {
      return hasPublishedItemNode(childNode)
    }

    return (
      item.type === ItemTypeEnum.Item &&
      (item.isTaskRelated || item.status === ItemStatusEnum.Published)
    )
  })
}

/**
 * 将给定树节点的维度展开，并返回一个维度列表
 *
 * @param treeNodes - 要展开的树节点数组
 * @returns 展开后的维度列表
 */
export const flattenDimensions = (treeNodes: TreeNode[]): Item[] => {
  return flatten(
    treeNodes
      .filter(treeNode => treeNode.item.isDimension)
      .map(treeNode => {
        return [treeNode.item, ...flattenDimensions(treeNode.children ?? [])]
      }),
  )
}

/**
 * 计算维度/评价项节点的最大占分
 *
 * @param params - 参数
 * @param params.parentNode - 父级节点
 * @param params.itemId - 维度/评价项 id
 * @param params.precision - 小数点精度
 * @returns 最大占分
 */
export const computeMaxWithinScore = ({
  parentNode,
  itemId,
  precision,
}: {
  parentNode: TreeNode | Tree
  itemId?: number
  precision?: number
}): number => {
  if (!isTree(parentNode) || !parentNode.root.isTemplate) {
    const parentScore = isTree(parentNode)
      ? Number(parentNode.root.fullScore)
      : Number(parentNode.score)

    return round(
      parentScore -
        sumBy(
          parentNode.children?.filter(_item => _item.id !== itemId),
          'score',
        ),
      precision || 2,
    )
  }

  return Number.MAX_SAFE_INTEGER
}
