import {
  faQuestionCircle,
  faTimesCircle,
  faInfoCircle,
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/pro-solid-svg-icons'
import {
  Button,
  Tooltip,
  Radio,
  Select,
  Checkbox,
  Input,
  Textarea,
  InputCaptcha,
} from '@seiue/ui'
import {
  ThemeProvider,
  useGlobalState,
} from '@seiue/ui/build/module/theme/Provider'
import {
  // eslint-disable-next-line no-restricted-imports
  Modal as ModalAntd,
} from 'antd'
import { ModalFuncProps as OriginModalFuncProps } from 'antd/es/modal'
import React, { CSSProperties, FC, useEffect } from 'react'
import styled from 'styled-components'

import { SeIcon as Icon } from 'packages/components/Icon'
import { $t } from 'packages/locale'
import { Color, BackgroundColor, FontSize } from 'packages/themed'

export type ModalFuncType =
  | 'confirm'
  | 'info'
  | 'success'
  | 'error'
  | 'warning'
  | 'danger'

export interface CheckItem {
  key: string
  label: string
  defaultValue?: boolean
  disabled?: boolean
  tooltip?: string
}

interface Option {
  key: string | number
  label: string
}

interface RadioOptions {
  defaultKey?: string | number
  options: Option[]
  selectPlaceholder?: string
  useSelectComponent?: boolean
  description?: string
}

interface InputOptions {
  placeholder?: string
  required?: boolean
  maxLength?: number
  textarea?: boolean
}

export interface ConfirmArguments {
  checkboxValues: { [key: string]: boolean }
  radioValue?: string | number
  inputValue: string
}

export interface ModalFuncProps extends OriginModalFuncProps {
  closeWhenFailed?: boolean
  useCaptcha?: boolean
  captchaCode?: string
  checklist?: CheckItem[]
  radio?: RadioOptions
  input?: InputOptions
  onOk?: (args?: ConfirmArguments) => void
}

type ModalFunc = (props: ModalFuncProps) => {
  destroy: () => void
  update: (newConfig: ModalFuncProps) => void
}

/**
 * 处理 Modal Callback Function，令其除了触发自己的默认行为，还能抛出错误
 *
 * @param actionFn - onOk or onCancel
 * @param closeWhenFailed - 是否在发生错误时关闭 Modal
 * @returns 返回一个 Promise，其 resolve 值为 actionFn 的返回值，reject 值为 actionFn 的错误
 */
const callbackFactory = (actionFn: any, closeWhenFailed = true) =>
  actionFn &&
  (() =>
    new Promise((resolve, reject) => {
      const result = actionFn()
      if (result && result.then) {
        result.then(resolve).catch((e: any) => {
          if (closeWhenFailed === false) reject(e)
          else resolve(undefined)
          throw e
        })
      } else {
        resolve(undefined)
      }
    }))

const iconMap: { [key in ModalFuncType]?: ModalFuncProps['icon'] } = {
  confirm: (
    <Icon
      icon={faQuestionCircle}
      style={{ color: Color.ElYellow, fontSize: 28 }}
    />
  ),
  info: (
    <Icon icon={faInfoCircle} style={{ color: Color.ElTheme, fontSize: 28 }} />
  ),
  warning: (
    <Icon
      icon={faExclamationCircle}
      style={{ color: Color.ElYellow, fontSize: 28 }}
    />
  ),
  error: (
    <Icon icon={faTimesCircle} style={{ color: Color.ElRed, fontSize: 28 }} />
  ),
  success: (
    <Icon icon={faCheckCircle} style={{ color: Color.ElTheme, fontSize: 28 }} />
  ),
}

const SimpleContent: FC<{ style?: CSSProperties; className?: string }> = ({
  style,
  className,
  children,
}) => {
  const [themeColor] = useGlobalState('themeColor')

  return (
    <ThemeProvider themeColor={themeColor}>
      <div style={style} className={className}>
        {children}
      </div>
    </ThemeProvider>
  )
}

const StatefulContent = (
  props: Omit<ModalFuncProps, 'onOk'> & {
    fnName: string
    onOk?: (args: ConfirmArguments) => void
  },
) => {
  const [canSubmit, setCanSubmit] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')
  const [checkboxValues, setCheckboxValues] = React.useState<{
    [key: string]: boolean
  }>({})

  const [radioValue, setRadioValue] = React.useState(props.radio?.defaultKey)
  const isDanger = props.fnName === 'danger'
  const toggleCheckItemChecked = (key: string, checked: boolean) => {
    setCheckboxValues({ ...checkboxValues, [key]: checked })
  }

  useEffect(() => {
    if (props.checklist?.length) {
      const initialValues: { [key: string]: boolean } = {}
      props.checklist.forEach(item => {
        initialValues[item.key] = !!item.defaultValue
      })

      setCheckboxValues(initialValues)
    }
  }, [props.checklist])

  const okText = props.okText || (isDanger ? $t('确定删除') : $t('确定'))

  const okType = !isDanger ? 'primary' : undefined
  const inputValuesTooLong = inputValue.length > (props.input?.maxLength || 20)
  const inputValueRequiredButEmpty = props.input?.required && !inputValue
  const inputProps = {
    style: { margin: '10px 0 0 0' },
    onChange: (e: any) => {
      setInputValue(e.target.value)
    },
    placeholder: props.input?.placeholder || $t('请输入'),
  }

  const [themeColor] = useGlobalState('themeColor')

  return (
    <ThemeProvider themeColor={themeColor}>
      <DangerContentWrapper>
        {!!props.content && props.useCaptcha ? (
          <DangerContentText danger={props.fnName === 'danger'}>
            {props.content}
          </DangerContentText>
        ) : (
          props.content
        )}
        {props.useCaptcha && (
          <InputCaptcha onValid={setCanSubmit} code={props.captchaCode} />
        )}
        {!!props.radio && (
          <Options>
            {!props.radio.useSelectComponent ? (
              <Radio.Group
                onChange={e => setRadioValue(e.target.value)}
                value={radioValue}
              >
                {props.radio.options.map(item => (
                  <OptionWrapper key={item.key}>
                    <Radio value={item.key}>{item.label}</Radio>
                  </OptionWrapper>
                ))}
              </Radio.Group>
            ) : (
              <>
                {!!props.radio?.description && (
                  <Description>{props.radio.description}</Description>
                )}
                <Select
                  placeholder={props.radio.selectPlaceholder || $t('请选择')}
                  style={{ width: '100%', marginTop: '12px' }}
                  value={radioValue}
                  onChange={value => setRadioValue(value as string | number)}
                  options={props.radio.options.map(opt => ({
                    label: opt.label,
                    value: opt.key,
                  }))}
                />
              </>
            )}
          </Options>
        )}
        {!!props.input && (
          <>
            {props.input.textarea ? (
              <Textarea rows={3} {...inputProps} />
            ) : (
              <Input {...inputProps} />
            )}
            {inputValuesTooLong && (
              <Warn>{`${$t('内容过长')} ${inputValue.length}/${
                props.input.maxLength
              }`}</Warn>
            )}
          </>
        )}
        {!!props.checklist?.length && (
          <Options>
            {props.checklist.map(item => (
              <OptionWrapper key={item.key}>
                <Checkbox
                  disabled={item.disabled}
                  checked={checkboxValues[item.key]}
                  onChange={e => {
                    toggleCheckItemChecked(item.key, e.target.checked)
                  }}
                >
                  {item.tooltip ? (
                    <Tooltip title={item.tooltip}>{item.label}</Tooltip>
                  ) : (
                    item.label
                  )}
                </Checkbox>
              </OptionWrapper>
            ))}
          </Options>
        )}
      </DangerContentWrapper>
      <DangerContentButtonGroup>
        <Button {...(props.cancelButtonProps as any)} onClick={props.onCancel}>
          {props.cancelText || $t('取消')}
        </Button>
        <Button
          // @ts-expect-error 此处使用的是 packages/components/Button，但类型声明是 antd/Button，需要修正。使用我们 Button 的原因是为了支持 Async Click Loading
          type={okType}
          {...props.okButtonProps}
          danger={isDanger}
          onClick={() =>
            !!props.onOk &&
            props.onOk({ checkboxValues, radioValue, inputValue })
          }
          disabled={
            (props.useCaptcha && !canSubmit) ||
            inputValuesTooLong ||
            inputValueRequiredButEmpty
          }
        >
          {okText}
        </Button>
      </DangerContentButtonGroup>
    </ThemeProvider>
  )
}

export const DangerContentWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: 24px;
  padding-left: 6px;
`

export const DangerContentText = styled.div<{ danger: boolean }>`
  margin-bottom: 15px;
  padding: 8px 12px;
  color: ${props => (props.danger ? Color.Red : Color.Yellow)};
  background-color: ${props =>
    props.danger ? BackgroundColor.LightRed : BackgroundColor.LightYellow};
  border-radius: 6px;
`

export const DangerContentButtonGroup = styled.div`
  float: right;
`

const modalFuncFactory = (fnName: ModalFuncType): ModalFunc => {
  const isConfirm = fnName === 'danger' || fnName === 'confirm'

  return ({ closeWhenFailed, ...props }: ModalFuncProps) => {
    const isComplexContent =
      props.useCaptcha ||
      !!props.checklist?.length ||
      !!props.radio ||
      !!props.input

    const nextProps = {
      icon: iconMap[fnName],
      // FIXME: the background-color of ok button is abnormal due to the ok button auto focus when not set autoFocusButton
      autoFocusButton: null,
      ...props,
      content: props.content ? (
        <InnerContent
          isConfirm={isConfirm}
          useCaptcha={props.useCaptcha}
          isComplex={isComplexContent}
        >
          {props.content}
        </InnerContent>
      ) : (
        props.content
      ),
      onOk: callbackFactory(props.onOk, closeWhenFailed),
      onCancel: callbackFactory(props.onCancel, closeWhenFailed),
    }

    if (fnName === 'danger' || fnName === 'confirm') {
      nextProps.icon =
        fnName === 'danger' ? (
          <Icon
            icon={faExclamationCircle}
            style={{ color: Color.ElRed, fontSize: 28 }}
          />
        ) : (
          iconMap.confirm
        )

      if (isComplexContent) {
        let instanceRef: any
        const onCancel = () => {
          props.onCancel?.()
          instanceRef.destroy()
        }

        /*
         * 利用 className 入侵 antd Modal 的样式
         * 对应的样式在 packages/src/themed/desktop/Modal.less
         * 注意关联影响apps/chalk/src/components/Modal/validatePhone.tsx
         */
        nextProps.className = 'danger-modal-with-captcha'
        nextProps.content = (
          <StatefulContent
            {...nextProps}
            fnName={fnName}
            onCancel={onCancel}
            onOk={async values => {
              await props.onOk?.(values)
              instanceRef.destroy()
            }}
          />
        )

        const modalInstance = ModalAntd.confirm(nextProps)
        instanceRef = modalInstance
        return modalInstance
      }
    }

    if (fnName === 'danger') {
      return ModalAntd.confirm(nextProps)
    }

    return ModalAntd[fnName](nextProps)
  }
}

/**
 * @deprecated use Modal.confirm from @seiue/ui instead.
 */
export const confirm = modalFuncFactory('confirm')

/**
 * @deprecated use Modal.danger from @seiue/ui instead.
 */
export const danger = modalFuncFactory('danger')

const InnerContent = styled(SimpleContent)<{
  useCaptcha?: boolean
  isConfirm?: boolean
  isComplex?: boolean
}>`
  /*
  * 限制警告内容的高度 (confirm)
  */
  max-height: ${props => (props.isConfirm ? '40vh' : 'auto')};
  margin-top: ${props => (props.isConfirm && !props.useCaptcha ? '-2px' : 0)};
  margin-left: ${props => (props.isComplex ? 0 : '6px')};
  overflow: auto;
`

const OptionWrapper = styled.div`
  margin-top: 10px;
`

const Options = styled.div`
  margin-bottom: 24px;
`

const Description = styled.div`
  margin-top: 20px;
  color: ${Color.DeepGray};
  font-size: ${FontSize.T2};
  line-height: 20px;
`

const Warn = styled.div`
  margin-top: 8px;
  color: ${Color.Red};
`
