/**
 * @file FullScreenModal methods
 */

import { Portal } from '@seiue/ui'
import { isFunction } from '@seiue/util'
import React from 'react'

import { FullScreenModal, FullScreenModalProps } from './FullScreenModal'

/**
 * 打开全屏弹窗的快捷方式，使用方法类似于 Modal.open
 *
 * @param props - FullScreenModalProps
 * @returns 返回一个对象，包含 hide 方法
 */
export const openFullScreenModal = (
  props: Omit<FullScreenModalProps, 'visible'> & {
    children: React.ReactNode
  },
) => {
  const modalKey = Portal.add(
    <FullScreenModal
      visible={true}
      getContainer={false}
      onCancel={e => {
        if (isFunction(props.onCancel)) {
          props.onCancel(e)
        } else {
          Portal.remove(modalKey)
        }
      }}
      {...props}
    />,
  )

  return {
    hide: () => Portal.remove(modalKey),
  }
}
