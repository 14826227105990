/**
 * 为处理和其他基础模块（比如 school）的循环引用而取出
 */
import { uniq } from '@seiue/util'
import { useEffect, useMemo, useState } from 'react'

import { useI18nName } from 'packages/feature-utils/reflections'
import { roleToLabel } from 'packages/features/reflections/utils/views'
import {
  useCurrentSchool,
  useGetSchoolName,
} from 'packages/features/schools/hooks'
import { TermTypeEnum } from 'packages/features/terms/types'
import { $t } from 'packages/locale'
import {
  Guardian,
  Reflection,
  RoleEnum,
  School,
  Term,
  reflectionApi$loadGuardian,
  systemApi$loadSchool,
  systemApi$querySchools,
  termApi$queryTerm,
} from 'packages/sdks-next/chalk'

import { useCurrentReflection, useCurrentUser } from './data'

/**
 * 获取指定身份的全部数据
 *  - 目前为学校名称，归档名称以及家长孩子名称
 *  - 如果含跨学校用户资源，将无法显示跨学校家长的孩子名称
 *
 * @param reflections - 用户信息
 * @returns 返回传入 reflections 的完整信息
 */
export const useReflectionsWithFullData = (reflections: Reflection[]) => {
  const $name = useI18nName()

  const ids = uniq(reflections.map(reflection => reflection.schoolId))
  const currentSchool = useCurrentSchool()

  const options = {
    staleTime: 2 * 60,
    omitAuthorizationHeader: true,
    omitReflectionHeaders: true,
  }

  const { data: querySchoolData } = systemApi$querySchools.useApi(
    {
      idIn: ids.join(','),
      hiddenIn: '0,1',
      paginated: 0,
    },
    { ...options, disable: ids.length <= 1 },
  )

  const { data: loadSchoolData } = systemApi$loadSchool.useApi(
    {
      id: `${ids[0]}`,
    },
    {
      ...options,
      disable: ids.length !== 1 || currentSchool?.id === ids[0],
    },
  )

  const schools: School[] = useMemo(() => {
    if (querySchoolData) {
      return querySchoolData
    }

    if (loadSchoolData) {
      return [loadSchoolData]
    }

    if (currentSchool) {
      return [currentSchool]
    }

    return []
  }, [querySchoolData, loadSchoolData, currentSchool])

  const getSchoolName = useGetSchoolName()

  const [pupilNameMap, setPupilNameMap] = useState<{
    [key: string]: string | undefined
  }>({})

  useEffect(() => {
    // 目前系统允许一个账号绑定多个学校的用户，这导致下方获取家长信息时，获取其它学校的家长信息时会出现资源不存在的错误，目前对请求数据进行学校过滤处理，避免频繁出现“资源不存在”错误
    const guardians = reflections.filter(
      r => r.role === RoleEnum.Guardian && r.schoolId === currentSchool?.id,
    )

    if (guardians.length) {
      /*
       * FIXME
       * 等待 TS4.2
       */
      Promise.all([
        ...guardians.map(guardian =>
          reflectionApi$loadGuardian.api(guardian.id || 0, {
            tryExpand: ['pupil'] as const,
          }),
        ),
        termApi$queryTerm.api(TermTypeEnum.GuardianRole),
      ] as any).then((res: any[]) => {
        const guardianRoleTerms: Term[] = res.pop().data
        const guardiansData: Guardian[] = res.map(_r => _r.data)
        setPupilNameMap(
          guardiansData.reduce(
            (result, data) => ({
              ...result,
              [data.id]: data.pupil
                ? `${$name(data.pupil)} ${
                    guardianRoleTerms.find(
                      term => term.id === data.guardianRoleId,
                    )?.name || $t('家长')
                  }`
                : '',
            }),
            {} as { [key: string]: string | undefined },
          ),
        )
      })
    }
  }, [$name, reflections, currentSchool])

  return useMemo(
    () =>
      reflections.map(reflection => {
        const school = schools?.find(s => s.id === reflection.schoolId)
        let archivedTypeName = reflection.archivedType?.name
        if (archivedTypeName && reflection.role === RoleEnum.Guardian) {
          archivedTypeName = `${$t('学生')}${archivedTypeName}`
        }

        return {
          ...reflection,
          schoolName: getSchoolName(school) || $t('未知学校'),
          archivedTypeName,
          pupilName: pupilNameMap[reflection.id],
        }
      }),
    [reflections, schools, getSchoolName, pupilNameMap],
  )
}

/**
 * 获取当前 User 的全部数据
 *
 * 目前为学校名称，归档名称以及家长孩子名称
 *
 * @returns 当前 User 的全部数据
 */
export const useCurrentUserReflectionsWithFullData = () => {
  const { reflections } = useCurrentUser() || {
    reflections: [] as Reflection[],
  }

  return useReflectionsWithFullData(reflections)
}

/**
 * 返回当前身份的所有数据
 *
 * @returns 当前身份的数据
 */
export const useCurrentReflectionWithFullData = () => {
  const reflections = useCurrentUserReflectionsWithFullData()
  const reflection = useCurrentReflection()

  return reflections.find(({ id }) => reflection.id === id)
}

export type FullDataReflection = ReturnType<
  typeof useCurrentUserReflectionsWithFullData
>[0]

/**
 * 将含有全部数据的身份表达为可读的 string
 *
 * @param reflection - 用户数据
 * @returns 字符串
 */
export const convertFullDataReflectionToString = (
  reflection: FullDataReflection,
) =>
  `${$t('{schoolName}的{roleName}{name}', {
    schoolName: reflection.schoolName,
    roleName: roleToLabel.get(reflection.role),
    name: reflection.name,
  })}${
    reflection.role === RoleEnum.Guardian
      ? `（${reflection.pupilName || ''}）`
      : ''
  }`
