/**
 * @file 录入分数的操作判断和提示语
 */
import { moment } from '@seiue/moment'
import { includes } from '@seiue/util'

import { isScoreLocked } from 'packages/feature-utils/grades/utils/score'
import { ScopeDomain } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { ScopeEnhancerEnum } from 'packages/sdks-next/chalk'
import {
  Assessment,
  AssessmentStageStatusEnum,
  AssessmentStatusEnum,
  ExpandedAssessment,
  Item,
  ItemInterface,
  ItemStatusEnum,
  Setting,
  Stage,
} from 'packages/sdks-next/vnas'

import { getAssessmentStatusLabel } from './assessments'
import { formatItemLockAllowedTime, getItemStatusLabel } from './item'

export { isScoreLocked } from 'packages/feature-utils/grades/utils/score'

export interface Scope {
  enhancer: ScopeEnhancerEnum
  domain: ScopeDomain
  params?: {
    [key: string]: any
    idIn?: string
    pupil?: {
      [key: string]: any
    }
  }
}

const isPending = (target: Pick<Item | ItemInterface | Assessment, 'status'>) =>
  target.status === AssessmentStatusEnum.Pending

const isSubmitted = (
  target: Pick<
    Item | ItemInterface | Assessment | ExpandedAssessment,
    'status'
  >,
) => target.status === AssessmentStatusEnum.Submitted

/**
 * 总成绩或评价项是否「已发布」
 *
 * @param target - 评价项或评价对象
 * @returns 是否
 */
export const isPublished = (
  target: Pick<
    Item | ItemInterface | Assessment | ExpandedAssessment,
    'status'
  >,
) => target.status === AssessmentStatusEnum.Published

/**
 * 总成绩或评价项是否「已通过」
 *
 * @param target - 评价项或评价对象
 * @returns 是否
 */
export const isPasses = (
  target: Pick<
    Item | ItemInterface | Assessment | ExpandedAssessment,
    'status'
  >,
) => target.status === ItemStatusEnum.Passed

/**
 * 总成绩或评价项是否「已发布/已提交」
 *
 * @param target - 评价项或评价对象
 * @returns 是否
 */
export const isLoosePublished = (target: Item | ItemInterface | Assessment) =>
  isPublished(target) || isSubmitted(target) || isPasses(target)

/**
 * *********************************************
 * **************** 评价项操作判断 ****************
 * *********************************************
 */

type ValidItemActionParam = {
  /**
   * 评价对象
   */
  assessment: Assessment | ExpandedAssessment
  /**
   * 评价项
   */
  item: Item | ItemInterface
  /**
   * 是否有成绩管理权限
   */
  hasManagerPermission: boolean
  /**
   * 学期学段列表
   */
  semesterStages?: Stage[] | null
}

/**
 * 获取评价项关联的学段和学段状态
 *
 * @param params - {@link ValidItemActionParam}
 * @returns 学段和学段状态
 */
const getItemStageStatus = (params: ValidItemActionParam) => {
  const { assessment, item, semesterStages } = params

  if (item.stageId && assessment instanceof Assessment) {
    const stageStatus = assessment.getStageStatus(item.stageId)
    const stage = semesterStages?.find(
      semesterStage => semesterStage.id === item.stageId,
    )

    return {
      stage,
      stageStatus,
      isStagePublished:
        stageStatus &&
        [
          AssessmentStageStatusEnum.Submitted,
          AssessmentStageStatusEnum.Published,
          AssessmentStageStatusEnum.Passed,
        ].includes(stageStatus),
    }
  }

  return {
    stage: undefined,
    stageStatus: undefined,
    isStagePublished: false,
  }
}

/**
 * 是否可以「保存」评价项分数
 *
 * @param params - valid params
 * @returns 是否
 */
export const canSaveItemScores = (params: ValidItemActionParam) => {
  const { assessment, item, hasManagerPermission } = params

  if (!item.status) return false

  // 任务关联的评价项不支持保存（处于默认发布模式）
  if (item instanceof Item && item.isTaskRelated) return false

  if (!hasManagerPermission && isScoreLocked(item)) return false

  const { isStagePublished } = getItemStageStatus(params)

  if (isStagePublished) return false

  if (
    [AssessmentStatusEnum.Pending, AssessmentStatusEnum.Rejected].includes(
      assessment.status,
    )
  ) {
    return [ItemStatusEnum.Pending, ItemStatusEnum.Rejected].includes(
      item.status,
    )
  }

  return false
}

/**
 * 是否可以「驳回」评价项分数
 *
 * @param params - valid params
 * @returns 是否
 */
export const canRejectItemScores = (params: ValidItemActionParam) => {
  const { assessment, item, hasManagerPermission } = params

  const { isStagePublished } = getItemStageStatus(params)

  if (isStagePublished) return false

  if (!item.reviewNeeded) return false

  return (
    hasManagerPermission &&
    [AssessmentStatusEnum.Pending, AssessmentStatusEnum.Rejected].includes(
      assessment.status,
    ) &&
    (isSubmitted(item) || isPublished(item) || isPasses(item))
  )
}

/**
 * 是否可以「修改」评价项分数
 *
 * @param params - valid params
 * @returns 是否
 */
export const canUpdateItemScores = (params: ValidItemActionParam) => {
  const { assessment, item, hasManagerPermission } = params

  if (!item.status) return false
  if (!hasManagerPermission && isScoreLocked(item)) return false

  const { isStagePublished } = getItemStageStatus(params)

  if (hasManagerPermission && isStagePublished) {
    return true
  }

  if (!hasManagerPermission && isStagePublished) {
    return false
  }

  const isItemExecuted = [
    ItemStatusEnum.SomePublished,
    ItemStatusEnum.Published,
    ItemStatusEnum.Passed,
    ItemStatusEnum.Submitted,
  ].includes(item.status)

  // 需审核评价项
  if (item.reviewNeeded) {
    if (
      [AssessmentStatusEnum.Pending, AssessmentStatusEnum.Rejected].includes(
        assessment.status,
      )
    ) {
      return hasManagerPermission && isItemExecuted
    }

    return hasManagerPermission
  }

  // 任务关联的评价项支持修改（处于默认发布模式），即使没有分数
  if (item instanceof Item && item.isTaskRelated) return true

  switch (assessment.status) {
    case AssessmentStatusEnum.Pending:
    case AssessmentStatusEnum.Rejected:
      return isItemExecuted

    default:
      return hasManagerPermission
  }
}

/**
 * 是否可以「发布」评价项分数
 *
 * @param params - valid params
 * @returns 是否
 */
export const canPublishItemScores = (params: ValidItemActionParam) => {
  const { assessment, item, hasManagerPermission } = params

  if (!item.status) return false

  const { isStagePublished } = getItemStageStatus(params)

  // 评价项关联的学段成绩待审核或者已发布
  if (isStagePublished) return false

  // 任务关联的评价项不支持发布（处于默认发布模式）
  if (item instanceof Item && item.isTaskRelated) return false

  // 总成绩未提交、已驳回
  if (
    [AssessmentStatusEnum.Pending, AssessmentStatusEnum.Rejected].includes(
      assessment.status,
    )
  ) {
    // 需审核评价项
    if (item.reviewNeeded) {
      // 有管理者权限 && 评价项未提交、待审核、已驳回、部分发布、已通过
      return (
        hasManagerPermission &&
        [
          ItemStatusEnum.Pending,
          ItemStatusEnum.Submitted,
          ItemStatusEnum.Passed,
          ItemStatusEnum.Rejected,
          ItemStatusEnum.SomePublished,
        ].includes(item.status)
      )
    }

    // 评价项未提交
    return isPending(item) || item.status === ItemStatusEnum.SomePublished
  }

  return false
}

/**
 * 是否可以「通过」评价项分数
 *
 * @param params - valid params
 * @param params.assessment - 评价对象
 * @param params.item - 评价项
 * @param params.hasManagerPermission - 是否有成绩管理权限
 * @returns 是否
 */
export const canPassItemScores = (params: ValidItemActionParam) => {
  const { assessment, item, hasManagerPermission } = params

  if (!item.status) return false

  const { isStagePublished } = getItemStageStatus(params)

  if (isStagePublished) return false

  if (
    [AssessmentStatusEnum.Pending, AssessmentStatusEnum.Rejected].includes(
      assessment.status,
    )
  ) {
    // 需审核评价项
    if (item.reviewNeeded) {
      return (
        hasManagerPermission &&
        [
          ItemStatusEnum.Pending,
          ItemStatusEnum.Submitted,
          ItemStatusEnum.Rejected,
          ItemStatusEnum.SomePublished,
          ItemStatusEnum.Published,
        ].includes(item.status)
      )
    }
  }

  return false
}

/**
 * 是否可以「提交审核」评价项分数
 *
 * @param params - valid params
 * @returns 是否
 */
export const canSubmitItemScores = (params: ValidItemActionParam) => {
  const { assessment, item, hasManagerPermission } = params

  if (!item.status) return false
  if (!hasManagerPermission && isScoreLocked(item)) return false

  const { isStagePublished } = getItemStageStatus(params)

  if (isStagePublished) return false

  return (
    [AssessmentStatusEnum.Pending, AssessmentStatusEnum.Rejected].includes(
      assessment.status,
    ) &&
    !hasManagerPermission &&
    item.reviewNeeded &&
    [ItemStatusEnum.Pending, ItemStatusEnum.Rejected].includes(item.status)
  )
}

/**
 * 是否可以「撤回」评价项分数
 *
 * @param params - valid params
 * @returns 是否
 */
export const canCancelSubmittedItemScores = (params: ValidItemActionParam) => {
  const { assessment, item, hasManagerPermission } = params

  if (!item.status) return false
  if (!hasManagerPermission && isScoreLocked(item)) return false

  const { isStagePublished } = getItemStageStatus(params)

  // 评价项关联的学段成绩待审核、已通过或已发布
  if (isStagePublished) return false

  // 总成绩未提交、已驳回
  if (
    [AssessmentStatusEnum.Pending, AssessmentStatusEnum.Rejected].includes(
      assessment.status,
    )
  ) {
    // 需审核评价项
    return item.reviewNeeded
      ? !hasManagerPermission && ItemStatusEnum.Submitted === item.status
      : [ItemStatusEnum.Published, ItemStatusEnum.SomePublished].includes(
          item.status,
        )
  }

  return false
}

/**
 * 判断当前是否处于设置的可录入时间范围内（必须设置了时间范围）
 *
 * @param item - 评价项
 * @returns boolean
 */
export const isInScoreEntryTime = (item: Item) => {
  const { startAt, endAt } = item.lockRules || {}
  return !!(
    item.lockScore &&
    startAt &&
    endAt &&
    moment().isBetween(startAt, endAt)
  )
}

/**
 * 评价项分数是否为「只读」
 *
 * @param params - valid params
 * @returns 是否
 */
export const isItemScoresReadOnly = (params: ValidItemActionParam) =>
  !canSaveItemScores(params) && !canUpdateItemScores(params)

/**
 * 获取不能保存/修改分数的原因
 *
 * @param params - valid params
 * @returns 原因
 */
export const getNotSaveScoresReason = (params: ValidItemActionParam) => {
  const { assessment, item, hasManagerPermission } = params

  if (hasManagerPermission) return ''

  // 评价项录入锁定
  if (item.lockScore) {
    const allowedTime = formatItemLockAllowedTime(item)

    const { startAt, endAt } = item.lockRules || {}
    if (startAt && endAt && moment().isBetween(startAt, endAt)) {
      return $t('评价项可以录入，可录入时间为 {time}，请及时完成录入', {
        time: allowedTime,
      })
    }

    return allowedTime
      ? $t(
          '评价项不在录入时间内，录入时间: {time} 如需修改成绩，请先联系管理员修改录入时间',
          {
            time: allowedTime,
          },
        )
      : $t('该评价项不在录入时间内')
  }

  // 需审核评价项
  if (item.reviewNeeded) {
    // 总成绩未提交、已驳回 && 评价项已发布
    if (
      [AssessmentStatusEnum.Pending, AssessmentStatusEnum.Rejected].includes(
        assessment.status,
      )
    ) {
      if (isPublished(item) || isPasses(item)) {
        return $t('评价项{status}，如需修改成绩请联系教务驳回成绩', {
          status: getItemStatusLabel(item.status ?? undefined),
        })
      }
    }
  }

  if (item.stageId) {
    const { stage, stageStatus } = getItemStageStatus(params)

    if (stage) {
      if (stageStatus === AssessmentStageStatusEnum.Submitted) {
        return $t('{stageName}成绩已提交审核，如需修改成绩，请先撤回', {
          stageName: stage.name,
        })
      }

      if (
        stageStatus &&
        [
          AssessmentStageStatusEnum.Published,
          AssessmentStageStatusEnum.Passed,
        ].includes(stageStatus)
      ) {
        return $t(
          '{stageName}总成绩{status}，如需修改成绩，请先联系教务驳回成绩',
          {
            stageName: stage.name,
            status: getAssessmentStatusLabel(stageStatus),
          },
        )
      }
    }
  }

  if (
    isSubmitted(assessment) ||
    isPublished(assessment) ||
    isPasses(assessment)
  ) {
    return $t('总成绩{status}，如需修改成绩请联系教务驳回总成绩', {
      status: getAssessmentStatusLabel(assessment.status),
    })
  }

  return ''
}

/**
 * *********************************************
 * **************** 总成绩操作判断 ****************
 * *********************************************
 */

type ValidTotalGradeActionParam = {
  /**
   * 评价对象
   */
  assessment: Assessment
  /**
   * 当前选中的学段
   */
  currentStage?: Stage
  /**
   * 是否有成绩管理权限
   */
  hasManagerPermission: boolean
  /**
   * 成绩的全局设置
   */
  gradeSetting?: Setting | null
}

/**
 * 是否可以「保存」总成绩/学段成绩
 *
 * @param params - valid params
 * @param params.assessment - 评价对象
 * @param params.currentStage - 当前选中的学段
 * @returns 是否
 */
export const canSaveGrades = ({
  assessment,
  currentStage,
}: ValidTotalGradeActionParam) => {
  let { status } = assessment

  // 获取学段成绩的发布状态
  if (currentStage) {
    const stageStatus = assessment.getStageStatus(currentStage.id)
    if (!stageStatus) return false

    status = stageStatus as unknown as AssessmentStatusEnum
  }

  return [AssessmentStatusEnum.Pending, AssessmentStatusEnum.Rejected].includes(
    status,
  )
}

/**
 * 是否可以「撤回」总成绩/学段成绩
 *
 * @param params - valid params
 * @returns 是否
 */
export const canCancelSubmittedGrades = (
  params: ValidTotalGradeActionParam,
) => {
  const { assessment, currentStage, hasManagerPermission } = params

  let { status } = assessment

  // 获取学段成绩的发布状态
  if (currentStage) {
    const stageStatus = assessment.getStageStatus(currentStage.id)
    if (!stageStatus) return false

    status = stageStatus as unknown as AssessmentStatusEnum
  }

  return (
    (!hasManagerPermission && status === AssessmentStatusEnum.Submitted) ||
    (hasManagerPermission &&
      [AssessmentStatusEnum.Published, AssessmentStatusEnum.Passed].includes(
        status,
      ))
  )
}

/**
 * 是否可以「提交审核」总成绩
 *
 * @param params - valid params
 * @param params.gradeSubmitReviewEnabled - 已开启「允许提交审核」
 * @returns 是否
 */
export const canSubmitGrades = (
  params: ValidTotalGradeActionParam & {
    gradeSubmitReviewEnabled?: boolean
  },
) => {
  const {
    assessment,
    currentStage,
    hasManagerPermission,
    gradeSetting,
    gradeSubmitReviewEnabled,
  } = params

  // 不可「提交审核」总成绩的课程班 Ids
  const { resourceIds } = gradeSetting?.gradeVerifyScope || {}
  const enableGradeVerify =
    gradeSubmitReviewEnabled && resourceIds?.length
      ? !includes(resourceIds, assessment?.klass?.id)
      : true

  let { status } = assessment

  // 获取学段成绩的发布状态
  if (currentStage) {
    const stageStatus = assessment.getStageStatus(currentStage.id)
    if (!stageStatus) return false

    status = stageStatus as unknown as AssessmentStatusEnum
  }

  // 无管理者权限 && 未设置隐藏 `提交审核` 按钮 && 总成绩/学段成绩未发布、已驳回
  return (
    !hasManagerPermission &&
    enableGradeVerify &&
    [AssessmentStatusEnum.Pending, AssessmentStatusEnum.Rejected].includes(
      status,
    )
  )
}

/**
 * 是否可以「修改」总成绩
 *
 * @param params - valid params
 * @returns 是否
 */
export const canUpdateGrades = (params: ValidTotalGradeActionParam) => {
  const { assessment, currentStage, hasManagerPermission } = params

  let { status } = assessment

  // 获取学段成绩的发布状态
  if (currentStage) {
    const stageStatus = assessment.getStageStatus(currentStage.id)
    if (!stageStatus) return false

    status = stageStatus as unknown as AssessmentStatusEnum
  }

  return (
    hasManagerPermission &&
    [
      AssessmentStatusEnum.Submitted,
      AssessmentStatusEnum.Passed,
      AssessmentStatusEnum.Published,
    ].includes(status)
  )
}

/**
 * 是否可以「发布」总成绩
 *
 * @param params - valid params
 * @returns 是否
 */
export const canPublishGrades = (params: ValidTotalGradeActionParam) => {
  const { assessment, currentStage, hasManagerPermission } = params

  let { status } = assessment

  // 获取学段成绩的发布状态
  if (currentStage) {
    const stageStatus = assessment.getStageStatus(currentStage.id)
    if (!stageStatus) return false

    status = stageStatus as unknown as AssessmentStatusEnum
  }

  return hasManagerPermission && status !== AssessmentStatusEnum.Published
}

/**
 * 是否可以「通过」总成绩
 *
 * @param params - valid params
 * @returns 是否
 */
export const canPassGrades = (params: ValidTotalGradeActionParam) => {
  const { assessment, currentStage, hasManagerPermission } = params

  let { status } = assessment

  if (currentStage) {
    const stageStatus = assessment.getStageStatus(currentStage.id)
    if (!stageStatus) return false

    status = stageStatus as unknown as AssessmentStatusEnum
  }

  return hasManagerPermission && status !== AssessmentStatusEnum.Passed
}

/**
 *是否可以「驳回」总成绩
 *
 * @param params - valid params
 * @returns 是否
 */
export const canRejectGrades = (params: ValidTotalGradeActionParam) => {
  const { assessment, currentStage, hasManagerPermission } = params

  let { status } = assessment

  // 获取学段成绩的发布状态
  if (currentStage) {
    const stageStatus = assessment.getStageStatus(currentStage.id)
    if (!stageStatus) return false

    status = stageStatus as unknown as AssessmentStatusEnum
  }

  return (
    hasManagerPermission &&
    [
      AssessmentStatusEnum.Submitted,
      AssessmentStatusEnum.Passed,
      AssessmentStatusEnum.Published,
    ].includes(status)
  )
}

/**
 * 总成绩是否为「只读」
 *
 * @param params - valid params
 * @returns 是否
 */
export const isGradesReadOnly = (params: ValidTotalGradeActionParam) =>
  !canSaveGrades(params) && !canUpdateGrades(params)

/**
 * 获取不能修改总成绩/学段成绩的原因
 *
 * @param params - {@link ValidTotalGradeActionParam}
 * @returns 原因
 */
export const getNotSaveGradeReason = (params: ValidTotalGradeActionParam) => {
  const { assessment, hasManagerPermission, currentStage } = params

  if (hasManagerPermission) return ''

  if (currentStage) {
    const stageStatus = assessment.getStageStatus(currentStage.id)

    // 学段成绩待审核
    if (stageStatus === AssessmentStageStatusEnum.Submitted) {
      return $t('{stageName}成绩已提交审核，如需修改成绩，请先撤回', {
        stageName: currentStage.name,
      })
    }

    // 学段成绩已发布
    if (stageStatus === AssessmentStageStatusEnum.Published) {
      return $t('{stageName}总成绩已发布，如需修改成绩，请先联系管理员撤回', {
        stageName: currentStage.name,
      })
    }

    return ''
  }

  // 总成绩待审核
  if (isSubmitted(assessment)) {
    return $t('总成绩已提交审核，如需修改成绩，请先撤回')
  }

  // 总成绩已发布
  if (isPublished(assessment)) {
    return $t('总成绩已发布，如需修改成绩，请先联系管理员撤回')
  }

  return ''
}
