export enum ExamScoringStatusEnum {
  Preparing = 'preparing',
  Configured = 'configured',
  Recognising = 'recognising',
  Recognised = 'recognised',
  Pending = 'pending',
  InProgress = 'in_progress',
  Completed = 'completed',
  None = 'none',
}
