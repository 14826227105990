import { AppLayout, Space } from '@seiue/ui'
import {
  compact,
  flatten,
  getReactNodeInnerText,
  isArray,
  last,
  uniqBy,
  useOnMount,
} from '@seiue/util'
import React from 'react'
import { matchPath, useHistory } from 'react-router-dom'

import { modalRoutes, routes, useRouteAuthorization } from 'packages/route'
import { NestedRoute } from 'packages/route/components/NestedRoute'
import { WebLayout } from 'packages/web-layout'
import { useActivePath } from 'packages/web-layout/hooks'
import { getActiveMenuPath } from 'packages/web-layout/utils'

import { ApplicationMenu } from '@/App/Layout/ApplicationMenu'
import { TopperMenu } from '@/App/Layout/TopperMenu'
import { HomeSearcher } from '@/features/global-search/components/HomeSearcher'
import { useComponentSlots } from '@/plugins'
import {
  useHomeMenuSetting,
  useIsCustomHomeMenu,
} from '@/plugins/features/gzzx-home-menus-setting/hooks'

import { isNoCodeModule, useAllMenus, useSiderMenus } from '../hooks'

import { FontSize } from './FontSize'
import { Language } from './Language'
import { MessageBell } from './MessageBell'
import { ReflectionAvatar } from './ReflectionAvatar'
import { SemesterSelector } from './SemesterSelector'
import { SiderLogo } from './SiderLogo'

const InnerLayout: React.FC<{
  navbarBackgroundColor: string
  sliderBackgroundColor: string
}> = ({ navbarBackgroundColor, sliderBackgroundColor }) => {
  const history = useHistory()
  const authRoute = useRouteAuthorization()

  const { flattenMenus, layoutMenus: _layoutMenus } = useAllMenus()
  const HomeLayoutHeaderRightSlot = flatten(
    useComponentSlots('homeLayoutHeaderRight'),
  ).map((HomeLayoutHeaderRightItem, idx) => (
    <HomeLayoutHeaderRightItem key={idx} />
  ))

  const LayoutMainSlot = flatten(useComponentSlots('layoutMain')).map(
    (LayoutMainItem, idx) => <LayoutMainItem key={idx} />,
  )

  const layoutMenus = useHomeMenuSetting(_layoutMenus)
  const isCustomHomeMenu = useIsCustomHomeMenu()

  // 获取当前路径
  const activePath = useActivePath()
  // 获取菜单路径
  const activeMenuPath = getActiveMenuPath(activePath, flattenMenus)

  const { menus: siderMenus, modules } = useSiderMenus()

  // 侧边栏标题 (当进入二级菜单后)
  const siderMenuTitle =
    !isArray(siderMenus) && siderMenus
      ? getReactNodeInnerText(siderMenus.label)
      : ''

  const matchResult = routes.find(route => matchPath(activePath, route))
  const showSidebarMenu = matchResult?.showSidebarMenu ?? true

  useOnMount(() => {
    // @ts-expect-error 在 Cypress 环境下，将 history 挂载到 window 上，好让 cypress 可以使用
    if (window.__Cypress__) {
      // @ts-expect-error 在 Cypress 环境下，将 history 挂载到 window 上，好让 cypress 可以使用
      window.seiueHistory = history
    }
  })

  return (
    <WebLayout
      activeMenuPath={activeMenuPath}
      logoSlot={
        <SiderLogo
          onClick={() => {
            history.push('/')
          }}
        />
      }
      menus={showSidebarMenu ? siderMenus || [] : undefined}
      siderMenuTitle={siderMenuTitle}
      HeaderLeft={
        <TopperMenu
          showRecent={!isCustomHomeMenu}
          showTabs={!isCustomHomeMenu}
          menus={layoutMenus}
          navbarBackgroundColor={navbarBackgroundColor}
          dropdownMenuBackgroundColor={navbarBackgroundColor}
        />
      }
      HeaderRight={
        <Space size={20}>
          <HomeSearcher key={'searcher'} />
          {HomeLayoutHeaderRightSlot}
          <Language key={'language'} />
          <FontSize key="fontSize" />
          <MessageBell key={'unreadMessages'} />
          <ReflectionAvatar key={'avatar'} />
        </Space>
      }
      siderFooter={
        <>
          <ApplicationMenu />
          <SemesterSelector />
        </>
      }
      formatBreadcrumbs={breadcrumbs => {
        // 如果是插件自己的菜单，面包屑则从他自己开始
        if (siderMenus && !isArray(siderMenus) && 'subMenus' in siderMenus) {
          const formattedBreadcrumbs = breadcrumbs?.filter(
            crumb => crumb.path !== '/',
          )

          const menuArr = siderMenus.subMenus || []

          const menuAbsTitlePath = AppLayout.getMenuAbsolutePath(
            menuArr as any,
            activeMenuPath,
          ).map(menu => ({
            getTitle: () => menu.labelText || getReactNodeInnerText(menu.label),
            realPath: 'path' in menu && menu.path ? menu.path : undefined,
          }))

          const rootMenu = {
            getTitle: () => getReactNodeInnerText(siderMenus.label),
          }

          const uniqBreadcrumbs = uniqBy(
            compact([
              rootMenu,
              ...menuAbsTitlePath,
              // 无代码应用页面 tabRoute 不展示面包屑
              isNoCodeModule(modules) ? undefined : last(formattedBreadcrumbs),
            ]),
            'realPath',
          )

          return uniqBreadcrumbs
        }

        return breadcrumbs
      }}
      navbarBackgroundColor={navbarBackgroundColor}
      sliderBackgroundColor={sliderBackgroundColor}
    >
      {LayoutMainSlot}
      <NestedRoute
        routes={routes}
        modalRoutes={modalRoutes}
        authFunction={authRoute}
      />
    </WebLayout>
  )
}

/**
 * 基于 WebLayout 封装的 Chalk Layout 组件
 *
 * @param props - props
 * @param props.navbarBackgroundColor - 导航栏背景色
 * @param props.sliderBackgroundColor - 侧边栏背景色
 * @returns component
 */
export const Layout: React.FC<{
  navbarBackgroundColor: string
  sliderBackgroundColor: string
}> = ({ navbarBackgroundColor, sliderBackgroundColor }) => {
  return (
    <InnerLayout
      navbarBackgroundColor={navbarBackgroundColor}
      sliderBackgroundColor={sliderBackgroundColor}
    />
  )
}
