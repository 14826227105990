import { isNumber } from '@seiue/util'

/**
 * 比较题组名称
 *
 * @param group1 - 题组名称 1
 * @param group2 - 题组名称 2
 * @returns number
 */
export const compareQuestionGroup = (group1: string, group2: string) => {
  // 提取第一个字符
  const firstChar1 = group1.split(',')[0]
  const firstChar2 = group2.split(',')[0]

  // 转换为数字
  const num1 = Number(firstChar1)
  const num2 = Number(firstChar2)

  // 比较数字大小
  if (!isNumber(num1) || !isNumber(num2)) {
    return 0
  }

  return num1 - num2
}

/**
 * 获取考试对应所有科目的阅卷状态
 *
 * @param onlineScoringExam - props
 * @param onlineScoringExam.scoringStatusGeneralLabel - 阅卷状态 label
 * @returns 阅卷
 */
export const getExamSubjectScoringStatusType = (onlineScoringExam: {
  scoringStatusGeneralLabel: string
}) => {
  /* eslint-disable seiue/missing-formatted-message */
  switch (onlineScoringExam.scoringStatusGeneralLabel) {
    case '待阅卷':
      return 'warning' as const
    case '阅卷中':
      return 'ongoing' as const
    case '已结束':
      return 'success' as const
    default:
      return 'default' as const
  }
  /* eslint-disable seiue/missing-formatted-message */
}
