import { Tooltip, Tag } from '@seiue/ui'
import React from 'react'
import styled, { useTheme } from 'styled-components'

import { useI18nName } from 'packages/feature-utils/reflections'
import { roleToLabel } from 'packages/features/reflections'
import { FullDataReflection } from 'packages/features/sessions/utils/full-data'
import { $t } from 'packages/locale'
import { RoleEnum } from 'packages/sdks-next/chalk'

type Props = {
  reflection: FullDataReflection
  active: boolean
  onClick?: () => void
  unread?: boolean
  isSingleReflection?: boolean
}

/**
 * 显示身份项
 *
 * @param {Props} param0 - props
 * @returns FC
 */
export const ReflectionItem: React.FC<Props> = ({
  reflection,
  active,
  onClick,
  unread,
  isSingleReflection,
}) => {
  const theme = useTheme()

  const $name = useI18nName()

  return (
    <ReflectionItemWrapper onClick={onClick}>
      {reflection.disabled ? (
        <Tooltip title={$t('账号已被禁用')}>
          <DisabledShadow />
        </Tooltip>
      ) : null}
      <NameAndRole large={!!isSingleReflection}>
        <ItemName unread={unread} active={active}>
          {$name(reflection)}
        </ItemName>
        <ItemRole>
          {reflection.role === RoleEnum.Guardian
            ? reflection.pupilName || ''
            : roleToLabel.get(reflection.role)}
        </ItemRole>
        <Placeholder />
        {reflection.archivedTypeName ? (
          <ArchivedLabel
            colors={theme.palette[2]}
            text={reflection.archivedTypeName}
          />
        ) : null}
      </NameAndRole>

      {reflection.usin && (
        <IdAndSchool large={!!isSingleReflection}>
          <span>{reflection.usin}</span>
          <span>{reflection.schoolName}</span>
        </IdAndSchool>
      )}
    </ReflectionItemWrapper>
  )
}

const ReflectionItemWrapper = styled.div`
  position: relative;
  padding: 12px 20px;
`

const NameAndRole = styled.div<{ large: boolean }>`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: ${props => (props.large ? '16px' : '14px')};

  & span + span {
    margin-left: 8px;
  }
`

const ItemName = styled.span<{ unread?: boolean; active: boolean }>`
  position: relative;
  color: ${props => (props.active ? '#fff' : props.theme.text._1)};

  &::after {
    position: absolute;
    top: 50%;
    left: -13px;
    display: ${({ unread, active }) => (!active && unread ? 'block' : 'none')};
    width: 6px;
    height: 6px;
    background: #e03437;
    border-radius: 50%;
    transform: translateY(-50%);
    content: '';
  }
`

const DisabledShadow = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.6);
  cursor: not-allowed;
`

const ItemRole = styled.span`
  margin-left: 8px;
`

const Placeholder = styled.span`
  flex: 1;
`

const ArchivedLabel = styled(Tag)`
  max-width: 80px;
`

const IdAndSchool = styled(NameAndRole)`
  margin-top: 4px;
  font-weight: 400;
  font-size: 13px;
`
