import { ExamSubject, ExamSubjectInterface } from './extends/exam-subject'
import { ExamScheduleStatusEnum } from './exam-schedule-status-enum'
import {
  ExamScheduleNoticeSettings,
  ExamScheduleNoticeSettingsInterface,
} from './exam-schedule-notice-settings'
import {
  ExaminerGlobalRules,
  ExaminerGlobalRulesInterface,
} from './examiner-global-rules'
import { ExamCustomData, ExamCustomDataInterface } from './exam-custom-data'
import { ExamGradeStats, ExamGradeStatsInterface } from './exam-grade-stats'
import { ExamTypeEnum } from './exam-type-enum'
import { ExamStatusEnum } from './exam-status-enum'
import { ExamScoringTypeEnum } from './exam-scoring-type-enum'
import { Semester, SemesterInterface } from './semester'
import { Term, TermInterface } from './extends/term'
import { ExamStatsData, ExamStatsDataInterface } from './exam-stats-data'
import { ExamTeacherDisplayEnum } from './exam-teacher-display-enum'

export interface ExamInterface {
  /**
   * expand 科目
   */
  subjects?: ExamSubjectInterface[]
  /**
   * 编排结果
   */
  scheduleStatus?: ExamScheduleStatusEnum
  /**
   * 排考场发布通知设置
   */
  scheduleNoticeSettings?: ExamScheduleNoticeSettingsInterface | null
  /**
   * 全局监考规则
   */
  examinerGlobalRules?: ExaminerGlobalRulesInterface | null
  /**
   * 通用数据（用于广中成绩分析规则、抄送设置）
   */
  customData?: ExamCustomDataInterface | null
  /**
   * 考生数量
   */
  examineesCount?: number | null
  gradeStats?: ExamGradeStatsInterface
  scoringStatusGeneralLabel?: string
  semesterId: number
  name: string
  /**
   * 考试类型
   */
  examType: ExamTypeEnum
  /**
   * 考试标签，后续改为 tag_ids
   */
  typeId: number | null
  graduatesInId: number | null
  status: ExamStatusEnum
  stage?: number | null
  scoringType?: ExamScoringTypeEnum | null
  /**
   * expand 已录入的分数数量
   */
  existedScoresCount?: number
  /**
   * expand 应该有的分数数量，按照 relations 统计
   */
  allScoresCount?: number
  /**
   * expand 学期
   */
  semester?: SemesterInterface
  /**
   * expand 考试类型
   */
  type?: TermInterface | null
  /**
   * expand 届别
   */
  graduatesIn?: TermInterface | null
  /**
   * 考试统计相关设置数据
   */
  statsData?: ExamStatsDataInterface
  /**
   * 发布时间
   */
  publishedAt?: string | null
  studentDisplay?: string[] | null
  /**
   * 教师可见数据设置，null 标识设置未打开走全局设置
   */
  teacherDisplay?: ExamTeacherDisplayEnum[] | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class Exam implements ExamInterface {
  /**
   * expand 科目
   */
  subjects?: ExamSubject[]
  /**
   * 编排结果
   */
  scheduleStatus?: ExamScheduleStatusEnum
  /**
   * 排考场发布通知设置
   */
  scheduleNoticeSettings?: ExamScheduleNoticeSettings | null
  /**
   * 全局监考规则
   */
  examinerGlobalRules?: ExaminerGlobalRules | null
  /**
   * 通用数据（用于广中成绩分析规则、抄送设置）
   */
  customData?: ExamCustomData | null
  /**
   * 考生数量
   */
  examineesCount?: number | null
  gradeStats?: ExamGradeStats
  scoringStatusGeneralLabel?: string
  semesterId: number
  name: string
  /**
   * 考试类型
   */
  examType: ExamTypeEnum
  /**
   * 考试标签，后续改为 tag_ids
   */
  typeId: number | null
  graduatesInId: number | null
  status: ExamStatusEnum
  stage?: number | null
  scoringType?: ExamScoringTypeEnum | null
  /**
   * expand 已录入的分数数量
   */
  existedScoresCount?: number
  /**
   * expand 应该有的分数数量，按照 relations 统计
   */
  allScoresCount?: number
  /**
   * expand 学期
   */
  semester?: Semester
  /**
   * expand 考试类型
   */
  type?: Term | null
  /**
   * expand 届别
   */
  graduatesIn?: Term | null
  /**
   * 考试统计相关设置数据
   */
  statsData?: ExamStatsData
  /**
   * 发布时间
   */
  publishedAt?: string | null
  studentDisplay?: string[] | null
  /**
   * 教师可见数据设置，null 标识设置未打开走全局设置
   */
  teacherDisplay?: ExamTeacherDisplayEnum[] | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: ExamInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      subjects: _subjects,
      scheduleStatus: _scheduleStatus,
      scheduleNoticeSettings: _scheduleNoticeSettings,
      examinerGlobalRules: _examinerGlobalRules,
      customData: _customData,
      examineesCount: _examineesCount,
      gradeStats: _gradeStats,
      scoringStatusGeneralLabel: _scoringStatusGeneralLabel,
      semesterId: _semesterId,
      name: _name,
      examType: _examType,
      typeId: _typeId,
      graduatesInId: _graduatesInId,
      status: _status,
      stage: _stage,
      scoringType: _scoringType,
      existedScoresCount: _existedScoresCount,
      allScoresCount: _allScoresCount,
      semester: _semester,
      type: _type,
      graduatesIn: _graduatesIn,
      statsData: _statsData,
      publishedAt: _publishedAt,
      studentDisplay: _studentDisplay,
      teacherDisplay: _teacherDisplay,
      id: _id,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.subjects = _subjects
      ? _subjects.map(i => new ExamSubject(i))
      : _subjects
    this.scheduleStatus = _scheduleStatus
    this.scheduleNoticeSettings = _scheduleNoticeSettings
      ? new ExamScheduleNoticeSettings(_scheduleNoticeSettings)
      : _scheduleNoticeSettings
    this.examinerGlobalRules = _examinerGlobalRules
      ? new ExaminerGlobalRules(_examinerGlobalRules)
      : _examinerGlobalRules
    this.customData = _customData
      ? new ExamCustomData(_customData)
      : _customData
    this.examineesCount = _examineesCount
    this.gradeStats = _gradeStats
      ? new ExamGradeStats(_gradeStats)
      : _gradeStats
    this.scoringStatusGeneralLabel = _scoringStatusGeneralLabel
    this.semesterId = _semesterId
    this.name = _name
    this.examType = _examType
    this.typeId = _typeId
    this.graduatesInId = _graduatesInId
    this.status = _status
    this.stage = _stage
    this.scoringType = _scoringType
    this.existedScoresCount = _existedScoresCount
    this.allScoresCount = _allScoresCount
    this.semester = _semester ? new Semester(_semester) : _semester
    this.type = _type ? new Term(_type) : _type
    this.graduatesIn = _graduatesIn ? new Term(_graduatesIn) : _graduatesIn
    this.statsData = _statsData ? new ExamStatsData(_statsData) : _statsData
    this.publishedAt = _publishedAt
    this.studentDisplay = _studentDisplay
    this.teacherDisplay = _teacherDisplay
    this.id = _id
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'subjects',
    'scheduleStatus',
    'scheduleNoticeSettings',
    'examinerGlobalRules',
    'customData',
    'examineesCount',
    'gradeStats',
    'scoringStatusGeneralLabel',
    'semesterId',
    'name',
    'examType',
    'typeId',
    'graduatesInId',
    'status',
    'stage',
    'scoringType',
    'existedScoresCount',
    'allScoresCount',
    'semester',
    'type',
    'graduatesIn',
    'statsData',
    'publishedAt',
    'studentDisplay',
    'teacherDisplay',
    'id',
    'schoolId',
    'createdAt',
    'updatedAt',
  ]
}
