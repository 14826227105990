export const LEGO_FLAG = 'lego_field'

/**
 * 判断是否为 LegoForm 内置的 field
 *
 * @param name - 字段 name
 * @returns 是否为 LegoForm 内置的 field
 */
export const isLegoFormBuiltInField = (name?: string) =>
  !!name?.startsWith(LEGO_FLAG)
