import { moment } from '@seiue/moment'
import { floor, keyBy, sumBy } from '@seiue/util'

import { Assessment, ComputeTypeEnum, Item } from 'packages/sdks-next/vnas'

import { isItemWithinScore, isSpecialItem } from './item'

export interface TreeNode {
  id: number
  index: number
  parent: TreeNode | Tree
  item: Item
  score: number | null
  siblings: TreeNode[]
  children?: TreeNode[]
}

export interface TreeNodeMap {
  [key: number]: TreeNode | undefined
}

export interface Tree {
  id: number
  root: Assessment
  children: TreeNode[]
}

/**
 * 转化评价项为树结构
 *
 * @param assessment - 成绩评价
 * @param originItems - 评价项
 * @returns 树结构
 */
export const convertItemsToTree = (
  assessment: Assessment,
  originItems: Item[],
) => {
  const items = originItems.filter(item => item.assessmentId === assessment.id)
  const itemMap = keyBy(items, 'id')

  // 根节点
  const fullTree: Tree = {
    id: assessment.id,
    root: assessment,
    children: [],
  }

  // TreeNodeMap 就是子节点的 hash，可以方便的通过 item.id 找出树节点
  const treeNodeMap: { [key: string]: TreeNode } = {}

  items.forEach(item => {
    const treeNode: TreeNode = treeNodeMap[item.id] || {
      id: item.id,
      item,
      score: null,
    }

    treeNodeMap[item.id] = treeNode

    if (!item.pid) {
      // 如果没有父 ID，即表示其为第一级节点
      treeNode.score = Number(item.withinScore || 0)

      // 那么其父节点就是根节点
      treeNode.parent = fullTree
      fullTree.children.push(treeNode)
    } else {
      if (!itemMap[item.pid]) {
        console.error(
          `WARNING: ${item.name} 没有加入到树中。该项属于某个维度/子维度，但无法在传入的 Item 数组中找到该维度/子维度`,
        )

        return
      }

      // 如果该子节点的父节点尚未加入到树中，则事先保存
      if (!treeNodeMap[item.pid]) {
        treeNodeMap[item.pid] = {
          id: item.pid,
          index: 0,
          item: itemMap[item.pid],
          parent: fullTree,
          score: null,
          siblings: [],
          children: [],
        }
      }

      const parentTreeNode = treeNodeMap[item.pid]

      if (!parentTreeNode.children) parentTreeNode.children = []

      treeNode.parent = parentTreeNode

      parentTreeNode.children.push(treeNode)
    }
  })

  /* eslint-disable no-param-reassign */
  // 计算各个子节点的分数
  const caculateTreeNodeScore = (treeNode: TreeNode) => {
    if (!treeNode.children || treeNode.score === null) return

    const {
      score: parentScore,
      item: { computeType },
      children,
    } = treeNode

    const withinChildren = children.filter(child =>
      isItemWithinScore(child.item),
    )

    if (computeType === ComputeTypeEnum.Sum) {
      withinChildren.forEach(node => {
        node.score = Number(node.item.withinScore) || 0
      })
    } else if (computeType === ComputeTypeEnum.Avg) {
      withinChildren.forEach(node => {
        // 父维度是平均分，则子评价项皆不占分
        node.score = null
      })
    } else if (computeType === ComputeTypeEnum.WeightedAvg) {
      const allWeight = sumBy(withinChildren, 'item.weight')

      withinChildren.forEach(node => {
        if (node.item.weight && allWeight) {
          node.score = (parentScore * node.item.weight) / allWeight
        } else {
          node.score = 0
        }
      })
    }

    withinChildren.forEach(node => {
      node.score = node.score && floor(node.score, 2)
      caculateTreeNodeScore(node)
    })
  }

  // 对节点对 children 进行排序
  const sortTreeNode = (treeNode: TreeNode | Tree) => {
    if (!treeNode.children) return
    treeNode.children = treeNode.children.sort(
      ({ item: firstItem }, { item: secondItem }) => {
        // 保证个性化评价总是排在最后
        if (isSpecialItem(firstItem)) return 1
        if (isSpecialItem(secondItem)) return -1

        // 如果两个 sort 相同，则按创建时间正序排列（最新的在后面）
        if (firstItem.sort === secondItem.sort) {
          return moment(firstItem.createdAt).isBefore(secondItem.createdAt)
            ? -1
            : 1
        }

        return firstItem.sort > secondItem.sort ? 1 : -1
      },
    )

    treeNode.children.forEach((node, index) => {
      node.index = index
      sortTreeNode(node)
    })
  }

  const fulfillSiblings = (treeNode: TreeNode) => {
    if (!treeNode.parent.children) return

    treeNode.siblings = treeNode.parent.children.reduce((result, node) => {
      if (node.id !== treeNode.id) return [...result, node]
      return result
    }, [] as TreeNode[])

    treeNode.children?.forEach(node => {
      fulfillSiblings(node)
    })
  }
  /* eslint-enable no-param-reassign */

  fullTree.children.forEach(node => {
    caculateTreeNodeScore(node)
    fulfillSiblings(node)
  })

  sortTreeNode(fullTree)

  return [fullTree, treeNodeMap] as const
}
