import { moment, Formatter } from '@seiue/moment'

import { $t } from 'packages/locale'
import {
  Item,
  ItemInterface,
  ItemTypeEnum,
  ScoringTypeEnum,
} from 'packages/sdks-next/vnas'

export const quantitative = [
  ScoringTypeEnum.Score,
  ScoringTypeEnum.Star,
  ScoringTypeEnum.Level,
  ScoringTypeEnum.Addition,
]

/**
 * 是否是维度类型
 *
 * @param item - 评价项
 * @returns 是否
 */
export const isDimension = (item: Pick<Item, 'type'>) =>
  [ItemTypeEnum.Dimension, ItemTypeEnum.SubDimension].includes(item.type)

/**
 * 是否为个性化评价
 *
 * @param item - 评价项
 * @param item.type - 评价项类型
 * @param item.name - 评价项名称
 * @returns 是否
 */
export const isSpecialItem = (item: {
  type: Item['type']
  name: Item['name']
}) => item.type === ItemTypeEnum.SpecialItem && item.name === $t('个性化评价')

/**
 * 判断某个评价项计分类型是否是定量的
 *
 * @param type - 打分方式
 * @returns 是否
 */
export const isScoringTypeQuantitative = (type: ScoringTypeEnum) =>
  quantitative.includes(type)

/**
 * 判断某个评价项是否应该占分
 *
 * @param item - 评价项
 * @returns 是否
 */
export const isItemWithinScore = (item: Item) => {
  // 所有非评价项的项（即维度和子维度）肯定占分
  if (
    [
      ItemTypeEnum.Item,
      ItemTypeEnum.SpecialItem,
      ItemTypeEnum.AttendanceItem,
    ].includes(item.type)
  ) {
    // 所有定性评价不占分
    if (item.scoringType && !isScoringTypeQuantitative(item.scoringType)) {
      return false
    }

    // 直接加减分评价项不占分
    if (item.scoringType === ScoringTypeEnum.Addition && !item.within)
      return false
  }

  return true
}

/**
 * 格式化评价项录入锁定的开放时间
 *
 * @param item - 评价项
 * @returns 开放时间
 */
export const formatItemLockAllowedTime = (item: ItemInterface | Item) =>
  item.lockRules?.startAt
    ? $t('{startAt} 至 {endAt}', {
        startAt: moment(item.lockRules.startAt).format(Formatter.DateTime),
        endAt: moment(item.lockRules.endAt).format(Formatter.DateTime),
      })
    : ''
