import { Ellipsis, Icon, Space } from '@seiue/ui'
import { getReactNodeInnerText, useSize } from '@seiue/util'
import Masonry from 'masonry-layout'
import React, { MouseEventHandler, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { useIsEnLocale } from 'packages/locale'

import { MenuGroup } from './MenuGroup'
import { MenuGroup as InnerMenuGroupType, MenuSingleItem } from './types'

export interface DropdownMenuItem extends Omit<InnerMenuGroupType, 'subMenus'> {
  subMenus: InnerMenuGroupType[]
}

interface DropdownMenuProps {
  /**
   * 标题
   */
  title: string
  /**
   * 菜单项
   */
  menus: DropdownMenuItem[]
  /**
   * 背景色
   */
  dropdownMenuBackgroundColor: string
  /**
   * 显示 Tabs
   */
  showTabs?: boolean
  /**
   * 右上角 slot
   */
  topRightSlot?: React.ReactNode
  /**
   * style
   */
  style?: React.CSSProperties
  /**
   * 关闭回调
   */
  onClose: () => void
  /**
   * 光标移入回调
   */
  onMouseEnter?: MouseEventHandler<HTMLDivElement>
  /**
   * 光标离开回调
   */
  onMouseLeave?: MouseEventHandler<HTMLDivElement>
  /**
   * 点击菜单回调
   *
   * @param menu - 菜单项
   */
  onClickMenu?: (menu: MenuSingleItem | InnerMenuGroupType) => void
}

/**
 * 下拉导航菜单
 *
 * @param props - DropdownMenuProps
 * @returns React.ReactNode
 */
export const DropdownMenu = (props: DropdownMenuProps) => {
  const {
    title,
    menus,
    dropdownMenuBackgroundColor,
    onClose,
    style,
    topRightSlot,
    onMouseEnter,
    onMouseLeave,
    onClickMenu,
    showTabs = true,
  } = props

  const isEn = useIsEnLocale()

  const finalGroupMenus = React.useMemo(() => {
    return menus.filter(menu => menu.subMenus.length > 0)
  }, [menus])

  const [activeMenu, setActiveMenu] = React.useState(finalGroupMenus[0])

  const masonryRootRef = useRef(null)
  const masonryRootSize = useSize(masonryRootRef)
  const [masonryInited, setMasonryInited] = useState(false)

  useEffect(() => {
    if (masonryRootRef.current && masonryRootSize.width) {
      // eslint-disable-next-line no-new
      new Masonry(masonryRootRef.current, {
        itemSelector: '.management-center-item',
        columnWidth: masonryRootSize.width / (isEn ? 3 : 4),
      })

      // 在 masonry 计算完毕后再令组件可见
      setTimeout(() => {
        setMasonryInited(true)
      }, 0)
    }
  }, [masonryRootSize.width, activeMenu, isEn])

  const showControlBar = topRightSlot || (finalGroupMenus.length && showTabs)

  return menus.length ? (
    <CommonTopperDropdownWrapper
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      dropdownMenuBackgroundColor={dropdownMenuBackgroundColor}
      $visible={masonryInited}
      style={style}
    >
      <CoverWrapper>
        <Header>
          <HeaderTitle>{title}</HeaderTitle>
          <Icon
            icon="Close"
            size={18}
            onClick={() => {
              onClose?.()
            }}
          />
        </Header>
        <BodyWrapper>
          {showControlBar ? (
            <ControlBar>
              <TabsWrapper size={8}>
                {showTabs &&
                  finalGroupMenus.length &&
                  finalGroupMenus.map(item => {
                    const labelKey = getReactNodeInnerText(item.label)
                    return (
                      <TabItem
                        key={labelKey}
                        active={
                          getReactNodeInnerText(activeMenu?.label) === labelKey
                        }
                        onClick={() => setActiveMenu(item)}
                        text={item.label}
                      />
                    )
                  })}
              </TabsWrapper>
              {topRightSlot}
            </ControlBar>
          ) : null}
          <MenuListWrapper
            style={{ marginTop: showControlBar ? undefined : '0' }}
          >
            <WaterfallList ref={masonryRootRef}>
              {activeMenu?.subMenus?.map((item, index) => (
                <MenuGroup
                  menu={item}
                  key={index}
                  onClickMenu={onClickMenu}
                  dropdownMenuBackgroundColor={dropdownMenuBackgroundColor}
                />
              ))}
            </WaterfallList>
          </MenuListWrapper>
        </BodyWrapper>
      </CoverWrapper>
    </CommonTopperDropdownWrapper>
  ) : null
}

const CommonTopperDropdownWrapper = styled.div<{
  $visible: boolean
  dropdownMenuBackgroundColor: string
}>`
  width: 100%;
  max-width: 960px;
  visibility: ${p => (p.$visible ? 'visible' : 'hidden')};
  background-color: ${props => props.dropdownMenuBackgroundColor};
  z-index: 10;
  color: rgb(255 255 255 / 65%);
  line-height: normal;
`

const CoverWrapper = styled.div`
  height: 100%;

  /* 下拉菜单的最大高度 = 屏幕高度 - 顶栏高度 - 下拉菜单下边距 */
  max-height: calc(100vh - 52px - 30px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: rgb(0 0 0 / 24%);
`

const Header = styled.div`
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 36px;
  border-bottom: 1px solid rgb(255 255 255 / 6%);
`

const HeaderTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgb(255 255 255 / 65%);
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  overflow: hidden;
`

const ControlBar = styled.div`
  display: flex;
  justify-content: space-between;
  height: 32px;
`

const TabsWrapper = styled(Space)``

const TabItem = styled(Ellipsis)<{ active: boolean }>`
  max-width: 200px;
  line-height: 32px;
  padding: 0 20px;
  text-align: center;
  color: ${props => (props.active ? '#fff' : 'rgba(255, 255, 255, 0.65)')};
  background: ${props =>
    props.active ? 'rgba(255, 255, 255, 0.05)' : 'transparent'};
  border-radius: 100px;
  cursor: pointer;

  :hover {
    background: rgb(255 255 255 / 5%);
  }
`

const MenuListWrapper = styled.div`
  margin-top: 24px;
  overflow: auto;

  /* hide ugly scrollbar */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`

const WaterfallList = styled.div`
  /* 在 masonry 计算高度之前设为 0, 避免一瞬间的滚动条 */
  height: 0;
  min-height: 280px;
`
