/**
 * @file 表单字段规则 types
 */
import { Attachment as AttachmentChalk } from 'packages/sdks-next/chalk'

import { LegoFormFieldOption } from './field-option'

export type LegoFormFieldContainer = {
  /**
   * 题目的选项
   */
  options?: LegoFormFieldOption[]

  /**
   * 是否启用详细地址，仅适用于选择省市区组件
   */
  enableDetailedAddress?: boolean

  /**
   * 是否启用字段描述
   */
  enableDescription?: boolean

  /**
   * 字段描述的附件
   */
  attachments?: AttachmentChalk[]

  /**
   * 字段校验规则
   */
  rules?: LegoFormFieldRules

  /**
   * 计分题
   */
  // 打分方式
  scoreType?: LegoFormFieldScoreType
  // 星星数量
  starCount?: number
  // 分数精度
  scorePrecision?: number
  // 满分
  fullScore?: number
  // 左边/右边提示文字
  scoreTooltips?: {
    min?: string
    max?: string
  }

  /**
   * 日期/时间和日期区间题显示格式
   */
  dateFormat?: string

  /**
   * 区县是否选填 (仅用于省市区字段), 默认 false
   */
  isDistrictOptional?: boolean

  /**
   * 是否禁用「必填」
   */
  disableRequired?: boolean

  /**
   * 是否禁用「复制」
   */
  disableCopy?: boolean

  /**
   * 是否禁用「删除」
   */
  disableDelete?: boolean

  /**
   * 是否禁用「添加「其他」项」
   */
  disableOtherOption?: boolean

  /**
   * 金额输入是否自动生成中文大写
   */
  hansEnable?: boolean

  /**
   * 隐藏题目序号
   */
  hideSort?: boolean

  /**
   * 必填按钮 tooltip
   */
  requiredTooltip?: string

  /**
   * 【复合字段】分组 name
   */
  group?: string

  /**
   * 【复合字段】是否为复合题
   */
  isParent?: boolean

  /**
   * 匿名上传凭证
   */
  bizSig?: string
  /**
   * 选项数据源，如果该字段未设置时使用 options 的值作为选项。
   * 该字段的值是字符串，由各个业务动态注册而来，前端根据其值判断应该那哪里获取数据。
   * 目前仅在「广中报修审批」中使用，该属性由后端配置
   */
  optionSource?: string
  /**
   * 数据源显示名称
   */
  optionSourceLabel?: string

  /**
   * 是否整体评价，仅用于评教问卷, 默认 false
   */
  isOverallEvaluation?: boolean

  /**
   * 用于子题目的排序，如矩阵单选
   */
  subSort?: number

  /**
   * 题目是否由学生/家长作答，默认 false（only for 约谈）
   */
  submitByStudent?: boolean

  [key: string]: any
}

export type LegoFormFieldRules = {
  /**
   * min/max 在多个字段类型中具有通用规则
   * 数字题：最大/最小值
   * 多选题：最少/最多选择项
   * 单行：最大输入数量
   * 附件：最大上传数量
   */
  min?: number
  max?: number
  /**
   * 数值输入的精度， 默认 2 (保留两位小数)
   */
  precision?: number
}

export enum LegoFormFieldScoreType {
  Star = 'star',
  Slider = 'slider',
}
