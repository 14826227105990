import { has, isNil } from '@seiue/util'

import {
  useIsBdfzHaikou,
  useIsSZW,
  useIsTjyh,
} from 'packages/features/schools/hooks'
import { useCurrentReflection } from 'packages/features/sessions'
import { $t } from 'packages/locale'
import { Expand } from 'packages/sdks-next'
import {
  Pupil,
  Reflection,
  RoleEnum,
  SecuredReflection,
} from 'packages/sdks-next/chalk'
import { AdminClassInterface } from 'packages/sdks-next/sgms'
import {
  AchievedScore,
  Assessment,
  AssessmentTypeEnum,
  EvaluateTargetEnum,
  Item,
  ItemTypeEnum,
  MoralAssessmentTypeEnum,
  MoralPolicyEnum,
  RelationsEnum,
  Score,
  ScoringTypeEnum,
} from 'packages/sdks-next/vnas'

import { isScoringTypeQuantitative } from '../grades/utils/item'
import { isScoreLocked, toNumberScore } from '../grades/utils/score'
import { TreeNode } from '../grades/utils/tree'

import { moralBadgeToImage } from './data'
import { getPublishedAssessmentTip } from './score-actions'

/**
 * 是否为内置的徽章
 *
 * @param badge - 内置徽章（值为图片的地址）/自定义上传的徽章（值为图片的 hash 值）
 * @returns boolean
 */
export const isBuiltInBadge = (badge?: string) => {
  return !!badge?.includes('builtInBadge')
}

/**
 * 获取德育的徽章图片
 *
 * @param badge - 内置徽章（值为图片的地址）/自定义上传的徽章（值为图片的 hash 值）
 * @returns 徽章图片
 */
export const getBadgeImage = (badge: string) => {
  return isBuiltInBadge(badge) ? moralBadgeToImage.get(badge as any) : badge
}

/**
 * 获取是否可以删除加减分/标签/个性化评价
 *
 * @param param - 参数
 * @param param.policy - 当前访问的身份
 * @param param.assessment - 德育评价
 * @param param.rid - 评价人 id
 * @returns (score: Score) => boolean
 */
export const useGetScoreDeletable = ({
  policy,
  assessment,
  rid,
}: {
  policy?: MoralPolicyEnum
  assessment: Assessment
  rid: number
}) => {
  return (score: Score) => {
    if (policy === MoralPolicyEnum.Admin) return true

    if (!!assessment.settings?.allowDeleteTags && score.evaluator?.id === rid) {
      return true
    }

    return false
  }
}

/**
 * 判断是否显示雷达图
 *
 * @param itemCount - 德育的评价项数量
 * @returns boolean
 */
export const shouldShowAssessmentRadar = (itemCount: number) =>
  itemCount < 10 && itemCount > 2

/**
 * 是否显示成绩分布图
 *
 * @param item - 评价项
 * @returns boolean
 */
export const shouldShowDistribution = (item: Item) => {
  return (
    !!item.scoringType &&
    isScoringTypeQuantitative(item.scoringType) &&
    !!toNumberScore({ value: item.fullScore, defaultValue: 0 })
  )
}

/**
 * 判断是否显示德育评价的动态
 *
 * @param me - 当前用户
 * @returns boolean
 */
export const shouldShowMoralTrends = (me: Reflection) => {
  // 北大附中海口学校国际部的教务老师认为这个动态展示的信息给学生和家长看到不好，要求隐藏动态
  if (me.schoolId === 199) {
    return false
  }

  return [RoleEnum.Student, RoleEnum.Guardian, RoleEnum.Teacher].includes(
    me.role,
  )
}

/**
 * 判断是否显示德育评价的动态
 *
 * @param operationType - 评价类型
 * @returns boolean
 */
export const useShouldShowMoralTrends = (
  operationType = MoralAssessmentTypeEnum.Moral,
) => {
  const isBdfzHaikou = useIsBdfzHaikou()
  const currentReflection = useCurrentReflection()
  const isTjyh = useIsTjyh()
  const isSzw = useIsSZW()

  if (
    // 北大附中海口学校国际部: 教务老师认为这个动态展示的信息给学生和家长看到不好，要求隐藏动态
    isBdfzHaikou ||
    ((isTjyh || isSzw) &&
      operationType === MoralAssessmentTypeEnum.TeacherAssessment)
  ) {
    return false
  }

  return [RoleEnum.Student, RoleEnum.Guardian, RoleEnum.Teacher].includes(
    currentReflection.role,
  )
}

/**
 * 判断德育评价录入-成员列是否支持排序
 *
 * @param assessment - 评价配置
 * @returns boolean
 */
export const shouldSupportSortMemberColumn = (assessment: Assessment) => {
  return !assessment.isAdminClassMoral
}

/**
 * 获取评价对象的标题
 *
 * @param assessment - 评价配置
 * @returns string
 */
export const getEvaluateTargetTitle = (assessment: Assessment) => {
  return assessment.isAdminClassMoral ? $t('班级名称') : $t('姓名')
}

/**
 * 德育评价对象为行政班时，评价录入/汇总中接口返回数据实际为行政班数据（不过接口定义的是 SecuredReflection，且后端生成 SDK 时就做了 skip lint)
 * 此方法根据 role（仅 reflection 有） 和 studentNums（仅行政班有） 字段判断评价对象是否为行政班
 *
 * @param target - 评价对象
 * @returns type guard
 */
export const isEvaluateTargetAdminClass = (
  target: any,
): target is AdminClassInterface => {
  return has(target, 'studentNums') && isNil(target?.role)
}

/**
 * 判断评价对象成绩是否已锁定, 评价对象时行政班时不支持成绩锁定
 *
 * @param assessment - 评价配置
 * @param id - 评价对象 id
 * @returns boolean
 */
export const checkLockedEvaluation = (assessment: Assessment, id: number) => {
  return (
    !!assessment.lockedEvaluatedIds?.includes(id) &&
    !assessment.isAdminClassMoral
  )
}

/**
 * 判断评价项成绩是否已发布
 *
 * @param item - 评价项
 * @param originalScore - 评价项成绩
 * @returns boolean
 */
export const isPublishedItemScore = (
  item?: Item,
  originalScore?: Score | AchievedScore,
) => {
  return (
    !!originalScore?.score &&
    !!item?.scoringType &&
    [
      ScoringTypeEnum.Score,
      ScoringTypeEnum.Level,
      ScoringTypeEnum.Star,
      ScoringTypeEnum.Review,
    ].includes(item.scoringType)
  )
}

/**
 * 获取评价的目标文本
 *
 * @param assessment - 评价
 * @returns 目标文本
 */
export const getAssessmentTargetText = (
  assessment: Expand<Assessment, ['items']>,
) => {
  if (assessment.type === AssessmentTypeEnum.TeacherAssessment) {
    return $t('教师')
  }

  if (assessment.evaluateTarget === EvaluateTargetEnum.Reflection) {
    return $t('学生')
  }

  return $t('班级人员')
}

/**
 * 获取录入提示文案
 *
 * @param type - 评价类型
 * @returns string
 */
export const getEntryAlertText = (type: AssessmentTypeEnum) => {
  switch (type) {
    case AssessmentTypeEnum.TeacherAssessment:
      return $t(
        '任何人将绩效修改后，得分和排名计算需要5~10分钟，等待后刷新页面查看最新数据',
      )
    default:
      return $t(
        '任何人将成绩修改后，得分和排名计算需要5~10分钟，等待后刷新页面查看最新数据',
      )
  }
}

/**
 * 评价类型转换成通用德育评价类型方法, 德育评价或教师考核
 *
 * @param type - 评价类型
 * @returns 通用德育评价类型方法
 */
export const assessmentTypeToOperationType = (type: AssessmentTypeEnum) => {
  switch (type) {
    case AssessmentTypeEnum.Moral:
      return MoralAssessmentTypeEnum.Moral
    case AssessmentTypeEnum.TeacherAssessment:
      return MoralAssessmentTypeEnum.TeacherAssessment
    default:
      throw new Error(
        $t('通用德育评价类型暂时只支持「德育评价」和「教师考核」'),
      )
  }
}

/**
 * 获取是否是一个单个评价对象的评价项
 *
 * @param item - 评价项
 * @returns boolean
 */
export const isSingleEvaluateTargetEntryItem = (item?: Item) => {
  return (
    !!item?.relation &&
    [RelationsEnum.MarkSelf, RelationsEnum.GuardianMarkStudent].includes(
      item.relation,
    )
  )
}

/**
 * 获取是否有单个评价对象的评价项
 *
 * @param items - 评价项列表
 * @returns boolean
 */
export const hasSingleEvaluateTargetEntryItem = (items: Item[]) => {
  return items.some(isSingleEvaluateTargetEntryItem)
}

/**
 * 评价项是否只读
 *
 * @param param - 参数
 * @param param.assessment - 评价
 * @param param.hasManagerPermission - 是否有管理权限
 * @param param.currentItemNode - 当前评价项节点
 * @param param.evaluatedMember - 被评人
 * @returns boolean
 */
export const getItemReadonly = ({
  assessment,
  hasManagerPermission,
  currentItemNode,
  evaluatedMember,
}: {
  assessment: Assessment
  hasManagerPermission?: boolean
  currentItemNode?: TreeNode | null
  evaluatedMember?: SecuredReflection | Pupil | null
}) => {
  // 评价已结算提示
  const publishedAssessmentTip = getPublishedAssessmentTip(assessment)

  // 对单个评价对象进行评价时，评价项是否只读
  if (evaluatedMember) {
    return (
      !!publishedAssessmentTip ||
      !!assessment.lockedEvaluatedIds?.includes(evaluatedMember.id)
    )
  }

  return (
    // 教务不能修改多人打分的评价项
    ((currentItemNode?.item as Item)?.multiEvaluators &&
      hasManagerPermission) ||
    // 评价已结算
    !!publishedAssessmentTip ||
    // 评价项已锁定
    (!!currentItemNode &&
      !hasManagerPermission &&
      isScoreLocked(currentItemNode.item))
  )
}

/**
 * 获取是否可显示多人打分成绩
 *
 * @param param - 参数
 * @param param.assessment - 评价
 * @param param.item - 评价项
 * @returns boolean
 */
export const useAllowShowMultiEvaluatorsScore = ({
  assessment,
  item,
}: {
  assessment: Assessment
  item: Item
}) => {
  const isTjyh = useIsTjyh()
  const operationType = assessmentTypeToOperationType(assessment.type)
  const isTeacherAssessment =
    operationType === MoralAssessmentTypeEnum.TeacherAssessment

  /**
   * Hack
   * 为天津英华实验学校隐藏【维度】【子维度】【分数】【星星】【等级】评价项的【评价详情】模块(多人评价下显示的内容)
   */
  if (isTjyh && isTeacherAssessment && item.multiEvaluators) {
    switch (item.type) {
      case ItemTypeEnum.Dimension:
      case ItemTypeEnum.SubDimension:
        return false
      case ItemTypeEnum.Item:
      case ItemTypeEnum.AttendanceItem:
        return !(
          item.scoringType &&
          [
            ScoringTypeEnum.Score,
            ScoringTypeEnum.Star,
            ScoringTypeEnum.Level,
          ].includes(item.scoringType)
        )
      default:
        return true
    }
  }

  return true
}
