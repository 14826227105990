import {
  BaseSeriesLabelType,
  BaseSeriesType,
  CommonSeriesContentType,
  WidgetChartOption,
  WithoutChartType,
} from 'packages/components/ReportCenter/Reporting/types'
import { LineChart, WidgetType } from 'packages/sdks-next/nuwa'

export enum LineSeriesLabelPosition {
  Top = 'top',
  Bottom = 'bottom',
}

type LineSeriesLabelType = BaseSeriesLabelType<
  LineSeriesLabelPosition,
  CommonSeriesContentType
>

export type LineSeriesType = BaseSeriesType<LineSeriesLabelType>
export type LineChartOption = WidgetChartOption<LineSeriesType>
export type LineChartExtra = WithoutChartType<
  LineChart,
  WidgetType.LineChart,
  LineChartOption
>
